<template>
    <div>
        <h6>Planning</h6>
        <div>
            <div class="d-flex align-items-center mb-3">
            <div class="label_code m-0 mr-2">
                <h2>P</h2>
            </div>
            <span class="font-weight-semibold text-indigo">Planning</span>
            </div>
            <div>

                
            <div class="row g-2 mb-1">
                <div class="col-lg-12">
                    <a href="javascript:;" @click="openTemplatePenunjangData" v-if="isParent.row.is_have_template_penunjang" class="btn alpha-success ml-1"><i class="fas fa-receipt mr-2"></i>Pilih Template Pemeriksaan Penunjang</a>
                </div>
            </div>
            <div class="row g-2">
                <div class="col-md-6 col-lg-12">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <div class="row">
                        <div class="col-md-4">
                        <h6 class="card-title font-weight-semibold">Laboratorium</h6>
                        </div>
                        <div class="col-md-8">
                        <b-form-checkbox :disabled="!canEditLab" @input="changeLab($event)"
                        v-model="isParent.row.aupdl_is_lab" value="Y" unchecked-value="N">
                        Apakah Ada Pengkajian Laboratorium
                        </b-form-checkbox>
                        </div>
                    </div>
                    </div>

                    <template v-if="isParent.row.aupdl_is_lab == 'Y'">

                        
                        <validation-observer
                            ref="VFormLab"
                        >
                        <div class="card-body p-3">
                        <div class="row g-2">
                            <div class="col-md-4">
                            <div>
                                <label>Prioritas Pemeriksaan <strong
                                    class="text-danger">*</strong></label>
                                <b-form-radio-group :disabled="!canEditLab"
                                :options="isParent.Config.mr.configPrioritasPemeriksaan"
                                v-model="isParent.row['aupdl_prioritas']" />
                            </div>
                            <VValidate v-if="isParent.row.aupdl_is_lab == 'Y'" :name="'Prioritas Pemeriksaan'"
                                v-model="isParent.row.aupdl_prioritas"
                                :rules="toValidate(mrValidation.aupdl_prioritas)" />
                            </div>
                        </div>
                        </div>
                        <div class="card-body p-3">
                        <div class="req-list-resume">
                            <div class="row g-3">
                            <template v-for="(v,k) in isParent.row.aupdl_data">
                                <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                <div class="card shadow-0 border">
                                    <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">
                                        {{v.head||"-"}}</h6>
                                    </div>
                                    <div class="card-body py-2">
                                    <template v-for="v1,k1 in (v.dubData||[])">
                                        <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)"
                                        :key="k1+'labdatas'">
                                        <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                        <template v-for="v2,k2 in (v1.data||[])">
                                            <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                            <i
                                                class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                            <b class="align-middle font-weight-semibold">{{v2.text||"-"}}</b>
                                            <span class="" v-if="v2.notes">, {{v2.notes}}</span>
                                            </div>
                                        </template>
                                        </div>
                                    </template>
                                    </div>
                                </div>
                                </div>
                            </template>

                            <div class="col-md-4 col-lg-3" v-if="isParent.row.aupdl_hasil_lainnya">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">Item
                                    Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                    <div>
                                        <i
                                        class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <b class="align-middle font-weight-semibold">{{isParent.row.aupdl_hasil_lainnya||"-"}}</b>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <span v-if="!selectedLabInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                            </div>
                        </div>
                        <div class="text-center mt-3" v-if="canEditLab">
                            <a href="javascript:;" @click="doOpenLab" class="btn alpha-blue"
                            data-toggle="modal" data-target="#labItemsChecklist"><i
                                class="icon-plus2 mr-2"></i>Tambah Pemeriksaan Laboratorium</a>                                                    
                        </div>
                        </div>

                        <div class="card-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Upload Hasil</th>
                                    <th>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.row.aupdl_hasil||[])" :key="k+'hasil'">
                                    <td>
                                    <b-form-input v-model="isParent.row.aupdl_hasil[k]['value']" type="text"
                                        name="name" class="form-control" />
                                    </td>
                                    <td>
                                    <Uploader v-model="isParent.row.aupdl_hasil[k]['file']" isDocument
                                        type="docimage" />
                                    <VValidate :name="'file Pemeriksaan #'+(k+1)" v-model="isParent.row.aupdl_hasil[k]['file']"
                                        :rules="{required:1}" />
                                    </td>
                                    <td>
                                    <a href="javascript:;" class="list-icons-item"
                                        @click="isParent.row.aupdl_hasil.splice(k,1)" data-toggle="tooltip"
                                        data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.row.aupdl_hasil||[]).length">
                                    <td colspan="99" class="text-center">Tidak Ada Data</td>
                                </tr>
                                </tbody>
                            </table>

                            <div class="more_data" v-if="canEditLab">
                                <a href="javascript:;"
                                @click="addLab"><i
                                    class="icon-plus-circle2"></i>
                                Tambah</a>
                            </div>
                            </div>

                        </div>
                        </div>

                        <div class="card-body p-3">
                        <div class="form-group">
                            <label for="">Apakah Pasien Harus Berpuasa? <strong
                                class="text-danger">*</strong></label>
                            <b-form-radio-group :disabled="!canEditLab" :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.aupdl_fasting" />

                            <VValidate v-if="isParent.row.aupdl_is_lab == 'Y'" :name="'Prioritas Pemeriksaan Lab'"
                            v-model="isParent.row.aupdl_fasting"
                            :rules="toValidate(mrValidation.aupdl_fasting)" />
                        </div>

                        </div>

                        <div class="card-footer">
                        <div class="d-flex flex-column align-items-end">
                            <button @click="submitLab()" type="button" class="btn btn-labeled btn-labeled-left btn-primary">
                            <b><i class="icon-paperplane"></i></b>
                            <span>Kirimkan</span>
                            </button>
                            <small class="text-secondary mt-1" v-if="isParent.rowReg.aur_is_lab_at">Terakhir dikirim: 
                            {{isParent.rowReg.aur_is_lab_at | moment("DD MMMM YYYY, HH:mm")}} WIB</small>
                        </div>
                        </div>
                        </validation-observer>

                    </template>
                </div>
                </div>
                <div class="col-md-6 col-lg-12">
                <div class="card">
                    <div class="card-header bg_head_panel">
                    <div class="row">
                        <div class="col-md-4">
                        <h6 class="card-title font-weight-semibold">Radiologi</h6>
                        </div>
                        <div class="col-md-8">
                            <b-form-checkbox @input="changeRadiologi($event)" v-model="isParent.row.aupdr_is_radio"
                            value="Y" :disabled="!canEditRadiologi" unchecked-value="N">
                            Apakah Ada Pengkajian Radiologi
                            </b-form-checkbox>                         
                        </div>
                    </div>
                    </div>

                    <template v-if="isParent.row.aupdr_is_radio == 'Y'">
                        <validation-observer
                            ref="VFormRadiologi"
                        >
                        <div class="card-body p-3">
                        <div class="row g-2">
                            <div class="col-md-4">
                            <div>
                                <label>Prioritas Pemeriksaan <strong
                                    class="text-danger">*</strong></label>
                                <b-form-radio-group :disabled="!canEditRadiologi"
                                :options="isParent.Config.mr.configPrioritasPemeriksaan"
                                v-model="isParent.row['aupdr_prioritas']" />
                            </div>
                            <VValidate v-if="isParent.row.aupdr_is_radio == 'Y'"
                                :name="'Prioritas Pemeriksaan Radiologi'" v-model="isParent.row.aupdr_prioritas"
                                :rules="toValidate(mrValidation.aupdr_prioritas)" />
                            </div>
                        </div>
                        </div>
                        <div class="card-body p-3">
                        <div class="req-list-resume">
                            <div class="row g-3">
                            <template v-for="(v,k) in isParent.row.aupdr_data">
                                <div class="col-md-4 col-lg-3" :key="k+'radioData'"
                                v-if="isShowHeadRadiologi(k)">
                                <div class="card shadow-0 border">
                                    <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">{{
                            getConfigDynamic(isParent.Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                                    </div>
                                    <div class="card-body py-2">
                                    <template v-for="v1,k1 in (v.data||[])">
                                        <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                                        <i
                                            class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                                        <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                                        
                                        <a href="javascript:;" @click="openInformConsent(v1,k1,k,getConfigDynamic(isParent.Config.mr.KategoriRadiologi,v.head))" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo ml-2"  v-b-tooltip.hover :title="v1.inform_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                                        <i class="icon-file-check2"></i>
                                        </a>

                                        <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,getConfigDynamic(isParent.Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                        <i class="icon-file-eye2"></i>
                                        </a>

                                        </div>
                                    </template>
                                    </div>
                                </div>
                                </div>
                            </template>
                            <div class="col-md-4 col-lg-3" v-if="isParent.row.aupdr_hasil_lainnya">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">Item
                                    Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                    <div>
                                        <i
                                        class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <b class="align-middle font-weight-semibold">{{isParent.row.aupdr_hasil_lainnya||"-"}}</b>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <span v-if="!selectedRadioInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                            </div>
                        </div>
                        <div class="text-center mt-3" v-if="canEditRadiologi">
                            <a href="javascript:;" @click="doOpenRadiologi()" class="btn alpha-blue"
                            data-toggle="modal" data-target="#radioItemsChecklist"><i
                                class="icon-plus2 mr-2"></i>Tambah Pemeriksaan Radiologi</a>

                        </div>
                        </div>


                        <div class="card-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Upload Hasil</th>
                                    <th>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.row.aupdr_hasil||[])" :key="k+'hasil'">
                                    <td>
                                    <b-form-input v-model="isParent.row.aupdr_hasil[k]['value']" type="text"
                                        name="name" class="form-control" />
                                    </td>
                                    <td>
                                    <Uploader v-model="isParent.row.aupdr_hasil[k]['file']" isDocument
                                        type="docimage" />
                                    <VValidate :name="'file Pemeriksaan #'+(k+1)" v-model="isParent.row.aupdr_hasil[k]['file']"
                                        :rules="{required:1}" />
                                    
                                    </td>
                                    <td>
                                    <a href="javascript:;" class="list-icons-item"
                                        @click="isParent.row.aupdr_hasil.splice(k,1)" data-toggle="tooltip"
                                        data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.row.aupdr_hasil||[]).length">
                                    <td colspan="99" class="text-center">Tidak Ada Data</td>
                                </tr>
                                </tbody>
                            </table>

                            <div class="more_data" v-if="canEditRadiologi">
                                <a href="javascript:;"
                                @click="addRadiologi"><i
                                    class="icon-plus-circle2"></i>
                                Tambah</a>
                            </div>
                            </div>

                        </div>
                        </div>
                        <div class="card-body p-3">
                        <div class="form-group">
                            <label for="">Pasien Memiliki Alergi Terhadap Bahan Kontras/Zat Lainnya?
                            <strong class="text-danger">*</strong></label>
                            <b-form-radio-group :disabled="!canEditRadiologi"
                            :options="isParent.Config.mr.yesNoOptV2" v-model="isParent.row.aupdr_alergi" />

                            <VValidate v-if="isParent.row.aupdr_is_radio == 'Y'" :name="'Alergi Zat Lainnya'"
                            v-model="isParent.row.aupdr_alergi" :rules="toValidate(mrValidation.aupdr_alergi)" />
                        </div>

                        <div class="form-group">
                            <label for="">Pasien akan Dialihkan Ke Radiologi? <strong
                                class="text-danger">*</strong></label>
                            <b-form-radio-group :disabled="!canEditRadiologi"
                            :options="isParent.Config.mr.yesNoOptV2" v-model="isParent.row.aupdr_to_radio" />

                            <VValidate v-if="isParent.row.aupdr_is_radio == 'Y'" :name="'Pasien Dialihkan ke Radiologi'"
                            v-model="isParent.row.aupdr_to_radio" :rules="{required : 1}" />
                        </div>
                        </div>
                        
                        <div class="card-footer">
                            <div class="d-flex flex-column align-items-end">
                                <button @click="submitRadiologi()" type="button" class="btn btn-labeled btn-labeled-left btn-primary">
                                <b><i class="icon-paperplane"></i></b>
                                <span>Kirimkan</span>
                                </button>
                                <small class="text-secondary mt-1" v-if="isParent.rowReg.aur_is_radiologi_at">Terakhir dikirim: 
                                {{isParent.rowReg.aur_is_radiologi_at | moment("DD MMMM YYYY, HH:mm")}} WIB</small>
                            </div>
                        </div>

                        </validation-observer>
                    </template>
                </div>
                </div>
                
                
                <template v-if="isPoliAdj">
                    <div class="col-12">
                    <div class="card">
                        
                    </div>
                    </div>
                </template>

                <div class="col-12">
                <div class="card">
                    <validation-observer
                        ref="VFormFarmasi"
                    >
                    <div class="card-header bg_head_panel">
                        <div class="row">
                            <div class="col-md-11">                                    
                            <h6 class="card-title font-weight-semibold mt-1">Resep</h6>
                            </div>
                            <div class="col-md-auto">
                            <!--
                            <div class="form-group mb-0">
                                <button type="button" @click="getResepPerawat()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang Resep Perawat" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                            </div>
                            -->
                            </div>
                        </div>
                    </div>
                    
                    <div class="card-body p-3"
                        v-if="isParent.row.ausd_has_alergi == 'Y' || isParent.row.ausd_has_obat_rutin == 'Y'">
                        <div class="row">
                        <div class="col-md-6" v-if="isParent.row.ausd_has_alergi == 'Y'">
                            <div class="card border mb-0">
                            <div class="card-header bg- head_panel_red p-2">
                                <h6 class="font-weight-semibold text-dark card-title">Catatan Alergi
                                Pasien</h6>
                            </div>
                            <div class="card-body py-2 px-3">
                                <ul class="mb-0">
                                <li class="my-1" v-for="(al, alKey) in (isParent.row.ausd_alergi || [])"
                                    :key="alKey">
                                    <span class="font-weight-semibold"
                                    v-if="isParent.row.ausd_alergi[alKey]['jenis']">
                                    ({{ isParent.row.ausd_alergi[alKey]['jenis'] }})</span>
                                    <span>{{ isParent.row.ausd_alergi[alKey]['name']||"-" }}
                                    </span>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="isParent.row.ausd_has_obat_rutin == 'Y'">
                            <div class="card border mb-0">
                            <div class="card-header bg- head_panel_blue p-2">
                                <h6 class="font-weight-semibold text-dark card-title">Catatan Obat Rutin
                                Pasien</h6>
                            </div>
                            <div class="card-body py-2 px-3">
                                <span class="my-1">{{ isParent.row.ausd_keterangan_obat_rutin||"-" }}</span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <table class="table table-striped table-sm table-hover table-bordered">
                        <thead>
                        <tr>
                            <th width="48">Aksi</th>
                            <th width="160">Jenis</th>
                            <th width="320">Nama</th>
                            <th width="150">Jumlah</th>
                            <th width="210">Frekuensi</th>
                            <th>Keterangan</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in isParent.resepDokter" :key="'resepDokter'+k">
                            <td>
                            <a href="javascript:;" @click="removeObat(v,k)"
                                class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                                class="icon-trash"></i></a>
                            </td>
                            <td>
                            <v-select placeholder="Pilih Item" v-model="isParent.resepDokter[k].aurd_jenis"
                                :options="isParent.Config.mr.configResep" label="text"
                                @input="changeJenis($event,k)" :reduce="v=>v.value">
                            </v-select>
                            <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                                v-model="isParent.resepDokter[k].aurd_jenis" :rules="{required : 1}" />
                            </td>
                            <td>
                            <div class="d-flex">
                                <template v-if="isParent.resepDokter[k]['aurd_jenis'] == 1">
                                <v-select style="flex: 1;" class="med-selection select-paging"
                                    placeholder="Pilih Item"
                                    :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                                    @open="countLen" @search="filterObat($event,k);countLen();"
                                    v-model="isParent.resepDokter[k].aurd_item_id" :options="isParent.mObatPreferensi"
                                    label="text" @input="selectJumlahSatuan($event,k)" :clearable="true"
                                    :reduce="v=>v.value"
                                    :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                    <template slot="selected-option">
                                    <span
                                        :class="isAlergiLabel(v.aurd_nama)?'text-warning':''">{{ v.aurd_nama }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                        v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                    <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                        v-else>{{ option.text }}</span>
                                    </template>
                                    <template #list-footer>
                                    <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                        <b-button
                                            @click="changePageObat(v.currentPage,k,'min','resepDokter')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                            <i class="icon-arrow-left22 text-white"></i></b-button>
                                        <small>Halaman {{v.currentPage}} dari
                                            {{ceilData(v.totalRows/10)}}</small>
                                        <b-button
                                            @click="changePageObat(v.currentPage,k,'plus','resepDokter')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                            <i class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                    </li>
                                    </template>
                                </v-select>
                                <br />
                                </template>

                                <template v-else-if="isParent.resepDokter[k]['aurd_jenis'] == 2">
                                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                    placeholder="Pilih Item" v-model="isParent.resepDokter[k].aurd_item_id"
                                    :options="isParent.mAlatKesehatan" label="text"
                                    @input="selectJumlahSatuanAlkes($event,k)" :clearable="true"
                                    :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                </v-select>
                                </template>

                                <template v-else-if="isParent.resepDokter[k]['aurd_jenis'] == 3">
                                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                    placeholder="Pilih Item" v-model="isParent.resepDokter[k].aurd_item_id"
                                    :options="isParent.mRacikan" label="text"
                                    @input="selectJumlahSatuanRacikan($event,k)" :clearable="true"
                                    :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                </v-select>
                                </template>
                                
                                <template v-else-if="isParent.resepDokter[k]['aurd_jenis'] == 4">
                                <v-select style="flex: 1;" class="med-selection select-paging"
                                    placeholder="Pilih Item" v-model="isParent.resepDokter[k].aurd_item_id"
                                    :options="isParent.mPaketAlkes" label="text"
                                    @input="selectJumlahSatuanPaketAlkes($event,k)" :clearable="true"
                                    :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                </v-select>
                                </template>

                                <template v-else-if="isParent.resepDokter[k]['aurd_jenis'] == 5">
                                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                                    placeholder="Pilih Item" v-model="isParent.resepDokter[k].aurd_item_id"
                                    :options="isParent.mAlatInfus" label="text"
                                    @input="selectJumlahSatuanInfus($event,k)" :clearable="true"
                                    :reduce="v=>v.value">
                                    <template slot="selected-option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                    <span>{{ option.text }}</span>
                                    </template>
                                </v-select>
                                </template>

                                <a href="javascript:;" v-if="isParent.resepDokter[k]['aurd_jenis'] !== 3 && isParent.resepDokter[k]['aurd_jenis'] !== 4"
                                @click="isParent.resepDokter[k]['aurd_item_id'] = 99999; isParent.resepDokter[k]['aurd_nama'] = 'Lainnya'"
                                v-b-tooltip.hover="'Pilih Lainnya'"
                                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                    class="icon-plus-circle2"></i></a>
                            </div>
                            <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi"
                                v-model="isParent.resepDokter[k]['aurd_item_id']" :rules="{required : 1}" />

                            <div v-if="isParent.resepDokter[k]['aurd_item_id'] == 99999">
                                <b-form-input type="text" v-model="isParent.resepDokter[k]['aurd_item_lainnya']"
                                class="form-control" id="itemCount" />
                                <VValidate :name="'Item Lainnya '+(k+1)"
                                message="Nama Item Lainnya Harus Diisi"
                                v-model="isParent.resepDokter[k]['aurd_item_lainnya']" :rules="{required : 1}" />
                            </div>

                            <a v-if="isParent.resepDokter[k].aurd_jenis == 3 && isParent.resepDokter[k]['aurd_item_id']"
                                href="javascript:;" @click="toRacikan(v,true)" data-popup="tooltip" title=""
                                data-toggle="modal" data-target="#modalRacikanDetail"
                                class="font-weight-semibold border-bottom"
                                data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>
                                
                            <a v-if="isParent.resepDokter[k].aurd_jenis == 4 && isParent.resepDokter[k]['aurd_item_id']"
                                href="javascript:;" @click="toPaketAlkes(v,true)" data-popup="tooltip" title=""
                                data-toggle="modal" data-target="#modalRacikanDetail"
                                class="font-weight-semibold border-bottom"
                                data-original-title="Lihat Detail Alkes">Lihat Detail Alkes</a>

                            <small class="text-warning"
                                v-if="isAlergiLabel(isParent.resepDokter[k].aurd_nama) ">Obat ini termasuk dalam
                                kategori alergi</small>
                            </td>
                            <td>
                            <div class="input-group">
                                <b-form-input type="text" v-model="isParent.resepDokter[k]['aurd_jumlah']"
                                :formatter="isParent.number" class="form-control" id="itemCount" />
                                <div class="input-group-append">
                                <span class="input-group-text">{{isParent.resepDokter[k]['aurd_satuan']}}</span>
                                </div>
                            </div>

                            <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                                v-model="isParent.resepDokter[k]['aurd_jumlah']" :rules="{required : 1}" />
                            </td>
                            <td>
                            <span class="d-flex">
                                <v-select style="flex: 1;" class="med-selection"
                                placeholder="Pilih Frekuensi" v-model="isParent.resepDokter[k].aurd_frekuensi"
                                :options="isParent.mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                                </v-select>
                                <a href="javascript:;" @click="isParent.resepDokter[k]['aurd_frekuensi'] = 99999"
                                v-b-tooltip.hover="'Pilih Lainnya'"
                                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                    class="icon-plus-circle2"></i></a>
                            </span>
                            <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                                v-model="isParent.resepDokter[k]['aurd_frekuensi']" 
                                v-if="isParent.resepDokter[k].aurd_jenis != 2"
                                :rules="{required : 1}" />

                            <div v-if="isParent.resepDokter[k]['aurd_frekuensi'] == 99999">
                                <b-form-input type="text"
                                v-model="isParent.resepDokter[k]['aurd_frekuensi_lainnya']" class="form-control"
                                id="itemCount" />
                                <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                                message="Frekuensi Lainnya Harus Diisi"
                                v-if="isParent.resepDokter[k].aurd_jenis != 2"
                                v-model="isParent.resepDokter[k]['aurd_frekuensi_lainnya']"
                                :rules="{required : 1}" />
                            </div>
                            </td>
                            <td>
                            <b-textarea v-model="isParent.resepDokter[k].aurd_keterangan" name="itemNotes"
                                id="itemNotes" rows="2" placeholder="cth. obat dihabiskan"
                                class="form-control"></b-textarea>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot v-if="canEditFarmasi">
                        <tr>
                            <td class="text-center" colspan="99">
                            <a href="javascript:;" @click="openObat()" class="btn alpha-blue"
                                data-toggle="modal" data-target="#modalResep">
                                <i class="icon-plus2 mr-1 align-middle"></i>
                                <span class="align-middle">Tambah Resep</span>
                            </a>
                            <a href="javascript:;" @click="openTemplateResep" v-if="isParent.row.is_have_template_resep" class="btn alpha-warning ml-1"
                                data-toggle="modal" data-target="#modalResep">
                                <i class="fas fa-receipt mr-1 align-middle"></i>
                                <span class="align-middle">Ambil Dari Template</span>
                            </a>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    
                    <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div>
                                <label for="">Catatan Resep</label>
                                <textarea v-model="isParent.row.aupd_catatan_resep" class="form-control" rows="3"></textarea>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="rencanaTindakan">Rencana Pengobatan & Tindakan</label>
                                <textarea v-model="isParent.row.aupd_rencana_pengobatan" name="rencanaTindakan" id="rencanaTindakan" cols="30" rows="3" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div class="card-footer" v-if="(isParent.resepDokter||[]).length">
                        <div class="d-flex float-right">
                            <!--
                            <button v-if="isParent.rowReg.aur_is_farmasi_at" @click="cetakAntrianFarmasi()" type="button" class="btn btn-labeled btn-labeled-left btn-primary mr-1">
                            <b><i class="icon-printer"></i></b>
                            <span>Cetak</span>
                            </button>
                            -->

                            <button @click="submitFarmasi()" type="button" class="btn btn-labeled btn-labeled-left btn-primary">
                            <b><i class="icon-paperplane"></i></b>
                            <span>Kirimkan</span>
                            </button>
                        </div>
                        <br>
                        <br>
                        <small class="text-secondary mt-1 float-right" v-if="isParent.rowReg.aur_is_farmasi_at">Terakhir dikirim: 
                        {{isParent.rowReg.aur_is_farmasi_at | moment("DD MMMM YYYY, HH:mm")}} WIB</small>
                    </div>
                    </validation-observer>
                </div>
                </div>

                <div class="col-12">
                <div class="card">
                    <validation-observer
                        ref="VFormTindakLanjut"
                    >
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                    </div>
                   
                    <div class="card-body p-3">
                    <div class="form-group">
                        <label for="opsiTindakLanjut">Tindak Lanjut<strong class="text-danger">*</strong></label>
                        
                        <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="isParent.row['aupd_tindak_lanjut']"
                            name="tindak-lanjut-dokter"
                        >
                            <b-form-checkbox value="RI">Rawat Inap</b-form-checkbox>
                            <b-form-checkbox @change="changeRujuk($event)" value="RU">Rujuk</b-form-checkbox>
                            <b-form-checkbox value="RJ">Rawat Jalan</b-form-checkbox>
                            <b-form-checkbox value="O">Observasi</b-form-checkbox>
                            <b-form-checkbox value="P">Melahirkan</b-form-checkbox>

                        </b-form-checkbox-group>

                        <VValidate :name="'Tindak Lanjut'"
                            v-model="isParent.row['aupd_tindak_lanjut']" :rules="{required : 1}" />
                    </div>  
                    

                    <div v-if="(isParent.row.aupd_tindak_lanjut||[]).indexOf('RU') != -1" class="dokterTindakLanjut card" id="tlRujuk">
                        <div class="card-header bg-info">
                        <h6 class="card-title font-weight-semibold">Rujuk</h6>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Alasan Merujuk<small class="txt_mandatory">*</small></label>
                                        <v-select placeholder="Alasan Rujuk" v-model="isParent.row.aupd_alasan_rujuk"
                                        :options="Config.mr.alasanRujuk" label="text" :clearable="true"
                                        :reduce="v=>v.value">
                                        </v-select>
                                        <VValidate :name="'Alasan Rujuk'"
                                        v-model="isParent.row.aupd_alasan_rujuk"
                                        :rules="{required : 1}" />
                                    </div>
                                </div>

                                <div class="col-md-3" v-if="isParent.row.aupd_alasan_rujuk">
                                    <div class="form-group">
                                        <label>{{getConfigDynamic(Config.mr.alasanRujuk, isParent.row.aupd_alasan_rujuk)||"-"}}<small class="txt_mandatory">*</small></label>
                                        <div class="clearfix"></div>
                                        <b-form-input v-model="isParent.row.aupd_alasan_rujuk_text" type="text" class="form-control" />

                                        <VValidate :name="'Alasan DiRujuk'"
                                        v-model="isParent.row.aupd_alasan_rujuk_text"
                                        :rules="{required : 1}" />
                                    </div>
                                </div>
                                
                                <!--
                                <div class="col-md-6" v-if="isParent.rowReg.aur_mcp_id == 137 || isParent.rowReg.aur_mcp_id == 138">
                                    <div class="form-group">
                                        <label for="poliSelection">Pilih Fakes Rujukan BPJS</label>
                                        <v-select placeholder="Pilih Fakes Rujukan"
                                        v-model="isParent.row.aupd_faskes_rujuk"
                                        :options="isParent.mFaskes" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                        <button type="button" class="btn btn-light mr-3 mt-2" @click="openFaskes = true">Tambah Faskes Rujukan BPJS</button>
                                        <VValidate :name="'Faskes Rujukan BPJS'"
                                        v-model="isParent.row.aupd_faskes_rujuk"
                                        :rules="{required : 1}" />
                                    </div>
                                </div>
                                -->

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="poliSelection">Cari Fakes Rujukan</label>

                                        <v-select class="diagnosa-section select-paging" placeholder="Cari Nama Faskes"
                                            v-model="isParent.row.aupd_faskes_rujuk_sisrute" 
                                            @input="inpMasterFaskesSisrute($event)"
                                            @search="getMasterFaskesSisrute($event)"
                                            :options="mFaskesSisrute" label="NAMA" value="KODE"
                                            :clearable="true" :reduce="v=>v.KODE">
                                            
                                            <template slot="no-options">
                                                <span></span>
                                            </template>
                                        </v-select>

                                    </div>
                                </div>

                                

                                
                                <div class="col-md-6">            
                                    <div class="form-group col-md-12">
                                    <label>Diagnosa Pelayanan</label>
                                    <v-select class="diagnosa-section select-paging" placeholder="Cari Diagnosa"
                                        v-model="isParent.row.auod_diagnosa_kode_bpjs" 
                                        @input="inpMasterDiagnosa($event)"
                                        @search="getMasterDiagnosa($event)"
                                        :options="mDiagnosa" label="nama" value="kode"
                                        :clearable="true" :reduce="v=>v.kode">
                                        
                                        <template slot="selected-option" slot-scope="option">
                                            <span v-b-tooltip.hover.right :title="option.nama">{{ option.nama }}</span>
                                        </template>
                                        
                                        <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover.right :title="option.nama">{{ option.nama }}</span>
                                        </template>
                                        <template slot="no-options">
                                            <span></span>
                                        </template>
                                    </v-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                        <div class="form-row">
                                            <div class="form-group col-md-5">
                                            <label>Peralatan Media yang Terpasang<small class="txt_mandatory">*</small></label>
                                            <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                                v-model="isParent.row.aupd_is_media" />
                                            <VValidate :name="'Peralatan Media yang Terpasang'"
                                            v-model="isParent.row.aupd_is_media"
                                            :rules="{required : 1}" />
                                            </div>
                                            <div class="form-group col-md-4 mt-3" v-if="isParent.row.aupd_is_media == 'Y'">
                                            <v-select multiple class="vs__multiple" placeholder="Pilih Media" v-model="isParent.row.aupd_media"
                                                :options="isParent.Config.mr.mediaTerpasang" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                            </v-select>
                                            <VValidate :name="'Media'"
                                            v-model="isParent.row.aupd_media"
                                            :rules="{required : 1}" />
                                            </div>
                                        </div>

                                        </div>

                                    </div>
                                </div>
                                
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label> Perawatan Pasien yang dibutuhkan<small class="txt_mandatory">*</small></label>
                                        <textarea v-model="isParent.row.aupd_perawatan_pasien" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                                        
                                        <VValidate :name="'Perawatan Pasien yang dibutuhkan'"
                                        v-model="isParent.row.aupd_perawatan_pasien"
                                        :rules="{required: 1, min: 3, max: 128}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="(isParent.row.aupd_tindak_lanjut||[]).indexOf('RJ') != -1" class="dokterTindakLanjut card" id="tlRawatJalan">
                        <div class="card-header bg-info">
                        <h6 class="card-title font-weight-semibold">Rawat Jalan</h6>
                        </div>
                        <div class="card-body">
                        <div class="form-group">
                            <label for="">Tindakan Selanjutnya</label>
                            <b-form-radio-group
                                :options="isParent.Config.mr.tindakLanjutRawatJalan"
                                v-model="isParent.row.aupd_rawat_jalan_tindakan"
                            />
                            <VValidate :name="`Tindakan Selanjutnya`" v-model="isParent.row.aupd_rawat_jalan_tindakan" :rules="{required:1}" />
                        </div>
                        <hr>
                        <div class="rajalForm d-none" id="rajalPartus">
                            <div class="alert alert-info mb-0">Tindakan selanjutnya akan diteruskan oleh perawat dan dokter obgyn/bidan</div>
                        </div>
                        <div class="rajalForm" id="rajalPulang">
                            <div class="alert alert-info mb-0">{{isParent.row.aupd_rawat_jalan_tindakan == 'P' ? 'Tindakan selanjutnya akan diteruskan oleh perawat dan dokter obgyn/bidan' : 'Tindakan selanjutnya akan diteruskan oleh perawat/dokter'}}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div v-if="(isParent.row.aupd_tindak_lanjut||[]).indexOf('RI') != -1" class="dokterTindakLanjut card" id="tlRawatInap">
                        <div class="card-header bg-info">
                        <h6 class="card-title font-weight-semibold">Rawat Inap</h6>
                        </div>
                        <div class="card-body">
                        <div class="row">

                            <div class="col-md-4 col-lg-3" v-if="isPoliAdjRanap">
                                <div class="form-group">
                                    <label for="selectRuangan">Jenis Rawat Inap</label>
                                    <v-select placeholder="Pilih Jenis Rawat Inap" style="width: 160px;" v-model="isParent.row.aupd_jenis_ranap"
                                    :options="Config.mr.mJenisRanapNormal" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>

                                <div class="form-group">
                                    <b-form-checkbox
                                        id="pasien_operasi"
                                        v-model="isParent.row.aupd_is_operasi"
                                        name="pasien_operasi"
                                        value="Y"
                                        unchecked-value="N"
                                    >
                                    Pasien akan Langsung di Operasi
                                    </b-form-checkbox>
                                </div>                                
                            </div>
                            <div class="col-md-4 col-lg-3" v-else>
                                <div class="form-group">
                                        <label for="selectRuangan">Ruangan</label>
                                        <v-select placeholder="Pilih Ruangan" style="width: 160px;" v-model="isParent.row.aupd_rawat_inap_ruangan"
                                        :options="isParent.mRuangan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4">
                            <div class="form-group">
                                <label for="namaDPJP">Nama DPJP <strong class="text-danger">*</strong></label>

                                <v-select placeholder="Pilih Dokter" style="width: 160px;" v-model="isParent.row.aupd_dokter_ranap"
                                :options="isParent.mDokterRanap" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                
                                <VValidate :name="`Nama DPJP`" v-model="isParent.row.aupd_dokter_ranap" :rules="{required:1}" />
                            </div>
                            </div>
                        </div>

                        <div class="row" v-if="isParent.row.aupd_is_operasi == 'Y'">
                            <div class="col-md-12">
                                <Operasi />
                            </div>
                        </div>
                        
                        </div>
                    </div>
                    <div v-if="(isParent.row.aupd_tindak_lanjut||[]).indexOf('P') != -1" class="rajalForm mb-3" id="meninggalUgd">
                        <div class="alert alert-info mb-0">Tindakan selanjutnya akan diteruskan oleh perawat/dokter</div>
                    </div>
                    
                    <div class="card-footer">
                        <div class="d-flex flex-column align-items-end">
                            <button @click="submitTindakLanjut()" type="button" class="btn btn-labeled btn-labeled-left btn-primary">
                            <b><i class="icon-paperplane"></i></b>
                            <span>Kirimkan</span>
                            </button>
                            <small class="text-secondary mt-1" v-if="isParent.rowReg.aur_is_tindak_lanjut_at">Terakhir dikirim: 
                            {{isParent.rowReg.aur_is_tindak_lanjut_at | moment("DD MMMM YYYY, HH:mm")}} WIB</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <b-form-checkbox @input="autoFillDPJP" :disabled="isParent.rowReg.aur_is_inserted_laporan_dpjp == 'Y'" v-model="isParent.row.aupd_laporan_dpjp"
                        value="Y" unchecked-value="N">
                            Tambahkan Laporan untuk DPJP?
                        </b-form-checkbox>
                        <template v-if="isParent.rowReg.aur_is_inserted_laporan_dpjp_at && isParent.rowReg.aur_is_inserted_laporan_dpjp_by">
                        <small class="text-info">Terakhir diupdate : {{isParent.rowReg.aur_is_inserted_laporan_dpjp_at | moment("DD MMMM YYYY, HH:mm")}} - {{isParent.rowReg.aur_is_inserted_laporan_dpjp_by}}</small>
                        </template>
                    </div>

                    <div class="card border shadow-0">
                        <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold">Catatan Asuhan</h5>
                        </div>
                        <div class="table-responsive">
                        <table class="table table-bordered table-sm table-align-top">
                            <thead>
                            <tr>
                                <th>Waktu</th>
                                <th>Asesmen/Tindakan</th>
                                <th>Waktu Evaluasi</th>
                                <th>Evaluasi</th>
                                <th>PPA</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody v-if="isParent.row.aupd_laporan_dpjp == 'Y'">
                            <tr class="table-info collapsed c-pointer" @click="isParent.row.isOpenLaporanDPJP = !isParent.row.isOpenLaporanDPJP">
                                <td colspan="6"><span class="font-weight-semibold text-info-600">Laporan DPJP #1</span></td>
                            </tr>
                            <tr class="dpjpEl" v-if="isParent.row.isOpenLaporanDPJP">
                                <td>
                                <div class="form-group">
                                    <label>Tanggal</label>
                                    <div class="input-group mb-3">
                                        <datepicker input-class="form-control transparent"
                                        placeholder="Pilih Tanggal" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar" v-model="isParent.row.aupd_laporan_dpjp_tanggal">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Jam</label>
                                    <div class="input-group bootstrap-timepicker timepicker">
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control custom-timepicker" v-model="isParent.row.aupd_laporan_dpjp_waktu">
                                    </vue-timepicker>
                                    </div>
                                </div>
                                </td>
                                <td style="min-width: 400px;">
                                <div class="tb-content">
                                    <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                                    <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama {{isParent.row.ausd_keluhan||"-"}} <span v-if="isParent.row.ausd_riwayat">, Pasien mempunyai riwayat penyakit dahulu: {{isParent.row.ausd_riwayat||"-"}} </span></p>
                                    <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                                        <ul class="mb-0" v-if="isParent.row.ausd_has_alergi == 'Y'">
                                            <li v-for="(v,k) in (isParent.row.ausd_alergi||[])" :key="k"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v.jenis)}})</span>{{v.name||"-"}}</li>

                                            <span v-if="!(isParent.row.ausd_alergi||[]).length"> - </span>
                                        </ul>
                                        <span v-else> - </span>
                                    </p>
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                                    <div>{{isParent.row.aupd_rencana_pengobatan||"-"}}</div>
                                    </div>
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">Riwayat Pembedahan</span>
                                    <textarea v-model="isParent.row.aupd_laporan_dpjp_riwayat_pembedahan" rows="2" class="form-control form-control-sm"></textarea>
                                    </div>
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Invasif</span>
                                    <ol class="mb-0" style="margin-left: -35px;" v-if="(isParent.row.aupd_laporan_dpjp_tindakan_invasif||[]).length">
                                        <li v-for="(v,k) in (isParent.row.aupd_laporan_dpjp_tindakan_invasif||[])" :key="k" class="mt-1">
                                        <div class="input-group">
                                            <input v-model="isParent.row.aupd_laporan_dpjp_tindakan_invasif[k]" type="text" class="form-control form-control-sm">
                                            <VValidate :name="`Tindakan Invasif `+(k+1)" v-model="isParent.row.aupd_laporan_dpjp_tindakan_invasif[k]" :rules="{required:1}" />
                                            <div class="input-group-append">
                                            <a href="javascript:;" 
                                            @click="(isParent.row.aupd_laporan_dpjp_tindakan_invasif||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                                <i class="icon-trash-alt"></i>
                                            </a>
                                            <a href="javascript:;" v-if="k == ((isParent.row.aupd_laporan_dpjp_tindakan_invasif||[]).length - 1)" @click="isParent.row.aupd_laporan_dpjp_tindakan_invasif.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                                <i class="icon-plus2"></i>
                                            </a>
                                            </div>
                                        </div>
                                        </li>
                                    </ol>
                                    <div class="" v-else>
                                        <button type="button" class="btn btn-light mr-3" @click="isParent.row.aupd_laporan_dpjp_tindakan_invasif.push('')">Tambah Tindakan</button>
                                    </div>
                                    </div>
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">A: </span>Keluhan saat ini
                                    <textarea v-model="isParent.row.aupd_laporan_dpjp_keluhan" rows="2" class="form-control form-control-sm"></textarea>
                                    <VValidate :name="`Keluhan saat ini`" v-model="isParent.row.aupd_laporan_dpjp_keluhan" :rules="{required:1, min: 2, max: 1500}" />

                                    </div>
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">Hasil Skor Nyeri</span>

                                    <div>{{isParent.row.auod_skala_nyeri_value}} | 

                                        <template v-if="(isParent.row.ap_usia > 6 || (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.row.auod_nyeri_type == 'NP'))">
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="isParent.row.auod_skala_nyeri_value == 0">Tidak Ada Nyeri</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="isParent.row.auod_skala_nyeri_value >= 1 && isParent.row.auod_skala_nyeri_value <= 3">Sedikit Nyeri</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="isParent.row.auod_skala_nyeri_value >= 4 && isParent.row.auod_skala_nyeri_value <= 5">Agak Mengganggu</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="isParent.row.auod_skala_nyeri_value >= 6 && isParent.row.auod_skala_nyeri_value <= 7">Mengganggu Aktivitas</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="isParent.row.auod_skala_nyeri_value >= 8 && isParent.row.auod_skala_nyeri_value <= 9">Sangat Mengganggu</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="isParent.row.auod_skala_nyeri_value >= 10">Tak Tertahankan</span>
                                        </template>
                                        
                                        <template v-if="(isParent.row.ap_usia <= 6) || (isParent.row.ap_usia == null && isParent.row.ap_dob == null)">
                                            <template v-if="(isParent.row.ap_usia > 1 || (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.row.auod_nyeri_type == 'FL'))">
                                                <span v-if="isParent.row.auod_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                                <span v-else-if="isParent.row.auod_skala_nyeri_value >= 1 && isParent.row.auod_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                                <span v-else-if="isParent.row.auod_skala_nyeri_value >= 4 && isParent.row.auod_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                            </template>

                                            <template v-if="((isParent.row.ap_usia <= 1  && isParent.row.ap_usia != null)|| (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.row.auod_nyeri_type == 'NIPS')) ">
                                                <span v-if="isParent.row.auod_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                                <span v-else-if="isParent.row.auod_skala_nyeri_value >= 3 && isParent.row.auod_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                            </template>
                                        </template>
                                    </div>
                                    </div>
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">Tanda-tanda Vital</span>
                                    <ul class="mb-0">
                                        <li>Tekanan Darah: 
                                        <span v-if="isParent.row.auod_ttv_tekanan_darah_min && isParent.row.auod_ttv_tekanan_darah_max">
                                        {{isParent.row.auod_ttv_tekanan_darah_min||"-"}}/
                                        {{isParent.row.auod_ttv_tekanan_darah_max||"-"}} mmHG
                                        </span>
                                        <span v-else> - </span>
                                        </li>

                                        <li>Nadi: 
                                        <span v-if="isParent.row.auod_ttv_nadi && isParent.row.auod_ttv_label">
                                        {{isParent.row.auod_ttv_nadi||"-"}} x/mnt - {{isParent.row.auod_ttv_label||"-"}}
                                        </span>
                                        <span v-else> - </span>
                                        </li>

                                        <li>Gula Darah: 
                                        <span v-if="isParent.row.auod_ttv_gula_darah">
                                        {{isParent.row.auod_ttv_gula_darah||"-"}} mg/dL
                                        </span>
                                        <span v-else> - </span>
                                        </li>
                                        <li>Pernafasan: 
                                        <span v-if="isParent.row.auod_ttv_pernafasan">
                                        {{isParent.row.auod_ttv_pernafasan||"-"}} x/mnt
                                        </span>
                                        <span v-else> - </span>
                                        </li>
                                        <li>SPO2: 
                                        <span v-if="isParent.row.auod_ttv_spo2">
                                        {{isParent.row.auod_ttv_spo2||"-"}}%
                                        </span>
                                        <span v-else> - </span>
                                        </li>
                                        
                                        <li>Suhu: 
                                        <span v-if="isParent.row.auod_ttv_suhu">
                                        {{isParent.row.auod_ttv_suhu||"-"}}C
                                        </span>
                                        <span v-else> - </span>
                                        </li>
                                        
                                        <li>Berat Badan 
                                        <span v-if="isParent.row.auod_ttv_weight">
                                        {{isParent.row.auod_ttv_weight||"-"}} kg
                                        </span>
                                        <span v-else> - </span>
                                        </li>

                                        <li>Tinggi: 
                                        <span v-if="isParent.row.auod_ttv_height">
                                        {{isParent.row.auod_ttv_height||"-"}} cm
                                        </span>
                                        <span v-else> - </span>
                                        </li>

                                        <li>Lingkar Kepala: 
                                        <span v-if="isParent.row.auod_ttv_lingkar_kepala">
                                        {{isParent.row.auod_ttv_lingkar_kepala||"-"}} cm
                                        </span>
                                        <span v-else> - </span>
                                        </li>

                                        <li>Luas Permukaan Tubuh Anak: 
                                        <span v-if="isParent.row.auod_ttv_luas_permukaan_anak">
                                        {{isParent.row.auod_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup>
                                        </span>
                                        <span v-else> - </span>
                                        </li>

                                        <li>Kesadaran: 
                                            <span>{{isParent.row.auod_gcs_total}} | 
                                            
                                                <span v-if="isParent.row.auod_gcs_total >= 14"> Composmentis</span> 
                                                <span v-else-if="isParent.row.auod_gcs_total >= 12 && isParent.row.auod_gcs_total <= 13">Apatis</span>
                                                <span v-else-if="isParent.row.auod_gcs_total >= 10 && isParent.row.auod_gcs_total <= 11">Delirium</span>
                                                <span v-else-if="isParent.row.auod_gcs_total >= 7 && isParent.row.auod_gcs_total <= 9">Somonolen</span>
                                                <span v-else-if="isParent.row.auod_gcs_total >= 5 && isParent.row.auod_gcs_total <= 6">Sopor</span>
                                                <span v-else-if="isParent.row.auod_gcs_total >= 4 && isParent.row.auod_gcs_total <= 4">Semi-Comma</span>
                                                <span v-else>Coma</span>
                                                
                                                <span class="ml-2" style="margin-right:20px;">E: 
                                                    {{isParent.row.auod_response_mata||0}} 
                                                </span>
                                                <span style="margin-right:20px;">M: 
                                                    {{isParent.row.auod_response_motorik||0}}  
                                                </span>
                                                <span style="margin-right:20px;">V: 
                                                    {{isParent.row.auod_response_verbal||0}}
                                                </span>
                                            </span>
                                        </li>


                                    </ul>
                                    </div>

                                    
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">Pemeriksaan Fisik</span>
                                    <ul class="mb-0">
                                        <template v-for="(v,k) in (Config.mr.configVitalSignUGD||[])">
                                            <div :key="k" v-if="v.value != 'auod_vs_saraf'">
                                                <li v-if="isParent.row[v.value] != 'TP'"> 
                                                    {{v.label}} : <span>{{isParent.row[v.value+'_text']}}</span>
                                                </li>
                                            </div>
                                            <div :key="k" v-else>
                                                <template v-if="isParent.row[v.value] != 'TP'">
                                                    <li>
                                                        <span> {{v.label}} :</span>
                                                    </li>
                                                    <p class="mb-0">Refleks Fisiologis : {{isParent.row.auod_vs_saraf_r_fisiologis||"-"}}</p>
                                                    <p class="mb-0">Refleks Patologis : {{isParent.row.auod_vs_saraf_r_patologis||"-"}}</p>
                                                    <p class="mb-0">Babinski : {{isParent.row.auod_vs_saraf_babinski||"-"}}</p>
                                                    <p class="mb-0">Lasegue : {{isParent.row.auod_vs_saraf_lasegue||"-"}}</p>
                                                    <p class="mb-0">Bregard : {{isParent.row.auod_vs_saraf_bregard||"-"}}</p>
                                                    <p class="mb-0">Sicard : {{isParent.row.auod_vs_saraf_sicard||"-"}}</p>
                                                    <p class="mb-0">Kaku Duduk : {{isParent.row.auod_vs_saraf_kaku_duduk||"-"}}</p>
                                                    <p class="mb-0">Kernig Sign : {{isParent.row.auod_vs_saraf_kernig_sign||"-"}}</p>
                                                    <p class="mb-0">Brudzinski 1: {{isParent.row.auod_vs_saraf_brudzinski||"-"}}</p>
                                                    <p class="mb-0">Brudzinski 2: {{isParent.row.auod_vs_saraf_brudzinski2||"-"}}</p>
                                                </template>
                                            </div>
                                        </template>

                                    </ul>
                                    <span v-if="!isHavePemeriksaanFisik">Hasil pemeriksaan fisik normal</span>
                                    </div>

                                    <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Lab</span>
                                    <p>{{selectedLabInput||"-"}}</p>
                                    </div>
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Radiologi</span>
                                    <p>{{selectedRadioInput||"-"}}</p>
                                    </div>
                                    <div class="mb-2">
                                    <span class="font-weight-semibold">R</span>
                                    <div class="mb-1">
                                        <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                                        <ol class="mb-1" style="margin-left: -35px;" v-if="(isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                                        <li v-for="(v,k) in (isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k" class="mt-1">
                                        <div class="input-group">
                                            <input v-model="isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi[k]" type="text" class="form-control form-control-sm">
                                            <VValidate :name="`Tindakan Invasif `+(k+1)" v-model="isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi[k]" :rules="{required:1}" />
                                            <div class="input-group-append">
                                            <button 
                                            @click="(isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                                <i class="icon-trash-alt"></i>
                                            </button>
                                            <button v-if="k == ((isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length - 1)" @click="isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                                <i class="icon-plus2"></i>
                                            </button>
                                            </div>
                                        </div>
                                        </li>
                                        </ol>
                                        <div class="" v-else>
                                            <button type="button" class="btn btn-light mr-3" @click="isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi.push('')">Tambah Tindakan</button>
                                        </div>
                                    </div>
                                    <div class="mb-1">
                                        <span>Usulan kolaborasi</span>
                                        <ol class="mb-1" style="margin-left: -35px;" v-if="(isParent.row.aupd_laporan_dpjp_usulan_kolaborasi||[]).length">
                                        <li v-for="(v,k) in (isParent.row.aupd_laporan_dpjp_usulan_kolaborasi||[])" :key="k" class="mt-1">
                                        <div class="input-group">
                                            <input v-model="isParent.row.aupd_laporan_dpjp_usulan_kolaborasi[k]" type="text" class="form-control form-control-sm">
                                            <VValidate :name="`Tindakan Invasif `+(k+1)" v-model="isParent.row.aupd_laporan_dpjp_usulan_kolaborasi[k]" :rules="{required:1}" />
                                            <div class="input-group-append">
                                            <button 
                                            @click="(isParent.row.aupd_laporan_dpjp_usulan_kolaborasi||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                                <i class="icon-trash-alt"></i>
                                            </button>
                                            <button v-if="k == ((isParent.row.aupd_laporan_dpjp_usulan_kolaborasi||[]).length - 1)" @click="isParent.row.aupd_laporan_dpjp_usulan_kolaborasi.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                                <i class="icon-plus2"></i>
                                            </button>
                                            </div>
                                        </div>
                                        </li>
                                        </ol>
                                        <div class="" v-else>
                                            <button type="button" class="btn btn-light mr-3" @click="isParent.row.aupd_laporan_dpjp_usulan_kolaborasi.push('')">Tambah Tindakan</button>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="text-right mt-2 pt-2 border-top">
                                    <a href="javascript:;" @click="copyKolab('P',isParent.row)" data-toggle="modal" class="btn btn-sm alpha-info border-info text-info-600">
                                        <i class="icon-copy3 mr-1"></i>
                                        <span>Salin Data</span>
                                    </a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                <div class="form-group">
                                    <label>Tanggal</label>
                                    <div class="input-group mb-3">
                                        <datepicker input-class="form-control transparent"
                                        placeholder="Pilih Tanggal" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar" v-model="isParent.row.aupd_laporan_dpjp_tanggal_evaluasi">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Jam</label>
                                    <div class="input-group bootstrap-timepicker timepicker">
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control custom-timepicker" v-model="isParent.row.aupd_laporan_dpjp_waktu_evaluasi">
                                    </vue-timepicker>
                                    </div>
                                </div>
                                </td>
                                <td style="min-width: 300px;">
                                <div class="form-group" 
                                v-for="(vU,kU) in (isParent.row.aupd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                                    <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                                    <textarea @input="inputEvalText($event,kU)" v-model="isParent.row.aupd_laporan_dpjp_evaluasi_data[kU]['value']" rows="3" class="form-control"></textarea>
                                    
                                    <div class="mb-2 mt-2 d-flex">
                                        <b-form-checkbox v-model="isParent.row.aupd_laporan_dpjp_evaluasi_data[kU]['is_done']"
                                        value="Y" unchecked-value="N"
                                        @input="inputEval(kU)"
                                        >
                                        Evaluasi telah Dibaca Ulang
                                        </b-form-checkbox>
                                        
                                        <a href="javascript:;" @click="isParent.row.aupd_laporan_dpjp_evaluasi_data.splice(kU,1)" v-b-tooltip.hover.right title="Hapus Evaluasi DPJP" class="text-danger float-right ml-auto"><i class="icon-bin"></i></a>
                                    </div>

                                        
                                    <span v-if="isParent.row.aupd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">
                                    Selesai pada {{isParent.row.aupd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                                </div>
                                <div class="ml-1 mb-2"><button type="button" style="font-size: 12px;" 
                                @click="addEvaluasi()" class="btn btn-light mr-3">Tambah Evaluasi</button></div>
                                
                                <div id="verifikasiRanap">
                                
                                    <p class="font-weight-semibold text-blue-800"></p>
                                    <div class="alert alert-info">Perintah DPJP telah dilakukan Penulisan, Pembacaan dan Konfirmasi ulang oleh: {{user.fullName}}</div>
                                    
                                    <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>
                                    
                                    <div class="form-group">
                                    <label for="">Nama DPJP <small class="text-danger">*</small></label>
                                    <v-select placeholder="Pilih Dokter" style="width: 160px;" v-model="isParent.row.aupd_laporan_dpjp_nama"
                                    :options="isParent.mDokterAll" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    <VValidate :name="`Nama DPJP Verifikasi`" v-model="isParent.row.aupd_laporan_dpjp_nama" :rules="{required:1}" />
                                    </div>

                                    <div class="form-group">
                                    <label>Tanggal <small class="text-danger">*</small></label>
                                    <div class="input-group">
                                        <datepicker input-class="form-control transparent"
                                        placeholder="Pilih Tanggal" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar" 
                                        @input="inputDataCADPJP(isParent.row.aupd_laporan_dpjp_evaluasi_date,isParent.row.aupd_laporan_dpjp_evaluasi_waktu)" v-model="isParent.row.aupd_laporan_dpjp_evaluasi_date">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                    <VValidate :name="`Tanggal Verifikasi`" v-model="isParent.row.aupd_laporan_dpjp_evaluasi_date" :rules="{required:1}" />
                                    </div>

                                    <div class="form-group">
                                    <label>Jam <small class="text-danger">*</small></label>
                                    <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input @input="inputDataCADPJP(isParent.row.aupd_laporan_dpjp_evaluasi_date,isParent.row.aupd_laporan_dpjp_evaluasi_waktu)" format="HH:mm" input-class="form-control custom-timepicker" v-model="isParent.row.aupd_laporan_dpjp_evaluasi_waktu">
                                        </vue-timepicker>
                                    </div>
                                    <VValidate :name="`Jam Verifikasi`" v-model="isParent.row.aupd_laporan_dpjp_evaluasi_waktu" :rules="{required:1}" />
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <v-select placeholder="Pilih PPA" style="width: 160px;" v-model="isParent.row.aupd_laporan_dpjp_ppa"
                                    :options="isParent.Config.mr.isPerawatOrDokter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                    <div class="form-group mt-2">
                                      <label for="">Nama PPA</label>
                                      <input type="text"
                                        class="form-control" v-model="isParent.row.aupd_laporan_dpjp_ppa_name" />
                                    </div>
                                </td>
                                <td>
                                <a href="javascript:;" class="list-icons-item"
                                @click="isParent.row.aupd_laporan_dpjp = 'N'" v-b-tooltip.hover.right data-toggle="tooltip"
                                data-placement="top" title="Hapus Laporan DPJP"><i class="icon-bin"></i></a>

                                </td>
                            </tr>
                            <div class="display-c" v-for="(v,k) in (isParent.catatanDPJPDokter||[])" :key="k">
                            <tr class="table-info c-pointer" @click="isParent.catatanDPJPDokter[k]['isOpen'] = !isParent.catatanDPJPDokter[k]['isOpen']" >
                                <td colspan="6"><span class="font-weight-semibold text-info-600">Laporan DPJP #{{k+2}}</span></td>
                            </tr>
                            <tr class="collapse show" id="detailLapDpjp2" v-if="isParent.catatanDPJPDokter[k]['isOpen']">
                                <td>
                                    <div class="form-group">
                                        <label>Tanggal</label>
                                        <div class="input-group">
                                            <datepicker input-class="form-control transparent"
                                            placeholder="Pilih Tanggal" class="my-datepicker"
                                            calendar-class="my-datepicker_calendar" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tanggal">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Jam</label>
                                        <div class="input-group bootstrap-timepicker timepicker">
                                            <vue-timepicker manual-input format="HH:mm" input-class="form-control custom-timepicker" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_waktu">
                                            </vue-timepicker>
                                        </div>
                                    </div>
                                </td>
                                <td style="min-width: 440px;">
                                    <div class="tb-content">
                                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                                        <div>

                                        <div class="form-group">              
                                        <span class="font-weight-semibold">Subjektif</span>
                                        <b-form-input type="text" v-model="isParent.catatanDPJPDokter[k].auldd_subjektif" class="form-control form-control-sm d-block" placeholder="Subjektif" />
                                        </div>
                                        
                                        <div class="form-group">   
                                        <span class="font-weight-semibold">Riwayat Penyakit</span>
                                        <b-form-input type="text" v-model="isParent.catatanDPJPDokter[k].auldd_riwayat_penyakit" class="form-control form-control-sm d-block" placeholder="Riwayat Penyakit" />
                                        </div>
                                        
                                        <div class="form-group">             
                                        <span class="font-weight-semibold">Catatan Dokter</span>
                                        <b-form-input type="text" v-model="isParent.catatanDPJPDokter[k].auldd_catatan_dokter" class="form-control form-control-sm d-block" placeholder="Catatan Dokter" />
                                        </div>

                                        <div class="mb-2">
                                        <span class="font-weight-semibold">Riwayat Alergi</span>
                                            <table class="table table-sm table-bordered">
                                                <thead>
                                                <tr>
                                                    <th width="33%">Jenis</th>
                                                    <th>Informasi Alergi</th>
                                                    <th width="64"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(v1,k1) in (isParent.catatanDPJPDokter[k]['auldd_riwayat_alergi']||[])" :key="k1+'aler'">
                                                    <td>
                                                        <v-select placeholder="Pilih Jenis"
                                                        v-model="v1['jenis']" :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </td>
                                                    <td>
                                                        <vue-typeahead-bootstrap
                                                        v-model="v1['name']"
                                                        :data="isParent.mAlergi" @input="searchAlergi(v1['name'])" placeholder="Pilih Alergi"
                                                        />
                                                    </td>
                                                    <td>
                                                    <a href="javascript:;" @click="removeAlergi(k,k1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td colspan="3" class="text-center">
                                                    <a href="javascript:;" @click="addAlergi(k)" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                                    </td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                        <div class="mb-2">
                                        <span class="font-weight-semibold">Riwayat Pembedahan</span>
                                        <textarea v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_riwayat_pembedahan" rows="2" class="form-control form-control-sm"></textarea>
                                        </div>
                                        
                                        <div class="mb-2">
                                        <span class="font-weight-semibold">Tindakan Invasif</span>
                                        <ol class="mb-0" style="margin-left: -35px;" v-if="(isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tindakan_invasif||[]).length">
                                            <li v-for="(v2,k2) in (isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tindakan_invasif||[])" :key="k2" class="mt-1">
                                            <div class="input-group">
                                                <input v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tindakan_invasif[k2]" type="text" class="form-control form-control-sm">
                                                <VValidate :name="`Tindakan Invasif `+(k+1)" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tindakan_invasif[k2]" :rules="{required:1}" />
                                                <div class="input-group-append">
                                                <a href="javascript:;" 
                                                @click="(isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tindakan_invasif||[]).splice(k,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                                    <i class="icon-trash-alt"></i>
                                                </a>
                                                <a href="javascript:;" v-if="k == ((isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tindakan_invasif||[]).length - 1)" @click="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tindakan_invasif.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                                    <i class="icon-plus2"></i>
                                                </a>
                                                </div>
                                            </div>
                                            </li>
                                        </ol>
                                        <div class="" v-else>
                                            <button type="button" class="btn btn-light mr-3" @click="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tindakan_invasif.push('')">Tambah Tindakan</button>
                                        </div>
                                        </div>
                                        

                                        <div class="mb-2">
                                            <span class="font-weight-semibold">A: </span>Keluhan saat ini
                                            <textarea v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_keluhan" rows="2" class="form-control form-control-sm"></textarea>
                                            
                                            <VValidate :name="`Keluhan saat ini #`+(k+1)" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_keluhan" :rules="{required:1, min: 2, max: 1500}" />
                                        </div>

                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                                            <b-form-input style="
                                                width: 200px;
                                            " type="text" :formatter="isParent.number" v-model="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri" class="form-control form-control-sm d-block" placeholder="Hasil Skoring Nyeri" />

                                            <span>Keterangan : </span>

                                            <template v-if="(isParent.row.ap_usia > 6 || (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.row.auod_nyeri_type == 'NP'))">
                                                <strong v-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri == 0">Tidak Ada Nyeri</strong>
                                                <strong v-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri >= 1 && isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri <= 3">Sedikit Nyeri</strong>
                                                <strong v-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri >= 4 && isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri <= 5">Agak Mengganggu</strong>
                                                <strong v-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri >= 6 && isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri <= 7">Mengganggu Aktivitas</strong>
                                                <strong v-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri >= 8 && isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri <= 9">Sangat Mengganggu</strong>
                                                <strong v-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri >= 10">Tak Tertahankan</strong>
                                            </template>
                                            
                                            <template v-if="(isParent.row.ap_usia <= 6) || (isParent.row.ap_usia == null && isParent.row.ap_dob == null)">
                                                <template v-if="(isParent.row.ap_usia > 1 || (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.row.auod_nyeri_type == 'FL'))">
                                                    <strong v-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri == 0">Santai dan Nyaman</strong>
                                                    <strong v-else-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri >= 1 && isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri < 4">Ketidaknyamanan ringan</strong>
                                                    <strong v-else-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri >= 4 && isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri < 7">Nyeri Sedang</strong>
                                                    <strong v-else>Ketidaknyamanan/Nyeri Parah</strong>
                                                </template>

                                                <template v-if="((isParent.row.ap_usia <= 1  && isParent.row.ap_usia != null)|| (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.row.auod_nyeri_type == 'NIPS')) ">
                                                    <strong v-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri < 3">Nyeri Ringan, Tidak Nyeri</strong>
                                                    <strong v-else-if="isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri >= 3 && isParent.catatanDPJPDokter[k].auldd_hasil_skoring_nyeri < 5">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</strong>
                                                    <strong v-else>Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</strong>
                                                </template>
                                            </template>

                                        </div>

                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Tanda-tanda Vital</span>
                                            
                                            <div class="form-group">   
                                                <label>Tekanan Darah</label>
                                                <div class="input-group">
                                                    <b-form-input :formatter="isParent.number"
                                                    v-model="isParent.catatanDPJPDokter[k].auldd_ttv_tekanan_darah_min" type="text" name="name"
                                                    class="form-control" placeholder="Systole" />

                                                    <div class="input-group-append input-group-prepend">
                                                    <span class="input-group-text">/</span>
                                                    </div>

                                                    <b-form-input :formatter="isParent.number" placeholder="Diastole"
                                                    v-model="isParent.catatanDPJPDokter[k].auldd_ttv_tekanan_darah_max" type="text"
                                                    class="form-control" />
                                                    <div class="input-group-append"><span class="input-group-text">mmHG</span>
                                                    </div>
                                                </div>                                 
                                            </div>

                                            <div class="form-group">  
                                                <label>Nadi</label>
                                                <div class="input-group">
                                                    <b-form-input :formatter="isParent.number"
                                                    v-model="isParent.catatanDPJPDokter[k].auldd_ttv_nadi" type="text" name="name"
                                                    class="form-control" placeholder="Nadi" />

                                                    <div class="input-group-append input-group-prepend">
                                                    <span class="input-group-text">x/mnt</span>
                                                    </div>

                                                    <div class="input-group-append">
                                                        <div style="width: 150px;">
                                                            <v-select placeholder="Pilih Label" v-model="isParent.catatanDPJPDokter[k].auldd_ttv_label"
                                                            :options="Config.mr.StatusRegular" label="text"
                                                            :clearable="true" :reduce="v=>v.value"></v-select>
                                                        </div>
                                                    </div>

                                                </div>                                 
                                            </div>


                                            <div class="row">
                                                <div class="form-group col-md-6">       
                                                    <label>Gula Darah</label>
                                                    <div class="input-group">
                                                        <b-form-input :formatter="isParent.alphanum"
                                                        v-model="isParent.catatanDPJPDokter[k].auldd_ttv_gula_darah" type="text"
                                                        class="form-control" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text">mg/dL</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="form-group col-md-6">       
                                                    <label>Pernafasan</label>
                                                    <div class="input-group">
                                                        <b-form-input :formatter="isParent.number"
                                                        v-model="isParent.catatanDPJPDokter[k].auldd_ttv_pernafasan" type="text"
                                                        class="form-control" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text">x/mnt</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="row">
                                                <div class="form-group col-md-6">       
                                                    <label>SPO2</label>
                                                    <div class="input-group">
                                                        <b-form-input :formatter="isParent.number"
                                                        v-model="isParent.catatanDPJPDokter[k].auldd_ttv_spo2" type="text"
                                                        class="form-control" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text">%</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-6">       
                                                    <label>Suhu</label>
                                                    <div class="input-group">
                                                        <b-form-input :formatter="isParent.number"
                                                        v-model="isParent.catatanDPJPDokter[k].auldd_ttv_suhu" type="text"
                                                        class="form-control" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text">C</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">                                            
                                                <div class="form-group col-md-6">       
                                                    <label>Berat Badan</label>
                                                    <div class="input-group">
                                                        <b-form-input @input="hitungBMI(k)" :formatter="isParent.number"
                                                        v-model="isParent.catatanDPJPDokter[k].auldd_ttv_weight" type="text"
                                                        class="form-control" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text">kg</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="form-group col-md-6">       
                                                    <label>Tinggi</label>
                                                    <div class="input-group">
                                                        <b-form-input @input="hitungBMI(k)" :formatter="isParent.number"
                                                        v-model="isParent.catatanDPJPDokter[k].auldd_ttv_height" type="text"
                                                        class="form-control" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text">cm</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">                                            
                                                <div class="form-group col-md-6">       
                                                    <label>Lingkar Kepala</label>
                                                    <div class="input-group">
                                                        <b-form-input @input="hitungBMI(k)" :formatter="isParent.number"
                                                        v-model="isParent.catatanDPJPDokter[k].auldd_ttv_lingkar_kepala" type="text"
                                                        class="form-control" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text">cm</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">       
                                                    <label>BMI</label>
                                                    <div class="input-group">
                                                        <b-form-input disabled v-model="isParent.catatanDPJPDokter[k].auldd_ttv_bmi" type="text"
                                                        class="form-control" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text">m2</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                            </div>

                                            <div class="form-group" v-if="isParent.row.ap_usia <= 15">       
                                                <label>Luas Permukaan Tubuh Anak</label>
                                                <div class="input-group">
                                                    <b-form-input :formatter="isParent.number"
                                                    v-model="isParent.catatanDPJPDokter[k].auldd_ttv_luas_permukaan_anak" type="text"
                                                    class="form-control" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text">cm</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="mb-2">

                                        
                                            <div class="row">                                            
                                                <div class="form-group col-md-6">   
                                                    <span class="font-weight-semibold">Tingkat Kesadaran</span>
                                                    <b-form-input style="
                                                        width: 200px;
                                                    " type="text" :formatter="isParent.number" v-model="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran" class="form-control form-control-sm d-block" placeholder="Hasil Tingkat Kesadaran" />
                                                    <span>Keterangan : </span>

                                                    <span v-if="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                                    <span v-else-if="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran >= 12 && isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                                    <span v-else-if="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran >= 10 && isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                                    <span v-else-if="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran >= 7 && isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                                    <span v-else-if="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran >= 5 && isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                                    <span v-else-if="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran >= 4 && isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                                </div>
                                                <div class="form-group col-md-2">
                                                    <span class="font-weight-semibold">E</span>
                                                    <b-form-input type="text" :formatter="isParent.number" v-model="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran_e" class="form-control form-control-sm d-block" placeholder="E" />
                                                </div>   
                                                
                                                <div class="form-group col-md-2">
                                                    <span class="font-weight-semibold">M</span>
                                                    <b-form-input type="text" :formatter="isParent.number" v-model="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran_m" class="form-control form-control-sm d-block" placeholder="M" />
                                                </div>   
                                                
                                                <div class="form-group col-md-2">
                                                    <span class="font-weight-semibold">V</span>
                                                    <b-form-input type="text" :formatter="isParent.number" v-model="isParent.catatanDPJPDokter[k].auldd_hasil_kesadaran_v" class="form-control form-control-sm d-block" placeholder="V" />
                                                </div>   
                                            </div>

                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Tindakan Lab</span>
                                            <table class="table table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="33%">Hasil</th>
                                                    <th width="64"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(v3,k3) in isParent.catatanDPJPDokter[k].auldd_hasil_lab" :key="k3">
                                                    <td>
                                                        <b-form-input style="
                                                            width: 200px;
                                                        " type="text" v-model="v3['value']" class="form-control form-control-sm d-block" placeholder="Tindakan Lab" />
                                                    </td>
                                                    <td>
                                                        <a href="javascript:;" @click="isParent.catatanDPJPDokter[k].auldd_hasil_lab.splice(k3,1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="3" @click="isParent.catatanDPJPDokter[k].auldd_hasil_lab.push({value: null,file: null,nilai_kritis: null,dokter:null, jam_keluar:'',dilaporkan_oleh:null,dilaporkan_pada:null})" class="text-center"><a href="javascript:;" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a></td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Tindakan Radiologi</span>
                                            <table class="table table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="33%">Hasil</th>
                                                    <th width="64"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(v4,k4) in isParent.catatanDPJPDokter[k].auldd_hasil_radiologi" :key="k4">
                                                    <td>
                                                        <b-form-input style="
                                                            width: 200px;
                                                        " type="text" v-model="v4['value']" class="form-control form-control-sm d-block" placeholder="Tindakan Radiologi" />
                                                    </td>
                                                    <td>
                                                        <a href="javascript:;" @click="isParent.catatanDPJPDokter[k].auldd_hasil_radiologi.splice(k3,1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="3" @click="isParent.catatanDPJPDokter[k].auldd_hasil_radiologi.push({value: null,file: null,nilai_kritis: null,dokter:null, jam_keluar:'',dilaporkan_oleh:null,dilaporkan_pada:null})" class="text-center"><a href="javascript:;" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a></td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">R</span>
                                            <div class="mb-1">
                                                <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                                                <ol class="mb-1" style="margin-left: -35px;" v-if="(isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                                                <li v-for="(v5,k5) in (isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k5" class="mt-1">
                                                <div class="input-group">
                                                    <input v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_rekomendasi_intervensi[k5]" type="text" class="form-control form-control-sm">
                                                    <VValidate :name="`Tindakan Invasif `+(k+1)" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_rekomendasi_intervensi[k5]" :rules="{required:1}" />
                                                    <div class="input-group-append">
                                                    <a href="javascript:;"
                                                    @click="(isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_rekomendasi_intervensi||[]).splice(k5,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                                        <i class="icon-trash-alt"></i>
                                                    </a>
                                                    <a href="javascript:;" v-if="k == ((isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_rekomendasi_intervensi||[]).length - 1)" @click="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_rekomendasi_intervensi.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                                        <i class="icon-plus2"></i>
                                                    </a>
                                                    </div>
                                                </div>
                                                </li>
                                                </ol>
                                                <div class="" v-else>
                                                    <button type="button" class="btn btn-light mr-3" @click="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_rekomendasi_intervensi.push('')">Tambah Tindakan</button>
                                                </div>
                                            </div>
                                            <div class="mb-1">
                                                <span>Usulan kolaborasi</span>
                                                <ol class="mb-1" style="margin-left: -35px;" v-if="(isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_usulan_kolaborasi||[]).length">
                                                <li v-for="(v6,k6) in (isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_usulan_kolaborasi||[])" :key="k6" class="mt-1">
                                                <div class="input-group">
                                                    <input v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_usulan_kolaborasi[k6]" type="text" class="form-control form-control-sm">
                                                    <VValidate :name="`Usulan Kolaborasi `+(k+1)" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_usulan_kolaborasi[k6]" :rules="{required:1}" />
                                                    <div class="input-group-append">
                                                    <a href="javascript:;"
                                                    @click="(isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_usulan_kolaborasi||[]).splice(k6,1)" class="btn btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" title="Hapus">
                                                        <i class="icon-trash-alt"></i>
                                                    </a>
                                                    <a href="javascript:;" v-if="k == ((isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_usulan_kolaborasi||[]).length - 1)" @click="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_usulan_kolaborasi.push('')" class="btn btn-sm alpha-info border-info text-info-800" data-popup="tooltip" title="Tambah">
                                                        <i class="icon-plus2"></i>
                                                    </a>
                                                    </div>
                                                </div>
                                                </li>
                                                </ol>
                                                <div class="" v-else>
                                                    <button type="button" class="btn btn-light mr-3" @click="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_usulan_kolaborasi.push('')">Tambah Tindakan</button>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="text-right mt-2 pt-2 border-top">
                                        <a href="javascript:;" @click="copyKolab('N',isParent.catatanDPJPDokter[k])" data-toggle="modal" class="btn btn-sm alpha-info border-info text-info-600">
                                            <i class="icon-copy3 mr-1"></i>
                                            <span>Salin Data</span>
                                        </a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <label>Tanggal</label>
                                        <div class="input-group mb-3">
                                            <datepicker input-class="form-control transparent"
                                            placeholder="Pilih Tanggal" class="my-datepicker"
                                            calendar-class="my-datepicker_calendar" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_tanggal_evaluasi">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Jam</label>
                                        <div class="input-group bootstrap-timepicker timepicker">
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control custom-timepicker" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_waktu_evaluasi">
                                        </vue-timepicker>
                                        </div>
                                    </div>
                                </td>
                                
                                <td style="min-width: 300px;">
                                    <div class="form-group" 
                                    v-for="(vU,kU) in (isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                                        <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                                        <textarea @input="inputEval2Text($event,k,kU)" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data[kU]['value']" rows="3" class="form-control"></textarea>
                                        
                                        <div class="mb-2 mt-2 d-flex">
                                            <b-form-checkbox v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data[kU]['is_done']"
                                            @input="inputEval2(k,kU)"
                                            value="Y" unchecked-value="N">
                                            Evaluasi telah Dibaca Ulang
                                            </b-form-checkbox>
                                            
                                            <a href="javascript:;" @click="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data.splice(kU,1)" 
                                            
                                            v-b-tooltip.hover.right title="Hapus Evaluasi DPJP" class="text-danger float-right ml-auto"><i class="icon-bin"></i></a>
                                        </div>

                                            
                                        <span v-if="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">Selesai pada {{isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                                    </div>
                                        
                                    <div class="ml-1 mb-2"><button type="button" style="font-size: 12px;" 
                                    @click="addEvaluasi2(k)" class="btn btn-light mr-3">Tambah Evaluasi</button></div>

                                    <div id="verifikasiRanap">
                                        <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>
                                    
                                        <div class="form-group">
                                        <label for="">Nama DPJP <small class="text-danger">*</small></label>
                                        <v-select placeholder="Pilih Dokter" style="width: 160px;" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_nama"
                                        :options="isParent.mDokterAll" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                                        <VValidate :name="`Nama DPJP Verifikasi `+(k+2)" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_nama" :rules="{required:1}" />

                                        </div>

                                        <div class="form-group">
                                        <label>Tanggal <small class="text-danger">*</small></label>
                                        <div class="input-group">
                                            <datepicker input-class="form-control transparent"
                                            placeholder="Pilih Tanggal" @input="inputDataCADPJPPengkajian(isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_date,isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_waktu,k)" class="my-datepicker"
                                            calendar-class="my-datepicker_calendar" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_date">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                        
                                        <VValidate :name="`Tanggal Verifikasi `+(k+2)" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_date" :rules="{required:1}" />
                                        </div>

                                        <div class="form-group">
                                        <label>Jam <small class="text-danger">*</small></label>
                                        <div class="input-group bootstrap-timepicker timepicker">
                                            <vue-timepicker manual-input @input="inputDataCADPJPPengkajian(isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_date,isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_waktu,k)" format="HH:mm" input-class="form-control custom-timepicker" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_waktu">
                                            </vue-timepicker>
                                        </div>
                                        <VValidate :name="`Jam Verifikasi `+(k+2)" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_waktu" :rules="{required:1}" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <v-select placeholder="Pilih PPA" style="width: 160px;" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_ppa"
                                    :options="isParent.Config.mr.isPerawatOrDokter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                    <div class="form-group mt-2">
                                      <label for="">Nama PPA</label>
                                      <input type="text"
                                        class="form-control" v-model="isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_nama_ppa" />
                                    </div>
                                </td>
                                <td>
                                    <a href="javascript:;" class="list-icons-item"
                                    @click="removeLaporanDPJP(v,k)" v-b-tooltip.hover.right data-toggle="tooltip"
                                    data-placement="top" title="Hapus Laporan DPJP"><i class="icon-bin"></i></a>
                                </td>
                            </tr>
                            </div>                            
                            <tr>
                                <td colspan="6" @click="addLaporanDPJP()" class="text-center">
                                <a href="javascript:;" class="btn btn-outline-info"><i class="icon-plus2 mr-2 align-middle"></i><span class="align-middle">Tambah Laporan DPJP</span></a>
                                </td>
                            </tr>

                            
                            
                            </tbody>
                            <tbody>
                            <tr class="table-info">
                                <td colspan="6"><span class="font-weight-semibold text-info-600">Catatan Asuhan Lainnya</span></td>
                            </tr>

                            <tr v-for="(v,k) in (isParent.catatanAsuhanDokter||[])" :key="k">
                                <td>
                                <div class="form-group">
                                    <label>Tanggal</label>
                                    <div class="input-group mb-3">
                                        <datepicker input-class="form-control transparent"
                                        placeholder="Pilih Tanggal" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar" v-model="v.aucad_tanggal">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Jam</label>
                                    <div class="input-group bootstrap-timepicker timepicker">
                                    <vue-timepicker manual-input format="HH:mm" input-class="form-control custom-timepicker" v-model="v.aucad_jam">
                                    </vue-timepicker>
                                    </div>
                                </div>
                                </td>
                                <td>
                                <textarea v-model="v.aucad_assesmen" name="" id="" rows="2" class="form-control"></textarea>
                                </td>
                                <td>
                                <div class="form-group">
                                    <label>Tanggal</label>
                                    <div class="input-group">
                                        <datepicker @input="inputDataCA(v['aucad_tanggal_evaluasi'],v['aucad_jam_evaluasi'],k)" input-class="form-control transparent"
                                        placeholder="Pilih Tanggal" class="my-datepicker"
                                        calendar-class="my-datepicker_calendar" v-model="v.aucad_tanggal_evaluasi">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Jam</label>
                                    <div class="input-group bootstrap-timepicker timepicker">
                                    <vue-timepicker manual-input @input="inputDataCA(v['aucad_tanggal_evaluasi'],v['aucad_jam_evaluasi'],k)" format="HH:mm" input-class="form-control custom-timepicker" v-model="v.aucad_jam_evaluasi">
                                    </vue-timepicker>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <textarea v-model="v.aucad_evaluasi" name="" id="" rows="2" class="form-control"></textarea>
                                </td>
                                <td>
                                    <v-select placeholder="Pilih PPA" style="width: 160px;" v-model="v.aucad_ppa"
                                    :options="isParent.Config.mr.isPerawatOrDokter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                                    <div class="form-group mt-2">
                                      <label for="">Nama PPA</label>
                                      <input type="text"
                                        class="form-control" v-model="v.aucad_ppa_name" />
                                    </div>
                                </td>
                                <td>
                                    <div class="mb-2">
                                        <b-form-checkbox @input="changeCA($event,k)" v-model="v.aucad_is_active"
                                        value="Y" unchecked-value="N">
                                        Pengkajian Selesai Dilakukan
                                        </b-form-checkbox>
                                    </div>

                                    <span v-if="v.aucad_tanggal_evaluasi && v.aucad_jam_evaluasi && v.aucad_is_active == 'Y'">Selesai pada {{v.aucad_tanggal_evaluasi | moment("DD MMMM YYYY")}} {{v.aucad_jam_evaluasi}}</span><br/>
                
                                    <a href="javascript:;" @click="removeCatatanAsuhan(v,k)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                                        data-popup="tooltip" v-b-tooltip.hover.right title="Hapus Tindakan Dokter"><i class="icon-trash"></i></a>

                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colspan="8" class="text-center">
                                <a href="javascript:;" @click="addCatatanAsuhan" class="btn btn-outline-info"><i class="icon-plus2 mr-2 align-middle"></i><span class="align-middle">Tambah Catatan</span></a>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        </div>
                    </div>

                

                
                    </div>
                    </validation-observer>
                </div>
                </div>
            </div>
            </div>
        </div>

            
        <b-modal v-model="openLab" :title="'Pilih Item Pemeriksaan Laboratorium'" size="xl" hide-footer>
            <div class="modal-header d-block p-0 mt-3">
                <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
                <li v-for="(v,k) in isParent.row.aupdl_data" :key="k" @click="changeTabLab(k)" class="nav-item">
                    <a href="javascript:;" data-toggle="tab"
                    :class="k == activeTabLab ? 'nav-link active' : 'nav-link'">{{v.head||"-"}}</a>
                </li>
                </ul>
            </div>
            <div class="modal-body p-0 mt-3">
                <div class="tab-content">
                <div v-for="(v,k) in isParent.row.aupdl_data" :key="k+'labform'"
                    :class="k == activeTabLab ? 'tab-pane fade show active' : 'tab-pane fade'">
                    <template v-for="(v1,k1) in (v.dubData||[])">
                    <div :key="k1+'sss'">
                        <div v-if="v1.sub" class="selection-control font-weight-semibold bg-light text-uppercase">
                        {{v1.sub}}</div>
                        <div class="row sc-row g-0">
                        <template v-for="(v2,k2) in (v1.data||[])">
                            <div class="col-md-4 col-lg-3" v-if="v2.id" :key="k2+'xdx'">
                                <div class="selection-control">
                                <b-form-checkbox :name="'tabLab'+String(v2.id)" v-model="v2['selected']">{{v2.text||"-"}}
                                </b-form-checkbox>
                                <b-form-input type="text" v-if="v2['selected']" v-model="v2['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                                </div>
                            </div>
                        </template>
                        </div>
                    </div>
                    </template>
                </div>
                </div>
                <div class="row ml-1 mt-2">
                <div class="col-md-8 col-lg-4">
                    <div class="form-group row">
                    <label>Lainnya</label>
                    <b-form-input v-model="isParent.row.aupdl_hasil_lainnya" type="text" class="form-control" />
                    </div>
                </div>
                </div>
            </div>
            <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
                <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
                <span class="m-0" v-if="selectedLabInput">{{selectedLabInput||"-"}}</span>
                <span v-else> - </span>
            </div>
            <div class="modal-footer p-0 mt-3 bg-white">
                <div class="text-right">
                <button @click="openLab = false" data-dismiss="modal" class="btn">Selesai</button>
                </div>
            </div>
        </b-modal>


            
        <b-modal v-model="openRadiologi" :title="'Pilih Item Pemeriksaan Radiologi'" size="xl" hide-footer>
            <div class="modal-header d-block p-0 mt-3">
                <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
                <li v-for="(v,k) in isParent.row.aupdr_data" :key="k" @click="changeTabRadiologi(k)" class="nav-item">
                    <a href="javascript:;" data-toggle="tab"
                    :class="k == activeTabRadiologi ? 'nav-link active' : 'nav-link'">{{getConfigDynamic(isParent.Config.mr.KategoriRadiologi, v.head)||"-"}}</a>
                </li>
                </ul>
            </div>

            <div class="modal-body p-0 mt-3">
                <div class="tab-content">
                <div v-for="(v,k) in isParent.row.aupdr_data" :key="k+'radform'"
                    :class="k == activeTabRadiologi ? 'tab-pane fade show active' : 'tab-pane fade'">
                    <div class="row sc-row g-0">
                    <div class="col-md-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                        <div class="selection-control">
                        <b-form-checkbox @input="changeInpRadiologi($event,v1,k,k1)" :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                        </b-form-checkbox>
                        <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row ml-1 mt-2">
                <div class="col-md-4">
                    <div class="form-group row">
                    <label>Lainnya</label>
                    <b-form-input v-model="isParent.row.aupdr_hasil_lainnya" type="text" class="form-control" />
                    </div>
                </div>
                </div>
            </div>

            <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
                <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
                <span class="m-0" v-if="selectedRadioInput">{{selectedRadioInput}}</span>
                <span v-else> - </span>
            </div>
            <div class="modal-footer p-0 mt-3 bg-white">
                <div class="text-right">
                <button @click="openRadiologi = false" data-dismiss="modal" class="btn">Selesai</button>
                </div>
            </div>
        </b-modal>

            
        <b-modal v-model="openRacikan" :title="'Komposisi Racikan'" size="md" hide-footer>
            <div class="modal-body p-0 table-responsive">
                <p>{{rowRacikan.aurd_jumlah||"-"}} {{rowRacikan.aurd_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri
                dari:</p>
                <table class="table table-striped table-sm table-hover table-bordered">
                <thead>
                    <tr>
                    <th width="40">#</th>
                    <th>Nama Obat</th>
                    <th>Qty</th>
                    <th>Frekuensi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in rowRacikan.aurd_racikan" :key="k+'rowracik'">
                    <td>{{k+1}}</td>
                    <td>{{getObat(v.value)||"-"}}</td>
                    <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                    <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div class="modal-footer p-0 mt-3">

                <a href="javascript:;" v-if="isEditRacikan" @click="doOpenFormRacikan()" data-toggle="modal" data-target="#updateRacikanFarmasi"
                class="btn btn-link" data-dismiss="modal">
                <div class="icon-pencil7"></div>
                Ubah Komposisi Racikan
                </a>
                <button type="button" @click="openRacikan = false" class="btn btn-success btn-labeled btn-labeled-left"
                data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
            </div>
        </b-modal>

        <validation-observer ref="VFormRacikan">
        <b-form @submit.prevent="doSubmitRacikan">
            <b-modal v-model="openFormRacikan" :title="'Ubah Komposisi Racikan'" size="lg" hide-footer>
            <div class="modal-body p-0">
                <div class="alert alert-info">
                <span>Saat ini Anda sedang melakukan perubahan komposisi racikan
                    <strong>{{rowRacikan.mrd_nama_racikan||"-"}}</strong> milik
                    <strong>{{rowRacikan.bu_full_name||"-"}}</strong></span>
                </div>
                <div class="form-group">
                <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                        <th width="48">#</th>
                        <th width="33%">Obat</th>
                        <th width="33%">Dosis</th>
                        <th width="33%">Jumlah</th>
                        <th width="33%">Aksi</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v,k) in rowRacikan.aurd_racikan" :key="k">
                        <td>{{k + 1}}</td>
                        <td>
                        <v-select placeholder="Pilih Obat" @input="selectObat($event,k)"
                            v-model="rowRacikan.aurd_racikan[k]['value']" :options="getMasterObat(rowRacikan.aurd_racikan,k)"
                            label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                        <VValidate :name="'Obat #'+(k+1)" message="Obat Harus Diisi"
                            v-model="rowRacikan.aurd_racikan[k]['value']" :rules="{required : 1}" />
                        </td>
                        <td>
                        <div class="input-group">
                            <input v-model="rowRacikan.aurd_racikan[k]['dosis']" type="text" class="form-control"
                            placeholder="cth. 120">
                            <div class="input-group-append"><input style="width: 90px;"
                                v-model="rowRacikan.aurd_racikan[k]['satuan_dosis']" type="text" class="form-control"
                                placeholder="cth. Kaplet"></div>
                        </div>
                        </td>
                        <td>
                        <div class="input-group">
                            <b-form-input type="text" v-model="rowRacikan.aurd_racikan[k]['jumlah']"
                            :formatter="isParent.number" class="form-control" placeholder="cth. 5" />
                            <div class="input-group-append"><span
                                class="input-group-text">{{rowRacikan.aurd_racikan[k]['satuan_jumlah']||"-"}}</span></div>
                        </div>

                        <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                            v-model="rowRacikan.aurd_racikan[k]['jumlah']" :rules="{required: 1}" />
                        </td>
                        <td>
                        <a href="javascript:;" @click="rowRacikan.aurd_racikan.splice(k,1)"
                            class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                            data-popup="tooltip" title="Hapus Obat"><i class="icon-trash"></i></a>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="9" class="text-center">
                        <a href="javascript:;" @click="addObat" class="btn btn-outline-info">
                            <i class="icon-plus2 align-middle mr-1"></i>
                            <span class="align-middle">Tambah Obat</span>
                        </a>
                        </td>
                    </tr>
                    </tfoot>
                </table>
                </div>
                <div class="w-100"></div>
                <div class="col-md-4">
                <div>
                    <label for="">Jumlah & Satuan<strong class="text-danger">*</strong></label>
                    <div class="input-group">
                    <b-form-input type="text" :formatter="isParent.number" v-model="rowRacikan.aurd_jumlah"
                        class="form-control" id="cth. Racikan Amlodipin" placeholder="Jumlah Default Racikan" />
                    <div class="input-group-append">
                        <v-select placeholder="Pilih Satuan" style="width: 160px;" v-model="rowRacikan.aurd_satuan"
                        :options="isParent.mTypeSatuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    </div>
                    <VValidate name="Jumlah" v-model="rowRacikan.aurd_jumlah" :rules="{required : 1}" />
                    <VValidate name="Satuan" v-model="rowRacikan.aurd_satuan" :rules="{required : 1}" />
                </div>
                </div>
            </div>
            <div class="modal-footer p-0 mt-3">
                <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
                class="btn btn-link" data-dismiss="modal">
                Batal
                </a>
                <button type="submit" @click="doSubmitRacikan" class="btn btn-success btn-labeled btn-labeled-left"
                data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
            </div>
            </b-modal>
        </b-form>
        </validation-observer>

        <validation-observer ref="VFormInformconsent">
            <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
            <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
            <table class="table table-bordered table-sm table-input">
                <thead>
                <tr>
                    <td colspan="3">
                    <div class="result_tab">
                        <h4>Nama Tindakan</h4>
                        <p>{{rowIC.tindakan}}</p>
                    </div>
                    </td>
                </tr>
                <tr>
                    <th width="24">No.</th>
                    <th>Jenis Informasi</th>
                    <th>Isi Informasi</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Dasar Diagnosis</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Tindakan Kedokteran</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Indikasi Tindakan</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Tata Cara</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Tujuan</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>Risiko</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>Komplikasi</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>Prognosis</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>Alternatif & Risiko</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                <tr>
                    <td>11</td>
                    <td>Lain-lain</td>
                    <td class="input-cell">
                    <textarea v-model="rowIC.auic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                    <tr>
                    <td width="50%" colspan="2">
                        <div>
                        <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                        <div>
                            <b-form-radio-group
                                :options="Config.mr.pasienSetuju"
                                v-model="rowIC.auic_is_pasien_setuju"
                            />
                            <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.auic_is_pasien_setuju" :rules="{required:1}" />
                        </div>
                        </div>
                    </td>
                    <td width="50%">
                        <div>
                        <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                        <div>
                            <b-form-radio-group
                                :options="Config.mr.persetujuanTindakan"
                                v-model="rowIC.auic_persetujuan_tindakan"
                            />
                            <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.auic_persetujuan_tindakan" :rules="{required:1}" />
                        </div>
                        </div>
                    </td>
                    </tr>
                </tfoot>
            </table>
            </b-modal>
        </validation-observer>
            
        <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
            <table class="table table-bordered table-sm table-input">
            <thead>
                <tr>
                <td colspan="3">
                    <div class="result_tab">
                        <h4>Nama Tindakan</h4>
                        <p>{{rowIC.tindakan}}</p>
                    </div>
                </td>
                </tr>
                <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                <td>
                {{rowIC.auic_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td>
                {{rowIC.auic_dasar_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td>
                {{rowIC.auic_tindakan_dokter||"-"}}
                </td>
                </tr>
                <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td>
                {{rowIC.auic_indikasi_tindakan||"-"}}
                </td>
                </tr>
                <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td>
                {{rowIC.auic_tata_cara||"-"}}
                </td>
                </tr>
                <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td>
                {{rowIC.auic_tujuan||"-"}}
                </td>
                </tr>
                <tr>
                <td>7</td>
                <td>Risiko</td>
                <td>
                {{rowIC.auic_risiko||"-"}}
                </td>
                </tr>
                <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td>
                {{rowIC.auic_kompilasi||"-"}}
                </td>
                </tr>
                <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td>
                {{rowIC.auic_prognosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td>
                {{rowIC.auic_alternatif||"-"}}
                </td>
                </tr>
                <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td>
                {{rowIC.auic_lainnya||"-"}}
                </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div class="result_tab">
                    <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                    <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                    <p v-else>Tindakan ditolak</p>
                    </div>
                </td>
                <td width="50%">
                    <div class="result_tab">
                    <h4>Persetujuan tindakan diberikan kepada</h4>
                    <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
                    <p v-else>Penanggung Jawab</p>
                    </div>
                </td>
                </tr>
            </tfoot>
            </table>
        </b-modal>
        
        <b-modal v-model="openFaskes" :title="'Tambah Faskes Tujuan'" size="sm" hide-footer>
        <div class="modal-body p-0">
            <div class="mb-3">
            <div class="row">
                
                <div class="col-md-12">
                    <label for="perGravid">Cari Nama Faskes<strong
                    class="text-danger">*</strong></label>
                    <div class="input-group">
                    <b-form-input v-model="faskesSearch"
                        type="text" class="form-control" />
                        <div class="input-group-append">
                        <button @click="searchFaskesData" type="button"
                            class="btn btn-info btn-labeled btn-labeled-left">
                            <b><i class="icon-search4"></i></b>
                            Cari
                        </button>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" v-for="(v,k) in (listFaskesBPJS||[])" :key="k+'radiox'">
                <div class="selection-control">
                    <b-form-checkbox v-model="v['selected']">{{v.kode}} - {{v.nama}}
                    </b-form-checkbox>
                </div>
                </div>

            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3 bg-white" v-if="(listFaskesBPJS||[]).length">
            <div class="text-right">
            <button @click="addFaskes" data-dismiss="modal" class="btn">Selesai</button>
            </div>
        </div>
        </b-modal>

        <b-modal v-model="isOpenCopy" scrollable :title="'Preview Salinan Data'" size="md" @ok.prevent="copyToData" ok-title="Salin Data" cancel-title="Tutup">
            <div class="mt-3" ref="message" v-if="typeCopy == 'P'">
                <div class="data-copy-preview">
                  <p><strong>Situation (S)</strong></p>
                  <p>Pasien datang ke UGD dengan keluhan utama {{copyData.ausd_keluhan||"-"}} <span v-if="copyData.ausd_riwayat">, Pasien mempunyai riwayat penyakit dahulu: {{copyData.ausd_riwayat||"-"}} </span></p>

                  <br>
                  <p><strong>Background (B)</strong></p>
                  <p>
                    <strong>Riwayat Alergi:</strong><br/>
                    <ul class="mb-0" v-if="copyData.ausd_has_alergi == 'Y'">
                        <li v-for="(v,k) in (copyData.ausd_alergi||[])" :key="k"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v.jenis)}})</span>{{v.name||"-"}}</li>

                        <span v-if="!(copyData.ausd_alergi||[]).length"> - </span>
                    </ul>
                    <span v-else> - </span>
                  </p>
                  <p>
                    <strong>Keluhan Saat Ini</strong><br/>
                    <span>{{copyData.ausd_catatan_dokter||"-"}}</span>
                  </p>
                  <p>
                    <strong>Pengobatan yang dilakukan di UGD</strong><br/>
                    <span>{{copyData.aupd_rencana_pengobatan||"-"}}</span>
                  </p>
                  <p>
                    <strong>Tindakan Invasif</strong>
                    <ol class="mb-0" style="margin-left: -35px;" v-if="(copyData.aupd_laporan_dpjp_tindakan_invasif||[]).length">
                        <li v-for="(v,k) in (copyData.aupd_laporan_dpjp_tindakan_invasif||[])" :key="k" class="mt-1 ml-3">
                            {{v}}
                        </li>
                    </ol>
                  </p>
                  <br>
                  <p><strong>Assessment (A)</strong></p>
                  <p>
                    <strong>Keluhan saat ini</strong><br>
                    {{copyData.aupd_laporan_dpjp_keluhan||"-"}}
                  </p>
                  <p>
                    <strong>Hasil Skor Nyeri</strong><br>
                    {{copyData.auod_skala_nyeri_value}}

                    <template v-if="(copyData.ap_usia > 6 || (copyData.ap_usia == null && copyData.ap_dob == null && copyData.auod_nyeri_type == 'NP'))">
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="copyData.auod_skala_nyeri_value == 0">Tidak Ada Nyeri</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auod_skala_nyeri_value >= 1 && copyData.auod_skala_nyeri_value <= 3">Sedikit Nyeri</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auod_skala_nyeri_value >= 4 && copyData.auod_skala_nyeri_value <= 5">Agak Mengganggu</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auod_skala_nyeri_value >= 6 && copyData.auod_skala_nyeri_value <= 7">Mengganggu Aktivitas</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auod_skala_nyeri_value >= 8 && copyData.auod_skala_nyeri_value <= 9">Sangat Mengganggu</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auod_skala_nyeri_value >= 10">Tak Tertahankan</span>
                    </template>
                    
                    <template v-if="(copyData.ap_usia <= 6) || (copyData.ap_usia == null && copyData.ap_dob == null)">
                        <template v-if="(copyData.ap_usia > 1 || (copyData.ap_usia == null && copyData.ap_dob == null && copyData.auod_nyeri_type == 'FL'))">
                            <span v-if="copyData.auod_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                            <span v-else-if="copyData.auod_skala_nyeri_value >= 1 && copyData.auod_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                            <span v-else-if="copyData.auod_skala_nyeri_value >= 4 && copyData.auod_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                        </template>

                        <template v-if="((copyData.ap_usia <= 1  && copyData.ap_usia != null)|| (copyData.ap_usia == null && copyData.ap_dob == null && copyData.auod_nyeri_type == 'NIPS')) ">
                            <span v-if="copyData.auod_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                            <span v-else-if="copyData.auod_skala_nyeri_value >= 3 && copyData.auod_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                        </template>
                    </template>
                  </p>
                  <p>
                    <strong>Tanda-tanda Vital</strong>
                    <ul>
                        <li>Tekanan Darah: 
                        <span v-if="copyData.auod_ttv_tekanan_darah_min && copyData.auod_ttv_tekanan_darah_max">
                        {{copyData.auod_ttv_tekanan_darah_min||"-"}}/
                        {{copyData.auod_ttv_tekanan_darah_max||"-"}} mmHG
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Nadi: 
                        <span v-if="copyData.auod_ttv_nadi && copyData.auod_ttv_label">
                        {{copyData.auod_ttv_nadi||"-"}} x/mnt - {{copyData.auod_ttv_label||"-"}}
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Gula Darah: 
                        <span v-if="copyData.auod_ttv_gula_darah">
                        {{copyData.auod_ttv_gula_darah||"-"}} mg/dL
                        </span>
                        <span v-else> - </span>
                        </li>
                        <li>Pernafasan: 
                        <span v-if="copyData.auod_ttv_pernafasan">
                        {{copyData.auod_ttv_pernafasan||"-"}} x/mnt
                        </span>
                        <span v-else> - </span>
                        </li>
                        <li>SPO2: 
                        <span v-if="copyData.auod_ttv_spo2">
                        {{copyData.auod_ttv_spo2||"-"}}%
                        </span>
                        <span v-else> - </span>
                        </li>
                        
                        <li>Suhu: 
                        <span v-if="copyData.auod_ttv_suhu">
                        {{copyData.auod_ttv_suhu||"-"}}C
                        </span>
                        <span v-else> - </span>
                        </li>
                        
                        <li>Berat Badan 
                        <span v-if="copyData.auod_ttv_weight">
                        {{copyData.auod_ttv_weight||"-"}} kg
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Tinggi: 
                        <span v-if="copyData.auod_ttv_height">
                        {{copyData.auod_ttv_height||"-"}} cm
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Lingkar Kepala: 
                        <span v-if="copyData.auod_ttv_lingkar_kepala">
                        {{copyData.auod_ttv_lingkar_kepala||"-"}} cm
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Luas Permukaan Tubuh Anak: 
                        <span v-if="copyData.auod_ttv_luas_permukaan_anak">
                        {{copyData.auod_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup>
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Kesadaran: 
                        <span>{{copyData.auod_gcs_total}} |     
                            <span v-if="copyData.auod_gcs_total >= 14"> Composmentis</span> 
                            <span v-else-if="copyData.auod_gcs_total >= 12 && copyData.auod_gcs_total <= 13">Apatis</span>
                            <span v-else-if="copyData.auod_gcs_total >= 10 && copyData.auod_gcs_total <= 11">Delirium</span>
                            <span v-else-if="copyData.auod_gcs_total >= 7 && copyData.auod_gcs_total <= 9">Somonolen</span>
                            <span v-else-if="copyData.auod_gcs_total >= 5 && copyData.auod_gcs_total <= 6">Sopor</span>
                            <span v-else-if="copyData.auod_gcs_total >= 4 && copyData.auod_gcs_total <= 4">Semi-Comma</span>
                            <span v-else>Coma</span>
                            
                            <span class="ml-2" style="margin-right:20px;">E: 
                                {{copyData.auod_response_mata||0}} 
                            </span>
                            <span style="margin-right:20px;">M: 
                                {{copyData.auod_response_motorik||0}}  
                            </span>
                            <span style="margin-right:20px;">V: 
                                {{copyData.auod_response_verbal||0}}
                            </span>
                        </span>
                        </li>
                    </ul>
                  </p>

                  
                    <div class="mb-2">
                    <strong>Pemeriksaan Fisik</strong><br>

                    <div class="mb-0">
                        <template v-for="(v,k) in (Config.mr.configVitalSignUGD||[])">
                            <div :key="k" v-if="v.value != 'auod_vs_saraf'">
                                <p class="mb-0" v-if="isParent.row[v.value] != 'TP'"> 
                                    - {{v.label}} : <span>{{isParent.row[v.value+'_text']}}</span>
                                </p>
                            </div>
                            <div :key="k" v-else>
                                <template v-if="isParent.row[v.value] != 'TP'">
                                    <p class="mb-0">
                                        <span> - {{v.label}} :</span>
                                    </p>
                                    <p class="mb-0"> - Refleks Fisiologis : {{isParent.row.auod_vs_saraf_r_fisiologis||"-"}}</p>
                                    <p class="mb-0"> - Refleks Patologis : {{isParent.row.auod_vs_saraf_r_patologis||"-"}}</p>
                                    <p class="mb-0"> - Babinski : {{isParent.row.auod_vs_saraf_babinski||"-"}}</p>
                                    <p class="mb-0"> - Lasegue : {{isParent.row.auod_vs_saraf_lasegue||"-"}}</p>
                                    <p class="mb-0"> - Bregard : {{isParent.row.auod_vs_saraf_bregard||"-"}}</p>
                                    <p class="mb-0"> - Sicard : {{isParent.row.auod_vs_saraf_sicard||"-"}}</p>
                                    <p class="mb-0"> - Kaku Duduk : {{isParent.row.auod_vs_saraf_kaku_duduk||"-"}}</p>
                                    <p class="mb-0"> - Kernig Sign : {{isParent.row.auod_vs_saraf_kernig_sign||"-"}}</p>
                                    <p class="mb-0"> - Brudzinski 1: {{isParent.row.auod_vs_saraf_brudzinski||"-"}}</p>
                                    <p class="mb-0"> - Brudzinski 2: {{isParent.row.auod_vs_saraf_brudzinski2||"-"}}</p>
                                </template>
                            </div>
                        </template>

                    </div>
                    <span v-if="!isHavePemeriksaanFisik">Hasil pemeriksaan fisik normal</span>
                    </div>

                  <div>
                    <strong>Tindakan Lab</strong>
                    <p>{{selectedLabInput||"-"}}</p>                    
                  </div>
                  <div>
                    <strong>Tindakan Radiologi</strong>
                    <p>{{selectedRadioInput||"-"}}</p>                    
                  </div>
                  <br>
                  <p><strong>Recommendation (R)</strong></p>
                  <div>
                    <strong>Rekomendasi intervensi yang perlu dilakukan</strong>
                    <ol class="mb-0" v-if="(copyData.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                        <li v-for="(v,k) in (copyData.aupd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">{{v}}</li>
                    </ol>
                    <p v-if="!(copyData.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length"> - </p>
                  </div>
                  <div>
                    <strong>Usulan kolaborasi</strong>
                    <ol class="mb-0" v-if="(copyData.aupd_laporan_dpjp_usulan_kolaborasi||[]).length">
                        <li v-for="(v,k) in (copyData.aupd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">{{v}}</li>
                    </ol>
                    <p v-if="!(copyData.aupd_laporan_dpjp_usulan_kolaborasi||[]).length"> - </p>
                  </div>
                </div>
            </div>
            <div class="mt-3" ref="message" v-else>
                <div class="data-copy-preview">
                  <p><strong>Situation (S)</strong></p>
                  <p>Pasien datang ke UGD dengan keluhan utama {{copyData.auldd_subjektif||"-"}} <span v-if="copyData.auldd_riwayat_penyakit">, Pasien mempunyai riwayat penyakit dahulu: {{copyData.auldd_riwayat_penyakit||"-"}} </span></p>

                  <br>
                  <p><strong>Background (B)</strong></p>
                  <p>
                    <strong>Riwayat Alergi:</strong><br/>
                    <ul class="mb-0">
                        <li v-for="(v,k) in (copyData.auldd_riwayat_alergi||[])" :key="k"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v.jenis)}})</span>{{v.name||"-"}}</li>

                        <span v-if="!(copyData.auldd_riwayat_alergi||[]).length"> - </span>
                    </ul>
                  </p>
                  <p>
                    <strong>Keluhan Saat Ini</strong><br/>
                    <span>{{copyData.auldd_subjektif||"-"}}</span>
                  </p>
                  <p>
                    <strong>Pengobatan yang dilakukan di UGD</strong><br/>
                    <span>{{copyData.auldd_catatan_dokter||"-"}}</span>
                  </p>
                  <p>
                    <strong>Tindakan Invasif</strong>
                    <ol class="mb-0" style="margin-left: -35px;" v-if="(copyData.auldd_laporan_dpjp_tindakan_invasif||[]).length">
                        <li v-for="(v,k) in (copyData.auldd_laporan_dpjp_tindakan_invasif||[])" :key="k" class="mt-1 ml-3">
                            {{v}}
                        </li>
                    </ol>
                  </p>
                  <br>
                  <p><strong>Assessment (A)</strong></p>
                  <p>
                    <strong>Keluhan saat ini</strong><br>
                    {{copyData.auldd_laporan_dpjp_keluhan||"-"}}
                  </p>
                  <p>
                    <strong>Hasil Skor Nyeri</strong><br>
                    {{copyData.auldd_hasil_skoring_nyeri}}
                    
                    <template v-if="(copyData.ap_usia > 6 || (copyData.ap_usia == null && copyData.ap_dob == null && copyData.auod_nyeri_type == 'NP'))">
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="copyData.auldd_hasil_skoring_nyeri == 0">Tidak Ada Nyeri</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auldd_hasil_skoring_nyeri >= 1 && copyData.auldd_hasil_skoring_nyeri <= 3">Sedikit Nyeri</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auldd_hasil_skoring_nyeri >= 4 && copyData.auldd_hasil_skoring_nyeri <= 5">Agak Mengganggu</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auldd_hasil_skoring_nyeri >= 6 && copyData.auldd_hasil_skoring_nyeri <= 7">Mengganggu Aktivitas</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auldd_hasil_skoring_nyeri >= 8 && copyData.auldd_hasil_skoring_nyeri <= 9">Sangat Mengganggu</span>
                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="copyData.auldd_hasil_skoring_nyeri >= 10">Tak Tertahankan</span>
                    </template>
                    
                    <template v-if="(copyData.ap_usia <= 6) || (copyData.ap_usia == null && copyData.ap_dob == null)">
                        <template v-if="(copyData.ap_usia > 1 || (copyData.ap_usia == null && copyData.ap_dob == null && copyData.auod_nyeri_type == 'FL'))">
                            <span v-if="copyData.auldd_hasil_skoring_nyeri == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                            <span v-else-if="copyData.auldd_hasil_skoring_nyeri >= 1 && copyData.auldd_hasil_skoring_nyeri < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                            <span v-else-if="copyData.auldd_hasil_skoring_nyeri >= 4 && copyData.auldd_hasil_skoring_nyeri < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                        </template>

                        <template v-if="((copyData.ap_usia <= 1  && copyData.ap_usia != null)|| (copyData.ap_usia == null && copyData.ap_dob == null && copyData.auod_nyeri_type == 'NIPS')) ">
                            <span v-if="copyData.auldd_hasil_skoring_nyeri < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                            <span v-else-if="copyData.auldd_hasil_skoring_nyeri >= 3 && copyData.auldd_hasil_skoring_nyeri < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                        </template>
                    </template>
                  </p>
                  <p>
                    <strong>Tanda-tanda Vital</strong>
                    <ul>
                      <li>Tekanan Darah: 
                        <span v-if="copyData.auldd_ttv_tekanan_darah_min && copyData.auldd_ttv_tekanan_darah_max">
                        {{copyData.auldd_ttv_tekanan_darah_min||"-"}}/
                        {{copyData.auldd_ttv_tekanan_darah_max||"-"}} mmHG
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Nadi: 
                        <span v-if="copyData.auldd_ttv_nadi && copyData.auldd_ttv_label">
                        {{copyData.auldd_ttv_nadi||0}} x/mnt - {{copyData.auldd_ttv_label||"-"}}
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Gula Darah: 
                        <span v-if="copyData.auldd_ttv_gula_darah">
                        {{copyData.auldd_ttv_gula_darah||0}} mg/dL
                        </span>
                        <span v-else> - </span>
                        </li>
                        <li>Pernafasan: 
                        <span v-if="copyData.auldd_ttv_pernafasan">
                        {{copyData.auldd_ttv_pernafasan||0}} x/mnt
                        </span>
                        <span v-else> - </span>
                        </li>
                        <li>SPO2: 
                        <span v-if="copyData.auldd_ttv_spo2">
                        {{copyData.auldd_ttv_spo2||0}}%
                        </span>
                        <span v-else> - </span>
                        </li>
                        
                        <li>Suhu: 
                        <span v-if="copyData.auldd_ttv_suhu">
                        {{copyData.auldd_ttv_suhu||0}}C
                        </span>
                        <span v-else> - </span>
                        </li>
                        
                        <li>Berat Badan 
                        <span v-if="copyData.auldd_ttv_weight">
                        {{copyData.auldd_ttv_weight||0}} kg
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Tinggi: 
                        <span v-if="copyData.auldd_ttv_height">
                        {{copyData.auldd_ttv_height||0}} cm
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Lingkar Kepala: 
                        <span v-if="copyData.auldd_ttv_lingkar_kepala">
                        {{copyData.auldd_ttv_lingkar_kepala||0}} cm
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Luas Permukaan Tubuh Anak: 
                        <span v-if="copyData.auldd_ttv_luas_permukaan_anak">
                        {{copyData.auldd_ttv_luas_permukaan_anak||0}} M<sup>2</sup>
                        </span>
                        <span v-else> - </span>
                        </li>

                        <li>Kesadaran: 
                            <span>{{copyData.auldd_hasil_kesadaran}} |     
                                <span v-if="copyData.auldd_hasil_kesadaran >= 14"> Composmentis</span> 
                                <span v-else-if="copyData.auldd_hasil_kesadaran >= 12 && copyData.auldd_hasil_kesadaran <= 13">Apatis</span>
                                <span v-else-if="copyData.auldd_hasil_kesadaran >= 10 && copyData.auldd_hasil_kesadaran <= 11">Delirium</span>
                                <span v-else-if="copyData.auldd_hasil_kesadaran >= 7 && copyData.auldd_hasil_kesadaran <= 9">Somonolen</span>
                                <span v-else-if="copyData.auldd_hasil_kesadaran >= 5 && copyData.auldd_hasil_kesadaran <= 6">Sopor</span>
                                <span v-else-if="copyData.auldd_hasil_kesadaran >= 4 && copyData.auldd_hasil_kesadaran <= 4">Semi-Comma</span>
                                <span v-else>Coma</span>
                            </span>
                            
                            <span class="ml-2" style="margin-right:20px;">E: 
                                {{copyData.auldd_hasil_kesadaran_e||0}} 
                            </span>
                            <span style="margin-right:20px;">M: 
                                {{copyData.auldd_hasil_kesadaran_m||0}}  
                            </span>
                            <span style="margin-right:20px;">V: 
                                {{copyData.auldd_hasil_kesadaran_v||0}}
                            </span>
                        </li>
                    </ul>
                  </p>
                  <div>
                    <strong>Tindakan Lab</strong>
                    <ol class="mb-0" v-if="(copyData.auldd_hasil_lab||[]).length">
                        <li v-for="(v,k) in (copyData.auldd_hasil_lab||[])" :key="k">{{v.value}}</li>
                    </ol>
                    <p v-if="!(copyData.auldd_hasil_lab||[]).length"> - </p>
                  </div>
                  <div>
                    <strong>Tindakan Radiologi</strong>
                    <ol class="mb-0" v-if="(copyData.auldd_hasil_radiologi||[]).length">
                        <li v-for="(v,k) in (copyData.auldd_hasil_radiologi||[])" :key="k">{{v.value}}</li>
                    </ol>
                    <p v-if="!(copyData.auldd_hasil_radiologi||[]).length"> - </p>
                  </div>
                  <br>
                  <p><strong>Recommendation (R)</strong></p>
                  <div>
                    <strong>Rekomendasi intervensi yang perlu dilakukan</strong>
                    <ol class="mb-0" v-if="(copyData.auldd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                        <li v-for="(v,k) in (copyData.auldd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">{{v}}</li>
                    </ol>
                    <p v-if="!(copyData.auldd_laporan_dpjp_rekomendasi_intervensi||[]).length"> - </p>
                  </div>
                  <div>
                    <strong>Usulan kolaborasi</strong>
                    <ol class="mb-0" v-if="(copyData.auldd_laporan_dpjp_usulan_kolaborasi||[]).length">
                        <li v-for="(v,k) in (copyData.auldd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">{{v}}</li>
                    </ol>
                    <p v-if="!(copyData.auldd_laporan_dpjp_usulan_kolaborasi||[]).length"> - </p>
                  </div>
                </div>
            </div>
            
            <textarea :value="contentData" v-on:focus="$event.target.select()" ref="myinput" 
            style="
                opacity: 0;
                overflow: hidden;
            ">
            </textarea>

            <div v-if="copyMessage" class="alert alert-success mt-2 mb-2 py-2">
                <i class="icon-checkmark mr-2"></i>
                <span>Data berhasil disalin</span>
            </div>
        </b-modal>        

        <b-modal v-model="openPaketAlkes" :title="'Komposisi Paket Alkes'" size="md" hide-footer>
            <div class="modal-body p-0 table-responsive">
                <p>Paket Alkes : {{rowPaketAlkes.mra_nama_racikan||"-"}} terdiri
                dari:</p>
                <table class="table table-striped table-sm table-hover table-bordered">
                <thead>
                    <tr>
                    <th width="40">#</th>
                    <th>Nama Alkes</th>
                    <th>Qty</th>
                    <th>Frekuensi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in rowPaketAlkes.aurd_paket_alkes" :key="k+'rowracik'">
                    <td>{{k+1}}</td>
                    <td>
                        <span>{{ getConfigDynamic(isParent.mAlatKesehatan,v.value)||"-" }}</span>
                    </td>
                    <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                    <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div class="modal-footer p-0 mt-3">

                <a href="javascript:;" v-if="isEditAlkes" @click="doOpenFormAlkes()" data-toggle="modal" data-target="#updateRacikanFarmasi"
                class="btn btn-link" data-dismiss="modal">
                <div class="icon-pencil7"></div>
                Ubah Komposisi Paket Alkes
                </a>
                <button type="button" @click="openPaketAlkes = false" class="btn btn-success btn-labeled btn-labeled-left"
                data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
            </div>
        </b-modal>

        <validation-observer ref="VFormPaketAlkes">
            <b-form @submit.prevent="doSubmitPaketAlkes">
                <b-modal v-model="openFormPaketAlkes" :title="'Ubah Komposisi Alat Kesehatan'" size="lg" hide-footer>
                <div class="modal-body p-0">
                    <div class="alert alert-info">
                    <span>Saat ini Anda sedang melakukan perubahan komposisi alat kesehatan
                        <strong>{{rowPaketAlkes.mra_nama_racikan||"-"}}</strong> milik
                        <strong>{{rowPaketAlkes.bu_full_name||"-"}}</strong></span>
                    </div>
                    <div class="form-group">
                    <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
                    <table class="table table-bordered table-striped table-sm">
                        <thead>
                        <tr>
                            <th width="48">#</th>
                            <th width="33%">Nama Alkes</th>
                            <th width="33%">Dosis</th>
                            <th width="33%">Jumlah</th>
                            <th width="33%">Aksi</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in rowPaketAlkes.aurd_paket_alkes" :key="k">
                            <td>{{k + 1}}</td>
                            <td>
                            <v-select placeholder="Pilih Alkes" @input="selectAlkes($event,k)"
                                v-model="rowPaketAlkes.aurd_paket_alkes[k]['value']" :options="isParent.mAlatKesehatan"
                                label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                            <VValidate :name="'Paket Alkes #'+(k+1)" message="Paket Alkes Harus Diisi"
                                v-model="rowPaketAlkes.aurd_paket_alkes[k]['value']" :rules="{required : 1}" />
                            </td>
                            <td>
                            <div class="input-group">
                                <input v-model="rowPaketAlkes.aurd_paket_alkes[k]['dosis']" type="text" class="form-control"
                                placeholder="cth. 120">
                                <div class="input-group-append"><input style="width: 90px;"
                                    v-model="rowPaketAlkes.aurd_paket_alkes[k]['satuan_dosis']" type="text" class="form-control"
                                    placeholder="cth. Kaplet"></div>
                            </div>
                            </td>
                            <td>
                            <div class="input-group">
                                <b-form-input type="text" v-model="rowPaketAlkes.aurd_paket_alkes[k]['jumlah']"
                                :formatter="$parent.number" class="form-control" placeholder="cth. 5" />
                                <div class="input-group-append"><span
                                    class="input-group-text">{{rowPaketAlkes.aurd_paket_alkes[k]['satuan_jumlah']||"-"}}</span></div>
                            </div>

                            <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                                v-model="rowPaketAlkes.aurd_paket_alkes[k]['jumlah']" :rules="{required: 1}" />
                            </td>
                            <td>
                            <a href="javascript:;" @click="rowPaketAlkes.aurd_paket_alkes.splice(k,1)"
                                class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                                data-popup="tooltip" title="Hapus Alkes"><i class="icon-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="9" class="text-center">
                            <a href="javascript:;" @click="addAlkes" class="btn btn-outline-info">
                                <i class="icon-plus2 align-middle mr-1"></i>
                                <span class="align-middle">Tambah Alkes</span>
                            </a>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    </div>
                    <div class="w-100"></div>
                </div>
                <div class="modal-footer p-0 mt-3">
                    <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
                    class="btn btn-link" data-dismiss="modal">
                    Batal
                    </a>
                    <button type="submit" @click="doSubmitAlkes" class="btn btn-success btn-labeled btn-labeled-left"
                    data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
                </div>
                </b-modal>
            </b-form>
        </validation-observer>


        <!-- RESEP -->    
        <validation-observer ref="VFormTemplate">
            <b-modal v-model="openTemplate" @ok.prevent="submitTemplate" :title="'Template Resep'" size="md" ok-title="Pilih Template">
            <div class="row">
                <div class="col-md-12">
                <template v-if="templateResep">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table">
                    <thead>
                    <tr>
                        <th width="20"></th>
                        <th>Nama</th>
                        <th>Rincian</th>
                        <th width="80">Aksi</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v,k) in (templateResep||[])" :key="k">
                        <td>
                        <b-form-checkbox v-model="v.checked" :value="true" :unchecked-value="null"></b-form-checkbox>
                        </td>
                        <td>{{v.mtr_name}}</td>
                        <td>
                        <span class="d-block font-weight-bold" v-if="v.mtr_total_obat">Total Obat : {{v.mtr_total_obat}}</span>
                        <span class="d-block font-weight-bold" v-if="v.mtr_total_alkes">Total Alkes : {{v.mtr_total_alkes}}</span>
                        <span class="d-block font-weight-bold" v-if="v.mtr_total_infus">Total Infus : {{v.mtr_total_infus}}</span>
                        </td>
                        <td>
                            <a @click="openDetail(k,v.data)"
                                                href="javascript:;"
                                                title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-center ml-1" v-b-tooltip.hover
                                                ><i class="icon-eye"></i></a>
                        </td>
                    </tr>
                    <tr v-if="!(templateResep||[]).length">
                        <td colspan="4" class="text-center">Tidak ada data</td>
                    </tr>
                    </tbody>
                    </table>
                </template>
                <span class="mt-1 mb-1 text-center" v-else>Loading ... </span>
                </div>
            </div>
            </b-modal>
        </validation-observer>

        <b-modal v-model="openDetailIsi" :title="'Detail Resep'" size="lg" ok-title="Pilih Template" @ok.prevent="selectOneTemplate()">
            <div class="row">
                <div class="col-md-12">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                        <th>Jenis</th>
                        <th>Nama</th>
                        <th>Jumlah</th>
                        <th>Frekuensi</th>
                        <th>Keterangan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (dataDetail||[])" :key="k">
                        <td>{{getConfigDynamic(Config.mr.configResep,v.aurd_jenis)||"-"}}</td>
                        <td>{{v.aurd_nama||"-"}}</td>
                        <td>{{v.aurd_jumlah||"-"}} {{v.aurd_satuan}}</td>
                        <td>{{v.mdo_name||v.aurd_frekuensi_lainnya||"-"}}</td>
                        <td>{{v.aurd_keterangan||"-"}}</td>
                        </tr>
                        <tr v-if="!(dataDetail||[]).length">
                        <td colspan="5" class="text-center">Tidak ada data</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </b-modal>

        <!-- PENUNJANG -->
        <validation-observer ref="VFormTemplate">
        <b-modal v-model="openTemplatePenunjang" @ok.prevent="submitTemplatePenunjang" :title="'Template Penunjang'" size="md" ok-title="Pilih Template">
        <div class="row">
            <div class="col-md-12">
            <template v-if="templatePenunjang">
                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                <thead>
                <tr>
                    <th width="20"></th>
                    <th>Nama</th>
                    <th>Isi pemeriksaan</th>
                    <th width="80">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v,k) in (templatePenunjang||[])" :key="k">
                    <td>
                    <b-form-checkbox v-model="v.checked" :value="true" :unchecked-value="null"></b-form-checkbox>
                    </td>
                    <td>{{v.mtp_name}}</td>
                    <td>
                    <span v-if="v.mtp_is_lab == 'Y'" class="badge bg-success mb-1 d-block">Laboratorium</span>
                    <span v-if="v.mtp_is_radio == 'Y'" class="badge bg-primary mb-1 d-block">Radiologi</span>
                    </td>
                    <td>
                        <a @click="openDetailPenunjang(k,v.data_lab,v.data_radio)"
                                            href="javascript:;"
                                            title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-center ml-1" v-b-tooltip.hover
                                            ><i class="icon-eye"></i></a>
                    </td>
                </tr>
                <tr v-if="!(templatePenunjang||[]).length">
                    <td colspan="4" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
                </table>
            </template>
            <span class="mt-1 mb-1 text-center" v-else>Loading ... </span>
            </div>
        </div>
        </b-modal>
        </validation-observer>

        <b-modal v-model="openDetailIsiPenunjang" @ok.prevent="selectOneTemplatePenunjang()" :title="'Detail Isi Template'" size="md" ok-title="Pilih Template">
        <div class="row">
            <div class="col-md-12">
            <table class="table table-bordered table-sm">
                <thead>
                    <tr>
                    <th>Nama Tindakan</th>
                    <th>Tipe Tindakan</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in (dataDetailPenunjangLab||[])" :key="k+'lab'">
                    <td>{{v||"-"}}</td>
                    <td>
                    <span class="badge bg-success mb-1">Laboratorium</span>
                    </td>
                    </tr>
                    <tr v-for="(v,k) in (dataDetailPenunjangRadiologi||[])" :key="k+'rad'">
                    <td>{{v||"-"}}</td>
                    <td>
                    <span class="badge bg-primary mb-1">Radiologi</span>
                    </td>
                    </tr>
                    <tr v-if="!(dataDetailPenunjangLab||[]).length && !(dataDetailPenunjangRadiologi||[]).length">
                    <td class="text-center">Tidak ada data</td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
        </b-modal>
        
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import { stringSimilarity } from "string-similarity-js"
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Operasi from './Operasi.vue'

export default{
    extends: GlobalVue,
    components: {
      Datepicker,VueTimepicker,Operasi
    },
    data() {
      return {
        openAddObat: false,
        openLab: false,
        activeTabLab: 0,
        openRadiologi: false,
        activeTabRadiologi: 0,
        
        openFormRacikan: false,
        obatLen: 0,
        currentPage: 1,
        openRacikan: false,
        rowRacikan: {},

        openRiwayatObat: false,
        selectedObat: '',
        searchObat: '',

        openModalICDetail: false,
        openModalIC: false,
        rowIC: {
          auic_is_pasien_setuju: null,
          auic_persetujuan_tindakan: null
        },

        openFaskes: false,
        faskesSearch : '',
        listFaskesBPJS: [],

        mDiagnosa: [],
        mFaskesSisrute: [],
        times: null,
        dates: null,

        isOpenCopy: false,
        copyData: {},
        typeCopy: null,
        contentData : '',
        copyMessage: false,

        rowPaketAlkes: {},
        openPaketAlkes: false,
        openFormPaketAlkes: false,
        isEditRacikan: false,
        isEditAlkes: false,
        
        
        templateResep: false,
        openTemplate: false,
        openTemplateDetail: false,
        dataDetail: [],
        openDetailIsi: false,
        selectedDetail: null,
        
        templatePenunjang: false,
        openTemplatePenunjang: false,
        dataDetailPenunjangLab: [],
        dataDetailPenunjangRadiologi: [],
        openDetailIsiPenunjang: false,
        selectedDetailPenunjang: null,
      }
    },
    mounted() {
      setTimeout(()=>{
        this.getMasterDiagnosa(this.isParent.row.auod_diagnosa_kode_bpjs||"A03")
        this.getMasterFaskesSisrute(this.isParent.row.aupd_faskes_rujuk_sisrute||"A")
      },1000)
    },
    methods: {
        
        getResepPerawat(){
            this.loadingOverlay = true
            let data = {
                id: this.isParent.row.aurm_aur_id,
                aurm_id: this.isParent.row.aurm_id,
                type: 'get-resep-perawat'
            }
            
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.loadingOverlay = false
                this.isParent.apiGet()
                this.resepPerawatData = res.data.resepPerawatData
            })
        },

        getObat(val) {
            let index = this.isParent.mObat.findIndex(x => x.value == val)
            if (index !== -1) {
                return this.isParent.mObat[index]['text']
            } else {
                return null
            }
        },
            
        getMasterObat(data, k) {
            const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
                return k2 != k
            }), v2 => {
                return v2.value
            })
                return _.filter(this.isParent.mObat, v2 => {
                return sldMenu.indexOf(v2.value) <= -1
            })
        },
        inputEvalText(e,kU){
            if(e){
                this.inputEval(kU)
                this.isParent.row.aupd_laporan_dpjp_evaluasi_data[kU]['is_done'] = "Y"
            }else{
                this.isParent.row.aupd_laporan_dpjp_evaluasi_data[kU]['is_done'] = "N"
            }
        },
        inputEval(kU){
            this.isParent.row.aupd_laporan_dpjp_evaluasi_data[kU]['done_date'] = moment().format('DD MMM YYYY, HH:mm:ss')
        },
        inputEval2Text(e,k,kU){
            if(e){
                this.inputEval2(k,kU)
                this.isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data[kU]['is_done'] = "Y"
            }else{
                this.isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data[kU]['is_done'] = "N"           
            }
        },
        inputEval2(k,kU){
            this.isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data[kU]['done_date'] = moment().format('DD MMM YYYY, HH:mm:ss')
        },
        inputDataCA(date,jam,k){
            if(date && jam){
                this.isParent.catatanAsuhanDokter[k]['aucad_is_active'] = "Y"
            }else{
                this.isParent.catatanAsuhanDokter[k]['aucad_is_active'] = "N"
            }
        },
        copyToData(){
            this.$refs.myinput.focus()
            document.execCommand('copy')
            this.copyMessage = true
            setTimeout(()=>{
                this.copyMessage = false
            },3000)
        },
        copyKolab(type,row){
            this.copyData = row
            this.typeCopy = type
            this.isOpenCopy = true
            
            setTimeout(()=>{
                let value = this.$refs.message.innerHTML        
                value = value.replace(/<strong>/g, '*').replace(/<\/strong>/g, '*')
                value = value.replace(/<\/p>/g,'\n')
                value = value.replace(/<br\/>/g,'\n').replace(/<br>/g,'\n')
                value = value.replace(/<\/li>/g,'\n')

                value = value.replace(/<\/li>/g,'\n').replace(/<ul><li>/g,'\n')
                value = value.replace(/<\/li>/g,'\n').replace(/<li>/g,'-')

                // console.log(value)
                value = value.replace(/<[^>]*>/g, '')
                value = value.replace(/\*-/g, '\n').replace(/<li>/g, '*\n-')

                value = value.replace('*Tindakan Lab', '*Tindakan Lab*')
                value = value.replace('*Tindakan Radiologi', '*Tindakan Radiologi*')
                value = value.replace('*Rekomendasi intervensi yang perlu dilakukan', '*Rekomendasi intervensi yang perlu dilakukan*')
                value = value.replace('*Usulan kolaborasi', '*Usulan kolaborasi*')

                this.contentData = value
                
            },100)

            console.log(this.copyData)

        },
        hitungBMI(k){
            if(this.isParent.catatanDPJPDokter[k].auldd_ttv_weight && this.isParent.catatanDPJPDokter[k].auldd_ttv_height){
                this.isParent.catatanDPJPDokter[k].auldd_ttv_bmi = this.isParent.catatanDPJPDokter[k].auldd_ttv_weight / this.isParent.catatanDPJPDokter[k].auldd_ttv_height
                this.isParent.catatanDPJPDokter[k].auldd_ttv_bmi = this.isParent.catatanDPJPDokter[k].auldd_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.catatanDPJPDokter[k].auldd_ttv_bmi = null
            }
        },

        addAlergi(k){
            this.isParent.catatanDPJPDokter[k]['auldd_riwayat_alergi'].push({
                name : '',
                jenis : null,
            })
        },
        removeAlergi(k,k2){
            this.isParent.catatanDPJPDokter[k]['auldd_riwayat_alergi'].splice(k2,1)
        },
        addLab(){
            this.isParent.row.aupdl_hasil.push({value: null,file: null,nilai_kritis: null,dokter:null, jam_keluar:'',dilaporkan_oleh:null,dilaporkan_pada:null})
        },
        addRadiologi(){
            this.isParent.row.aupdr_hasil.push({value: null,file: null,nilai_kritis: null,dokter:null, jam_keluar:'', dilaporkan_oleh:null,dilaporkan_pada:null})
        },
        cetakAntrianFarmasi(){

            this.$swal({
                    icon: 'warning',
                    title: 'Apakah pasien akan di rawat inap?',
                    text: 'Pastikan Anda mencetak antrean farmasi HANYA untuk pasien rawat jalan.',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
            }).then(result => {
                if(result.value){
                    this.loadingOverlay = true                        
                    let dataCetak = {
                        type: "cetak-antrean-farmasi",
                        id: this.isParent.rowReg.aur_id
                    }
                    Gen.apiRest(
                        "/do/"+'UGDFormDokter', 
                        {
                            data: dataCetak
                        }, "POST"
                    ).then(res => {
                        this.loadingOverlay = false
                        this.isParent.rowReg.aur_no_antrian = res.data

                            
                        this.times = moment().format('HH:mm:ss')
                        this.dates = moment().format('dddd, Do MMMM YYYY')

                        let newWindow = window.open('', '', 'left=0,top=0,width=302,height=350,toolbar=0,scrollbars=0,status=0,addressbar=0'),
                        document = newWindow.document.open(),
                        pageContent =
                            '<!DOCTYPE html>' +
                            '<html>' +
                            '<head>' +
                            '<meta charset="utf-8" />' +
                            '<title>Inventory</title>' +
                            '<style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
                            '</head>' +
                            '<body>' +
                            `<div style="position: relative;max-width: 400px;">
                                <div
                                style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;">
                                <div>
                                    <table border="0" style="border-collapse: collapse;width: 100%;">
                                    <tr>
                                        <td style="padding:0;">
                                        <table style="border-collapse: collapse;width: 100%;">
                                            <tr>
                                                <td style="padding:8px 12px;">
                                                    <div
                                                    style="float: left;width: 15%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                                    <img src="${this.assetLocal('global_assets/images/logo-rsh-mark.png')}" style="width: 100%;" alt="">
                                                    </div>
                                                    <div
                                                    style="text-transform: uppercase;float: left;width: 75%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;text-align: center;">
                                                    <h1 style="margin:0;font-size: 14px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan
                                                        Magelang</h1>
                                                    </div>
                                                    <div style="display: table-cell; clear: both;"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding:2px 12px;">
                                                    <p style="margin:0;font-size: 12px;text-align:center;">Jl. P. Senopati No. 11 Magelang 56123<br />Telp. (0293) 364033
                                                        s/d 364035 <br /> Fax. (0293) 364037</p>
                                                    <div style="display: table-cell; clear: both;"></div>
                                                </td>
                                            </tr>
                                        </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding:20px;">
                                        <table style="border-collapse: collapse;width: 100%;">
                                            <tbody>
                                            <tr>
                                                <td colspan="2">
                                                <p style="margin:  0; text-align:center; font-size:16px;">BUKTI ANTRIAN FARMASI LAYANAN UGD: </p>
                                                <h3 style="font-weight: bold; text-transform: uppercase;letter-spacing: 6px;text-align: center;font-size: 60px;
                                                    margin: 0px 0 10px 0; line-height:1;">
                                                    ${this.isParent.rowReg.aur_no_antrian||'-'}</h3>
                                                <p style="margin: 8px 0; text-align:center; font-size:14px;">Nomor antrian ini berlaku sebagai
                                                    nomor antrian perawat, antrian periksa dokter, dan antrian  pengambilan obat.
                                                </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">JAMINAN </p>
                                                </td>
                                                <td>
                                                <p style="margin:  10px 0 0 0;  color: #000; font-size:12px;">:
                                                ${this.isParent.rowReg.mcp_name||'-'} </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <p style="margin:  0px 0 0 0; color: #000; font-size:12px;">TANGGAL/JAM </p>
                                                </td>
                                                <td>
                                                <p style="margin:  0; color: #000; font-size:12px;">: ${moment().format('DD MMM YYYY, HH:mm')}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <p style="margin:  0 0 10px 0; color: #000; font-size:12px;">NOMOR RM </p>
                                                </td>
                                                <td>
                                                <p style="margin:  0 0 10px 0; color: #000; font-size:12px;"> :${this.isParent.row.ap_code||"-"}</p>
                                                </td>
                                            </tr>
                                            <tr style="border-top:2px solid #333;">
                                                <td>
                                                <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">NAMA </p>
                                                </td>
                                                <td>
                                                <p style="margin:  10px 0 0 0; color: #000; font-size:12px;"> : ${this.isParent.row.ap_fullname||"-"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <p style="margin:  0; color: #000; font-size:12px;">ALAMAT </p>
                                                </td>
                                                <td>
                                                <p style="margin:  0; color: #000; font-size:12px;"> : ${this.isParent.row.ap_address||"-"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <p style="margin:  0 0 10px 0;  color: #000; font-size:12px;">KOTA </p>
                                                </td>
                                                <td>
                                                <p style="margin:  0 0 10px 0;  color: #000; font-size:12px;"> : ${this.isParent.row.ap_kota||"-"}</p>
                                                </td>
                                            </tr>
                                            <tr style="border-top:2px solid #333;">
                                                <td>
                                                <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">TANGGAL LAHIR </p>
                                                </td>
                                                <td>
                                                <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">:
                                                ${moment(this.isParent.row.ap_dob).format('DD MMM YYYY')} </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <p style="margin:  0; color: #000; font-size:12px;">LAYANAN  </p>
                                                </td>
                                                <td>
                                                <p style="margin:  0; color: #000; font-size:12px;"> : UGD</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;">DOKTER </p>
                                                </td>
                                                <td>
                                                <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;"> : ${this.isParent.rowReg.bu_full_name||"-"} </p>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </td>
                                    </tr>
                                    </table>
                                </div>
                                </div>
                            </div>` +
                            '</body></html>'
                        document.write(pageContent)
                        document.close()
                        newWindow.moveTo(0, 0)
                        newWindow.resizeTo(screen.width, screen.height)
                        setTimeout(function() {
                            newWindow.print()
                            newWindow.close()
                        }, 250)
                    })
                }
            })

        },
        changeRujuk(e){
            if(e.indexOf('RU') == -1){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda yakin ingin menghapus Rujukan?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if(result.value){
                        this.loadingOverlay = true                        
                        let data = {
                            type: "delete-rujukan",
                            id: this.isParent.rowReg.aur_id
                        }
                        Gen.apiRest(
                            "/do/"+'UGDFormDokter', 
                            {
                                data: data
                            }, "POST"
                        ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok',
                            }).then(result2 => {
                                if (result2.value) {
                                    this.isParent.apiGet()
                                }
                            })
                        }).catch(err => {
                            this.loadingOverlay = false
                            if (err) {
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })
                    }
                    else{
                        this.isParent.row['aupd_tindak_lanjut'].push('RU')
                    }
                })   
            }
        },
        changeInpRadiologi(e,v,kU,k){
            if(!e && v.inform_consent){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda yakin ingin menghapus tindakan ini, Tindakan telah mempunyai Informconsent?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if(result.value){
                        this.loadingOverlay = true                        
                        let data = {
                            type: "remove-ic",
                            id: v.inform_consent
                        }
                        Gen.apiRest(
                            "/do/"+'UGDFormDokter', 
                            {
                                data: data
                            }, "POST"
                        ).then(()=>{
                            this.loadingOverlay = false                                            
                            this.isParent.row.aupdr_data[kU]['data'][k]['inform_consent'] = null                    
                        })
                    }
                    else{
                        this.isParent.row.aupdr_data[kU]['data'][k]['selected'] = true

                    }
                })
            }
        },
        getMasterDiagnosa: _.debounce(function (e) {
            if(e){
                let data = {
                    search : e,
                    type : 'select-diagnosa'
                }
                Gen.apiRest(
                    "/do/"+'UGDFormDokter', 
                    {
                        data: data
                    }, "POST"
                ).then(res=>{
                    if(res.data.status == 'success'){
                    this.mDiagnosa = res.data.response.diagnosa
                    }
                })
            }
        }, 1000),
        getMasterFaskesSisrute: _.debounce(function (e) {
            console.log(e)
            if(e){
                let data = {
                    search : e,
                    type : 'select-faskes-sisrute'
                }
                Gen.apiRest(
                    "/do/"+'UGDFormDokter', 
                    {
                        data: data
                    }, "POST"
                ).then(res=>{
                    console.log(res)
                    this.mFaskesSisrute = res.data
                })
            }
        }, 1000),

        inpMasterFaskesSisrute(e){
            if(e){
                let idx = this.mFaskesSisrute.findIndex(x => x.KODE == e) 
                this.isParent.row.aupd_faskes_rujuk_sisrute = this.mFaskesSisrute[idx]['KODE']
                this.isParent.row.aupd_faskes_rujuk_sisrute_text = this.mFaskesSisrute[idx]['NAMA']
            }
        },
        inpMasterDiagnosa(e){
            if(e){
                let idx = this.mDiagnosa.findIndex(x => x.kode == e) 
                this.isParent.row.auod_diagnosa_kode_bpjs_text = this.mDiagnosa[idx]['nama']
            }
        },  
        autoFillDPJP(){
            this.isParent.row.aupd_laporan_dpjp_tanggal = moment().format('YYYY-MM-DD')
            this.isParent.row.aupd_laporan_dpjp_tanggal_evaluasi = moment().format('YYYY-MM-DD')
            this.isParent.row.aupd_laporan_dpjp_evaluasi_date = moment().format('YYYY-MM-DD')
            this.isParent.row.aupd_laporan_dpjp_keluhan = this.isParent.row.ausd_catatan_dokter
            this.isParent.row.aupd_laporan_dpjp_waktu = moment().format('HH:mm')
            this.isParent.row.aupd_laporan_dpjp_ppa = 'Dokter'
            this.isParent.row.aupd_laporan_dpjp_ppa_name = this.user.fullName
            this.isParent.row.aupd_laporan_dpjp_nama = this.isParent.rowReg.aur_dokter_id
            this.isParent.row.aupd_laporan_dpjp_evaluasi_data = []
            this.isParent.row.isOpenLaporanDPJP = true

        },
        submitLab(){
            if (!this.selectedLabInput) {
                return this.$swal({
                    icon: 'error',
                    title: 'Tidak Bisa Lanjut',
                    text: 'Data Tindakan Lab Masih Kosong'
                })
            }

            this.$refs['VFormLab'].validate().then(success=>{
                if(!success){
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan mengirimkan permintaan tindakan laboratorium?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.isParent.row
                            data.type = 'submit-data-lab'
              
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDFormDokter', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if (result.value) {
                                    this.isParent.apiGet()
                                }
                            })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })

                        }
                    })
                }
            })
        },
        
        
        submitRadiologi(){
            
            if (!this.selectedRadioInput) {
                return this.$swal({
                    icon: 'error',
                    title: 'Tidak Bisa Lanjut',
                    text: 'Data Tindakan Radiologi Masih Kosong'          
                })
            }
            
            this.$refs['VFormRadiologi'].validate().then(success=>{
                if(!success){
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan mengirim permintaan tindakan Radiologi?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.isParent.row
                            data.type = 'submit-data-radiologi'
              
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDFormDokter', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if (result.value) {
                                    this.isParent.apiGet()
                                }
                            })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })

                        }
                    })
                }
            })
        },

        submitFarmasi(){
            this.$refs['VFormFarmasi'].validate().then(success=>{
                if(!success){
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.isParent.row
                            data.type = 'submit-data-farmasi'
                            data.resepDokter = this.isParent.resepDokter
              
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDFormDokter', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if (result.value) {
                                    this.isParent.apiGet()
                
                                }
                                this.$socket.emit('refresh_data', {to : 'UGDFarmasi'})
                            })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })

                        }
                    })
                }
            })
        },

        submitTindakLanjut(){
            if (this.isParent.row.aupd_is_operasi == 'Y') {
                if (!this.validateICOperasi) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Tidak dapat Lanjut',
                        text: 'Tindakan Operasi Memerlukan Inform Consent'
                    })
                }
            }


            this.$refs['VFormTindakLanjut'].validate().then(success=>{
                if(!success){
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.isParent.row
                            data.tindakanOperasi = this.selectedOperasiInput
                            data.tindakanOperasiId = this.selectedOperasiInputId
                            data.tindakanIC = this.selectedOperasiInformConsent

                            data.type = 'submit-data-tindak-lanjut'
              
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDFormDokter', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            }).then(result => {
                                if (result.value) {
                                    this.isParent.apiGet()
                                }
                            })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })

                        }
                    })
                }
            })
        },

        changeCA(e,k){
            if(e == "Y"){
                this.isParent.catatanAsuhanDokter[k]['aucad_done_at'] = moment().format('YYYY-MM-DD HH:mm:ss')
            }else{
                this.isParent.catatanAsuhanDokter[k]['aucad_done_at'] = null
            }
        },
        changeCADPJP(e){
            if(e == "Y"){
                this.isParent.row.aupd_laporan_done_at = moment().format('YYYY-MM-DD HH:mm:ss')
            }else{
                this.isParent.row.aupd_laporan_done_at = null
            }
        },
        
        inputDataCADPJP(date,jam){
            console.log(date,jam)
            if(date && jam){
                this.isParent.row.aupd_laporan_dpjp_is_done = "Y"
            }else{
                this.isParent.row.aupd_laporan_dpjp_is_done = "N"
            }
        },
  
        inputDataCADPJPPengkajian(date,jam,k){
            if(date && jam){
                this.isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_is_done = "Y"
            }else{
                this.isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_is_done = "N"
            }
        },

        changeCADPJPPengkajian(e,k){
            if(e == "Y"){
                this.isParent.catatanDPJPDokter[k].auldd_laporan_done_at = moment().format('YYYY-MM-DD HH:mm:ss')
            }else{
                this.isParent.catatanDPJPDokter[k].auldd_laporan_done_at = null
            }
        },
        removeCatatanAsuhan(v,k){
            this.loadingOverlay = true        
            if (!this.isParent.row.isEdit) {
                let data = {
                    type: 'remove-catatan-asuhan',
                    id: v.aucad_id
                }
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.isParent.catatanAsuhanDokter.splice(k, 1)
                    this.loadingOverlay = false        
                })
            } else {
                this.isParent.catatanAsuhanDokter.splice(k, 1)
                this.loadingOverlay = false        
            }
        },
        removeLaporanDPJP(v,k){
            this.loadingOverlay = true        
            if (!this.isParent.row.isEdit) {
                let data = {
                    type: 'remove-laporan-dpjp',
                    id: v.auldd_id
                }
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.isParent.catatanDPJPDokter.splice(k, 1)
                    this.loadingOverlay = false     
                    for(let i = 0; i < (this.isParent.catatanDPJPDokter||[]).length; i++){
                        if(i+1 == (this.isParent.catatanDPJPDokter||[]).length){
                            this.isParent.catatanDPJPDokter[i]['isOpen'] = true
                        }
                    }   
                    
                    if((this.isParent.catatanDPJPDokter||[]).length){
                        this.isParent.row.isOpenLaporanDPJP = false
                    }else{
                        this.isParent.row.isOpenLaporanDPJP = true
                    }
                })
            } else {
                this.isParent.catatanDPJPDokter.splice(k, 1)
                this.loadingOverlay = false       
                
                for(let i = 0; i < (this.isParent.catatanDPJPDokter||[]).length; i++){
                    if(i+1 == (this.isParent.catatanDPJPDokter||[]).length){
                        this.isParent.catatanDPJPDokter[i]['isOpen'] = true
                    }
                }

                if((this.isParent.catatanDPJPDokter||[]).length){
                    this.isParent.row.isOpenLaporanDPJP = false
                }else{
                    this.isParent.row.isOpenLaporanDPJP = true
                }

            }
        },
        addLaporanDPJP(){
            const data = {
                auldd_aurm_id: this.isParent.row.aurm_id,
                auldd_laporan_dpjp_tanggal: moment().format('YYYY-MM-DD'),
                auldd_laporan_dpjp_waktu: moment().format('HH:mm'),
                auldd_assesmen: null,
                auldd_tanggal_evaluasi: moment().format('YYYY-MM-DD'),
                auldd_jam_evaluasi: moment().add(10, 'minutes').format('HH:mm'),
                auldd_ppa: 'Dokter',
                auldd_is_active: 'N',
                auldd_done_at: moment(),

                auldd_subjektif: this.isParent.row.ausd_keluhan,
                auldd_riwayat_penyakit: this.isParent.row.ausd_riwayat,
                auldd_riwayat_alergi: this.isParent.row.ausd_has_alergi == 'Y' ? JSON.parse(JSON.stringify(this.isParent.row.ausd_alergi)) : [],
                auldd_catatan_dokter: this.isParent.row.ausd_catatan_dokter,
                auldd_laporan_dpjp_riwayat_pembedahan: this.isParent.row.aupd_laporan_dpjp_riwayat_pembedahan,
                auldd_laporan_dpjp_tindakan_invasif: JSON.parse(JSON.stringify(this.isParent.row.aupd_laporan_dpjp_tindakan_invasif)),
                auldd_laporan_dpjp_keluhan: this.isParent.row.aupd_laporan_dpjp_keluhan,
                auldd_hasil_skoring_nyeri: this.isParent.hitungSkala, //hasil nyeri
                auldd_hasil_kesadaran: this.isParent.row.auod_gcs_total, // hasil kesadaran
                auldd_hasil_kesadaran_e: this.isParent.row.auod_response_mata, // hasil kesadaran
                auldd_hasil_kesadaran_m: this.isParent.row.auod_response_motorik, // hasil kesadaran
                auldd_hasil_kesadaran_v: this.isParent.row.auod_response_verbal, // hasil kesadaran
                auldd_hasil_lab: JSON.parse(JSON.stringify(this.isParent.row.aupdl_hasil)),
                auldd_hasil_radiologi: JSON.parse(JSON.stringify(this.isParent.row.aupdr_hasil)),
                auldd_laporan_dpjp_rekomendasi_intervensi: JSON.parse(JSON.stringify(this.isParent.row.aupd_laporan_dpjp_rekomendasi_intervensi)),
                auldd_laporan_dpjp_usulan_kolaborasi: JSON.parse(JSON.stringify(this.isParent.row.aupd_laporan_dpjp_usulan_kolaborasi)),
                auldd_laporan_dpjp_tanggal_evaluasi: this.isParent.row.aupd_laporan_dpjp_tanggal_evaluasi,
                auldd_laporan_dpjp_waktu_evaluasi: this.isParent.row.aupd_laporan_dpjp_waktu_evaluasi,
                auldd_laporan_dpjp_evaluasi: this.isParent.row.aupd_laporan_dpjp_evaluasi,
                auldd_laporan_dpjp_nama: this.isParent.row.aupd_laporan_dpjp_nama,
                auldd_laporan_dpjp_evaluasi_date: this.isParent.row.aupd_laporan_dpjp_evaluasi_date,
                auldd_laporan_dpjp_evaluasi_waktu: this.isParent.row.aupd_laporan_dpjp_evaluasi_waktu,
                auldd_laporan_dpjp_ppa: this.isParent.row.aupd_laporan_dpjp_ppa,
                auldd_laporan_dpjp_nama_ppa: this.isParent.row.aupd_laporan_dpjp_ppa_name,
                auldd_ttv_tekanan_darah_min: this.isParent.row.auod_ttv_tekanan_darah_min,
                auldd_ttv_tekanan_darah_max: this.isParent.row.auod_ttv_tekanan_darah_max,
                auldd_ttv_nadi: this.isParent.row.auod_ttv_nadi,                
                auldd_ttv_label: this.isParent.row.auod_ttv_label,                
                auldd_ttv_gula_darah: this.isParent.row.auod_ttv_gula_darah,                
                auldd_ttv_pernafasan: this.isParent.row.auod_ttv_pernafasan,                
                auldd_ttv_spo2: this.isParent.row.auod_ttv_spo2,        
                auldd_ttv_suhu: this.isParent.row.auod_ttv_suhu,
                auldd_ttv_weight: this.isParent.row.auod_ttv_weight,
                auldd_ttv_height: this.isParent.row.auod_ttv_height,        
                auldd_ttv_lingkar_kepala: this.isParent.row.auod_ttv_lingkar_kepala,        
                auldd_ttv_bmi: this.isParent.row.auod_ttv_bmi,        
                auldd_ttv_kesadaran: this.isParent.row.auod_ttv_kesadaran,      
                auldd_ttv_luas_permukaan_anak: this.isParent.row.auod_ttv_luas_permukaan_anak, 
                auldd_ttv_imt: this.isParent.row.auod_ttv_imt, 
                auldd_laporan_dpjp_evaluasi_data: JSON.parse(JSON.stringify(this.isParent.row.aupd_laporan_dpjp_evaluasi_data)),
                id: null,
                isOpen: true,
                type: 'add-laporan-dpjp'
            }
            
            if (!this.isParent.row.isEdit) {
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.isParent.apiGet()
                })
            } else {
                this.isParent.catatanDPJPDokter.push(data)
                
                for(let i = 0; i < (this.isParent.catatanDPJPDokter||[]).length; i++){
                    if(i+1 == (this.isParent.catatanDPJPDokter||[]).length){
                        this.isParent.catatanDPJPDokter[i]['isOpen'] = true
                    }
                }
                
                if((this.isParent.catatanDPJPDokter||[]).length){
                    this.isParent.row.isOpenLaporanDPJP = false
                }else{
                    this.isParent.row.isOpenLaporanDPJP = true
                }
            }
        },
        addCatatanAsuhan(){
            let data = {
                aucad_aurm_id: this.isParent.row.aurm_id,
                aucad_tanggal: moment().format('YYYY-MM-DD'),
                aucad_jam: moment().format('HH:mm'),
                aucad_assesmen: null,
                aucad_tanggal_evaluasi: moment().format('YYYY-MM-DD'),
                aucad_jam_evaluasi: '',

                // aucad_tanggal_evaluasi: moment().format('YYYY-MM-DD'),
                // aucad_jam_evaluasi: moment().add(10, 'minutes').format('HH:mm'),
                aucad_ppa: 'Dokter',
                aucad_ppa_name: this.user.fullName,
                aucad_is_active: 'N',
                aucad_done_at: moment(),
                id: null
            }
            if (!this.isParent.row.isEdit) {
                data.type = 'add-catatan-asuhan'
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.isParent.apiGet()
                })
            } else {
                this.isParent.catatanAsuhanDokter.push(data)
            }
        },
        addFaskes(){
            this.loadingOverlay = true
            let data = {
                faskesData: this.selectedFaskesInput,
                type: 'submit-faskes-data'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
            this.loadingOverlay = false
            this.$swal({
                icon: 'success',
                title: 'Data Faskes berhasil ditambahkan',
            })
            this.apiGet()
            this.openFaskes = false
            })
        },
        searchFaskesData(){
            this.loadingOverlay = true
            let data = {
                search: this.faskesSearch,
                type: 'search-faskes-data'
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                    data: data
                },
                "POST"
            ).then(res => {
                this.loadingOverlay = false
                this.listFaskesBPJS = res.data
            })
        },    
        doSubmitRacikan() {
            this.$refs['VFormRacikan'].validate().then(success => {
            if (success) {
                let data = this.rowRacikan
                let idx = this.isParent.resepDokter.findIndex(x => x.aurd_id == this.rowRacikan.aurd_id)
                this.isParent.resepDokter[idx]['aurd_satuan'] = this.rowRacikan.aurd_satuan
                this.isParent.resepDokter[idx]['aurd_jumlah'] = this.rowRacikan.aurd_jumlah
                this.isParent.resepDokter[idx]['aurd_racikan'] = this.rowRacikan.aurd_racikan
                this.openFormRacikan = false
            } else {
                setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                    }
                }
                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
                }, 500)
            }
            })
        },

        openInformConsentDetail(v,k1,k,kat){
            Gen.apiRest('/do/' + 'UGDFormDokter', {
                data: {
                    type: 'get-data-ic',
                    id: v.inform_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.tindakan = v.text  + "("+kat+")"
                this.rowIC.id_tindakan = v.inform_consent
                 
                this.rowIC.k1 = k1
                this.rowIC.k = k

                this.openModalICDetail = true                   
            })
        },
        openInformConsent(v,k1,k,kat){
            if(!v.inform_consent){
            
                this.rowIC.auic_is_pasien_setuju = null
                this.rowIC.auic_persetujuan_tindakan = null

                this.rowIC.tindakan = v.text  + "("+kat+")"
                this.rowIC.id_tindakan = v.inform_consent
                this.rowIC.k1 = k1
                this.rowIC.k = k

                this.rowIC.auic_diagnosis = null
                this.rowIC.auic_id = null
                this.rowIC.auic_dasar_diagnosis = null
                this.rowIC.auic_tindakan_dokter = null
                this.rowIC.auic_indikasi_tindakan = null
                this.rowIC.auic_tata_cara = null
                this.rowIC.auic_tujuan = null
                this.rowIC.auic_risiko = null
                this.rowIC.auic_kompilasi = null
                this.rowIC.auic_prognosis = null
                this.rowIC.auic_alternatif = null
                this.rowIC.auic_lainnya = null
      
            }else{  
                Gen.apiRest('/do/' + 'UGDFormDokter', {
                    data: {
                        type: 'get-data-ic',
                        id: v.inform_consent,
                    }
                }, 'POST').then(res => {
                    this.rowIC = res.data.row 
                    this.rowIC.tindakan = v.text + "("+kat+")"
                    this.rowIC.id_tindakan = v.inform_consent   
                    this.rowIC.k1 = k1
                    this.rowIC.k = k         
                })
                // console.log(edit)
            }
            this.openModalIC = true
        },
        submitInformConcent(){
            this.$refs['VFormInformconsent'].validate().then(success => {
                if(success){
                    this.openModalIC = false

                    let data = this.rowIC
                    data.type = 'submit-data-inform-consent-radiologi'
                    data.auic_aur_id = this.isParent.rowReg.aur_id
                    data.auic_name = this.rowIC.tindakan

                    this.loadingOverlay = true
                    Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                        data: data
                    },
                    "POST"
                    ).then(res => {
                        this.isParent.row['aupdr_data'][this.rowIC.k]['data'][this.rowIC.k1]['inform_consent'] = res.data
                        this.loadingOverlay = false
                        this.isParent.autoSave(this.isParent.row)
    
                        this.rowIC.auic_id = null

                        this.isParent.row.type = "auto-save"
                        
                        Gen.apiRest(
                            "/do/"+'UGDFormDokter',
                            {data:this.isParent.row}, 
                            "POST"
                        )
                        
                        return this.$swal({
                            title: "Data Informed Consent berhasil disimpan",
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                    })
                }
            })
        },
        changeJenis(e, k) {
            this.isParent.resepDokter[k].aurd_item_id = null
            this.isParent.resepDokter[k].aurd_frekuensi = null
            this.isParent.resepDokter[k].aurd_frekuensi_lainnya = null
        },
        ceilData(value) {
            return Math.ceil(value)
        },
        selectObat(e, k) {
            let index = this.isParent.mObat.findIndex(x => x.value == e)
            if (index !== -1) {
                let data = this.isParent.mObat[index]
                this.rowRacikan.aurd_racikan[k]['dosis'] = data.dosis
                this.rowRacikan.aurd_racikan[k]['satuan_dosis'] = data.satuan_dosis
                this.rowRacikan.aurd_racikan[k]['satuan_jumlah'] = data.satuan
            }
        },
        filterObat: _.debounce(function (e, k) {
            if (e) {
                this.isParent.resepDokter[k]['search'] = e
                let id = []
                for (let i = 0; i < (this.isParent.resepDokter || []).length; i++) {
                    if (this.isParent.resepDokter[i]['aurd_item_id'] && this.isParent.resepDokter[i]['aurd_jenis'] == 1) {
                    id.push(this.isParent.resepDokter[i]['aurd_item_id'])
                    }
                }
                Gen.apiRest('/do/' + 'UGDFormDokter', {
                    data: {
                        type: 'select-obat',
                        search: e,
                        id: id
                    }
                }, 'POST').then(res => {
                    this.isParent.mObatPreferensi = res.data.data
                    this.isParent.resepDokter[k]['currentPage'] = 1
                    this.isParent.resepDokter[k]['totalRows'] = res.data.totalRows
                })
            }
        }, 10),

        
        selectJumlahSatuan(e, k) {
            let data = {
                id: e,
                type: 'get-by-obat'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.resepDokter[k].aurd_satuan = res.data.satuan
                this.isParent.resepDokter[k].aurd_nama = res.data.nama
                this.isParent.resepDokter[k].aurd_racikan = null

                this.isParent.resepDokter[k].aurd_dosis = res.data.dosis
                this.isParent.resepDokter[k].aurd_satuan_dosis = res.data.satuan_dosis
                this.isParent.resepDokter[k].aurd_item_lainnya = null

                this.isAlergi(res.data.nama, k)
            })
        },
        
        isAlergi(nama, k) {
            let data = []
            for (let i = 0; i < (this.isParent.row.ausd_alergi || []).length; i++) {
                if (this.isParent.row.ausd_alergi[i]['jenis'] == 'Obat') {
                    data.push(this.isParent.row.ausd_alergi[i]['name'])
                }
            }
            let isWarning = 0
            for (let i = 0; i < (data || []).length; i++) {
                let result = stringSimilarity(nama||"", data[i])
                if (result > 0.3) isWarning += 1
            }

            this.isParent.resepDokter[k]['aurd_is_alergi'] = isWarning ? "Y" : "N"
        },
        selectJumlahSatuanAlkes(e, k) {
            let data = {
                id: e,
                type: 'get-by-alkes'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.resepDokter[k].aurd_satuan = res.data.satuan
                this.isParent.resepDokter[k].aurd_nama = res.data.nama
                this.isParent.resepDokter[k].aurd_racikan = null

                this.isParent.resepDokter[k].aurd_dosis = null
                this.isParent.resepDokter[k].aurd_satuan_dosis = null
                this.isParent.resepDokter[k].aurd_frekuensi = null
                this.isParent.resepDokter[k].aurd_frekuensi_lainnya = null
                this.isParent.resepDokter[k].aurd_item_lainnya = null
            })
        },
        selectJumlahSatuanInfus(e, k) {
            let data = {
                id: e,
                type: 'get-by-infus'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.resepDokter[k].aurd_satuan = res.data.satuan
                this.isParent.resepDokter[k].aurd_nama = res.data.nama
                this.isParent.resepDokter[k].aurd_racikan = null

                this.isParent.resepDokter[k].aurd_dosis = null
                this.isParent.resepDokter[k].aurd_satuan_dosis = null
                this.isParent.resepDokter[k].aurd_frekuensi = null
                this.isParent.resepDokter[k].aurd_frekuensi_lainnya = null
                this.isParent.resepDokter[k].aurd_item_lainnya = null
            })
        },
        selectJumlahSatuanRacikan(e, k) {
            let data = {
                id: e,
                type: 'get-by-racikan'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.resepDokter[k].aurd_satuan = res.data.satuan
                this.isParent.resepDokter[k].aurd_nama = res.data.nama
                this.isParent.resepDokter[k].aurd_racikan = res.data.data

                this.isParent.resepDokter[k].aurd_dosis = null
                this.isParent.resepDokter[k].aurd_satuan_dosis = null
                this.isParent.resepDokter[k].aurd_frekuensi = null
                this.isParent.resepDokter[k].aurd_frekuensi_lainnya = null
                this.isParent.resepDokter[k].aurd_item_lainnya = null
            })
        },
        isAlergiLabel(nama) {
            let data = []
            for (let i = 0; i < (this.isParent.row.ausd_alergi || []).length; i++) {
                if (this.isParent.row.ausd_alergi[i]['jenis'] == 'Obat') {
                    data.push(this.isParent.row.ausd_alergi[i]['name'])
                }
            }
            let isWarning = 0
            for (let i = 0; i < (data || []).length; i++) {
                let result = stringSimilarity(nama||"", data[i])
                if (result > 0.3) isWarning += 1
            }
            return isWarning
        },

        countLen() {
            setTimeout(() => {
                this.obatLen = $('.sp-col ul li').length
            }, 100)
        },

        toValidate(val){
            return {...val}
        },
        
        checkDateIsLibur(e){
            let year = moment(e, "YYYY").format('YYYY')
            let data = {
                year: year,
                type: 'get-holiday-config'
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                    data: data
                },
                "POST"
            ).then(res => {
                this.isParent.mHariLibur = res.data.mHariLibur
            })
        },
        
        getConfigDynamic(master, value) {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                    text = master[index]['text']
                }
            }
            return text
        },

        doOpenLab() {
            this.openLab = true
        },
        doOpenRadiologi() {
            this.openRadiologi = true
        },
        changeTabLab(e) {
            this.activeTabLab = e
        },
        changeTabRadiologi(e) {
            this.activeTabRadiologi = e
        },
        
        changeLab(e) {
            if (e == 'N') {
                this.isParent.row.aupdl_prioritas = null
                this.isParent.row.aupdl_fasting = null
                this.isParent.row.aupdl_catatan = null
                this.isParent.row.aupdl_hasil_lainnya = null
                this.resetLab()
            } else {
                this.isParent.row.aupdl_prioritas = 2
                this.isParent.row.aupdl_fasting = 'N'
            }
        },
        
        isShowHeadLab(i) {
            let isData = 0
            for (let j = 0; j < (this.isParent.row.aupdl_data[i]['dubData'] || []).length; j++) {
                for (let k = 0; k < (this.isParent.row.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
                    if (this.isParent.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                        isData += 1
                    }
                }
            }
            return isData
        },
        isShowHeadSubHeadLab(i, j) {
            let isData = 0
            for (let k = 0; k < (this.isParent.row.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
                if (this.isParent.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                    isData += 1
                }
            }
            return isData
        },
        isShowLab(i, j, k) {
            let isData = 0
            if (this.isParent.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                isData += 1
            }
            return isData
        },
        resetLab() {
            for (let i = 0; i < (this.isParent.row.aupdl_data || []).length; i++) {
                for (let j = 0; j < (this.isParent.row.aupdl_data[i]['dubData'] || []).length; j++) {
                    for (let k = 0; k < (this.isParent.row.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
                    this.isParent.row.aupdl_data[i]['dubData'][j]['data'][k]['selected'] = false
                    }
                }
            }
        },


        
        changeRadiologi(e) {
            const mRadiologi = this.isParent.mRadiologi
            if (e == 'N') {
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda yakin ingin menghapus tindakan Radiologi?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {                
                        this.isParent.row.aupdr_prioritas = null
                        this.isParent.row.aupdr_alergi = null
                        this.isParent.row.aupdr_catatan = null
                        this.isParent.row.aupdr_data = mRadiologi
                        this.isParent.row.aupdr_to_radio = null
                        this.isParent.row.aupdr_hasil_lainnya = null
                    
                        this.resetRadiologi()
                    
                        let data = {
                            id: this.isParent.rowReg.aur_id,
                            type: 'delete-radiologi-ic'
                        }
                        Gen.apiRest(
                            "/do/" + 'UGDFormDokter', {
                                data: data
                            },
                            "POST"
                        )

                        let dataVal = {
                            id: this.isParent.rowReg.aur_id,
                            type: 'delete-radiologi'
                        }
                        Gen.apiRest(
                            "/do/" + 'UGDFormDokter', {
                                data: dataVal
                            },
                            "POST"
                        )
                    }else{
                        this.isParent.row.aupdr_is_radio = "Y"
                    }
                })
            } else {
                this.isParent.row.aupdr_prioritas = 2
                this.isParent.row.aupdr_alergi = "N"
                this.isParent.row.aupdr_to_radio = "Y"
            }
        },
        isShowHeadRadiologi(j) {
            let isData = 0
            for (let k = 0; k < (this.isParent.row.aupdr_data[j]['data'] || []).length; k++) {
                if (this.isParent.row.aupdr_data[j]['data'][k]['selected']) {
                    isData += 1
                }
            }
            return isData
        },
        isShowRadiologi(j, k) {
            let isData = 0
            if (this.isParent.row.aupdr_data[j]['data'][k]['selected']) {
                isData += 1
            }
            return isData
        },
        resetRadiologi() {
            for (let i = 0; i < (this.isParent.row.aupdr_data || []).length; i++) {
                for (let j = 0; j < (this.isParent.row.aupdr_data[i]['data'] || []).length; j++) {
                    this.isParent.row.aupdr_data[i]['data'][j]['selected'] = false
                }
            }
        },

        changePageObat(page, k, act, col) {
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this.isParent[col][k]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this.isParent[col][k]['totalRows'] / 10)) {
                    page += 1
                    this.isParent[col][k]['currentPage'] += 1
                }
            }

            let data = {
                type: 'select-paging-obat',
                page: page,
                idDokter: this.isParent.rowReg.aur_dokter_id,
                search: this.isParent[col][k]['search']
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.mObatPreferensi = res.data.data
            })
        },

        openObat(v = null) {
            let data = {
                aurd_aur_id: this.isParent.row.aurm_aur_id,
                aurd_aurm_id: this.isParent.row.aurm_id,
                aurd_jenis: 1,
                aurd_item_id: null,
                aurd_nama: null,
                aurd_jumlah: null,
                aurd_satuan: null,
                aurd_frekuensi: null,
                aurd_frekuensi_lainnya: null,
                aurd_keterangan: null,
                id: null
            }
            if (!this.isParent.row.isEdit) {
                data.type = 'add-resep-dokter'
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    let resp = res.data
                    this.isParent.resepDokter.push(resp.row)
                })
            } else {
                this.isParent.resepDokter.push(data)
            }

        },
        
        removeObat(v, k) {
            if (!this.isParent.row.isEdit) {
                let data = {
                    type: 'remove-resep-dokter',
                    id: v.aurd_id
                }
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.isParent.resepDokter.splice(k, 1)
                })
            } else {
                this.isParent.resepDokter.splice(k, 1)
            }
        },
        
        removeObatAlkes(v, k) {
            if (!this.isParent.row.isEdit) {
                let data = {
                    type: 'remove-resep-dokter',
                    id: v.aurd_id
                }
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.isParent.resepPerawatData.splice(k, 1)
                })
            } else {
                this.isParent.resepPerawatData.splice(k, 1)
            }
        },
        
        resetObat() {
            this.loadingOverlay = true
            let data = {
                id: this.isParent.row.aurm_id,
                type: 'get-resep-dokter'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.isParent.resepDokter = resp.data
                this.loadingOverlay = false
            })
        },

        
        toRacikan(v,isEdit=false) {
            this.loadingOverlay = true
            let data = {
                id: v.aurd_item_id,
                type: 'get-racikan-data'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isEditRacikan = isEdit
                this.loadingOverlay = false
                this.rowRacikan = {
                    ...v,
                    ...res.data.row
                }
                
                this.isParent.mObat = res.data.mObat
                this.openRacikan = true
            })
        },
        
        doOpenFormRacikan() {
            let data = {
                type: 'get-all-obat'
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                    data: data
                },
                "POST"
            ).then(res => {
                this.isParent.mObat = res.data.data
                this.openFormRacikan = true
                this.openRacikan = false
            })
        },
        
        addObat() {
            this.rowRacikan.aurd_racikan.push({
                value: null,
                dosis: null,
                satuan_dosis: null,
                jumlah: null,
                satuan_jumlah: null,
            })
        },
        
        resetData() {
            this.searchObat = ''
            this.openFormRacikan = false
            this.openFormPaketAlkes = false
            this.isParent.apiGet()
        },
        
        searchAlergi: _.debounce(function (e) {
          let data = {
              name: e,
              type: 'get-alergi'
          }
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
              this.isParent.mAlergi = res.data.data            
          })
        }, 100),

        addEvaluasi(){
            this.isParent.row.aupd_laporan_dpjp_evaluasi_data.push({
                value : '',
                is_done : 'N',
                done_date : null
            })
        },
        addEvaluasi2(k){
            console.log(k)

            this.isParent.catatanDPJPDokter[k].auldd_laporan_dpjp_evaluasi_data.push({
                value : '',
                is_done : 'N',
                done_date : null
            })
        },
        
        // POLI ADJSTMENT //
        selectJumlahSatuanPaketAlkes(e, k) {
            let data = {
                id: e,
                type: 'get-by-paket-alkes'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.resepDokter[k].aurd_satuan = res.data.satuan
                this.isParent.resepDokter[k].aurd_nama = res.data.nama
                this.isParent.resepDokter[k].aurd_paket_alkes = res.data.data

                this.isParent.resepDokter[k].aurd_dosis = null
                this.isParent.resepDokter[k].aurd_satuan_dosis = null
                this.isParent.resepDokter[k].aurd_frekuensi = null
                this.isParent.resepDokter[k].aurd_frekuensi_lainnya = null
                this.isParent.resepDokter[k].aurd_item_lainnya = null
            })
        },
        toPaketAlkes(v,isEdit=false) {
            this.loadingOverlay = true
            let data = {
                id: v.aurd_item_id,
                type: 'get-paket-alkes-data'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isEditAlkes = isEdit
                this.loadingOverlay = false
                this.rowPaketAlkes = {
                    ...v,
                    ...res.data.row
                }
                this.openPaketAlkes = true
            })
        },
        
        doOpenFormAlkes(){
            this.openFormPaketAlkes = true
            this.openPaketAlkes = false
        },
        
        selectAlkes(e, k) {
            let index = this.isParent.mAlatKesehatan.findIndex(x => x.value == e)
            if (index !== -1) {
                let data = this.isParent.mAlatKesehatan[index]
                this.rowPaketAlkes.aurd_paket_alkes[k]['dosis'] = data.dosis
                this.rowPaketAlkes.aurd_paket_alkes[k]['satuan_dosis'] = data.satuan_dosis
                this.rowPaketAlkes.aurd_paket_alkes[k]['satuan_jumlah'] = data.satuan
            }
        },
        
        doSubmitAlkes() {
            this.$refs['VFormPaketAlkes'].validate().then(success => {
                if (success) {
                    let data = this.rowPaketAlkes
                    let idx = this.isParent.resepDokter.findIndex(x => x.aurd_id == this.rowPaketAlkes.aurd_id)
                    this.isParent.resepDokter[idx]['aurd_paket_alkes'] = this.rowPaketAlkes.aurd_paket_alkes
                    this.openFormPaketAlkes = false
                } else {
                    setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll('.label_error')
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                    if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                    }, 500)
                }
            })
        },
        addAlkes(){
            this.rowPaketAlkes.aurd_paket_alkes.push({
                value: null,
                dosis: null,
                satuan_dosis: null,
                jumlah: null,
                satuan_jumlah: null,
            })
        },
        // POLI ADJSTMENT //

        // TEMPLATE ADJUSTMENT
  
        selectOneTemplatePenunjang(){
            this.templatePenunjang[this.selectedDetailPenunjang]['checked'] = true
            this.submitTemplatePenunjang()
        },
        openTemplatePenunjangData(){
            let idRefTemplate = this.isParent.rowReg.aur_dokter_id
            this.templatePenunjang = false
            let data = {
                id_dokter: idRefTemplate,
                type: 'get-template-penunjang'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },"POST").then(res => {
                this.openTemplatePenunjang = true
                this.templatePenunjang = res.data.data
            })
        },
        openDetailPenunjang(k,data_lab,data_radiologi){
            this.selectedDetailPenunjang = k
            this.dataDetailPenunjangLab = data_lab
            this.dataDetailPenunjangRadiologi = data_radiologi
            this.openDetailIsiPenunjang = true
        },
        submitTemplatePenunjang(){
            if(!(this.selectedTemplateLab||[]).length && !(this.selectedTemplateRadiologi||[]).length){
                return this.$swal({
                    icon: 'error',
                    title: 'Wajib Memilih Satu Item Template'
                })
            }
            
            this.openDetailIsiPenunjang = false

            this.$swal({
                html: 'Menyalin Template...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    this.$swal.showLoading()
                }
            })
            
            let data = {
                selectedTemplateLabLainnya: this.selectedTemplateLabLainnya,
                selectedTemplateLab: this.selectedTemplateLab,
                selectedTemplateRadiologiLainnya: this.selectedTemplateRadiologiLainnya,
                selectedTemplateRadiologi: this.selectedTemplateRadiologi,
                dataMasterLab: this.isParent.row.aupdl_data,
                dataMasterRadiologi: this.isParent.row.aupdr_data,
                type : 'replace-penunjang-from-template'
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                data: data
                },
                "POST"
            ).then(res => {
                this.isParent.row.aupdl_data = res.data.data_lab
                this.isParent.row.aupdl_hasil_lainnya = res.data.data_lab_lainnya
                this.isParent.row.aupdl_is_lab = res.data.is_lab ? "Y" : this.isParent.row.aupdl_is_lab
                
                this.isParent.row.aupdr_data = res.data.data_radiologi
                this.isParent.row.aupdr_hasil_lainnya = res.data.data_radiologi_lainnya
                this.isParent.row.aupdr_is_radio = res.data.is_radiologi ? "Y" : this.isParent.row.aupdr_is_radiologi

                this.$nextTick(() => {
                    this.$swal().close()
                })
                this.openTemplatePenunjang = false
            })
        },


        openTemplateResep(){
            let idRefTemplate = this.isParent.rowReg.aur_dokter_id
            this.templateResep = false
            let data = {
                id_dokter: idRefTemplate,
                type: 'get-template-resep'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },"POST").then(res => {
                this.openTemplate = true
                this.templateResep = res.data.data
            })
        // kalo super admin biar bisa ngetrack pake template dpjp
        },
        openDetail(k,data){
            this.selectedDetail = k
            this.dataDetail = data
            this.openDetailIsi = true
        },
        selectOneTemplate(){
            this.templateResep[this.selectedDetail]['checked'] = true
            this.submitTemplate()
            this.openDetailIsi = false
        },
        submitTemplate(){
            if(!(this.selectedTemplate||[]).length){
                return this.$swal({
                icon: 'error',
                title: 'Wajib Memilih Satu Item Template'
                })
            }
            let dataPost = this.selectedTemplate
            for (let i = 0; i < (dataPost||[]).length; i++) {
                dataPost[i]['aurd_code'] = null
                dataPost[i]['aurd_aur_id'] = this.isParent.row.aurm_aur_id
                dataPost[i]['aurd_aurm_id'] = this.isParent.row.aurm_id

                dataPost[i]['aurd_is_alergi'] = null
                dataPost[i]['aurd_signa1'] = null
                dataPost[i]['aurd_signa2'] = null
            }

            console.log(dataPost)
            this.$swal({
                html: 'Menyalin resep...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    this.$swal.showLoading()
                }
            })
            
            if (!this.isParent.row.isEdit) {
                let data = {
                dataPost : dataPost,
                aurm_id : this.isParent.row.aurm_id,
                type : 'replace-resep-from-template'
                }
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(res => {
                    this.isParent.resepDokter = res.data.data
                    this.$nextTick(() => {
                    this.$swal().close()
                    })
                    this.openTemplate = false
                })
            } else {
                this.isParent.resepDokter = dataPost
                this.$nextTick(() => {
                this.$swal().close()
                })
                this.openTemplate = false
            }
        }




        

    },
    computed: {
        selectedOperasiInput() {
            let data = []
            for (let i = 0; i < (this.isParent.row.aupd_tindakan_operasi || []).length; i++) {
                for (let j = 0; j < (this.isParent.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                    if (this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                        data.push(this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['text'])
                    }
                }
            }
            return data.join(", ")
        },
        selectedOperasiInputId() {
            let data = []
            for (let i = 0; i < (this.isParent.row.aupd_tindakan_operasi || []).length; i++) {
                for (let j = 0; j < (this.isParent.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                    if (this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                        data.push(this.isParent.row.aupd_tindakan_operasi[i]['data'][j])
                    }
                }
            }
            return data
        },
        
        selectedOperasiInformConsent() {
            let data = []
            for (let i = 0; i < (this.isParent.row.aupd_tindakan_operasi || []).length; i++) {
                for (let j = 0; j < (this.isParent.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                    if (this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                        data.push(this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['inform_consent'])
                    }
                }
            }
            return data
        },

        validateICOperasi() {
            let isHaveIC = 0
            for (let i = 0; i < (this.selectedOperasiInputData || []).length; i++) {
                if (this.selectedOperasiInputData[i]['inform_consent']) {
                    isHaveIC = isHaveIC + 1
                }
            }
            return isHaveIC == (this.selectedOperasiInputData || []).length
        },
        selectedOperasiInputData() {
            let data = []
            for (let i = 0; i < (this.isParent.row.aupd_tindakan_operasi || []).length; i++) {
                for (let j = 0; j < (this.isParent.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                    if (this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                        data.push(this.isParent.row.aupd_tindakan_operasi[i]['data'][j])
                    }
                }
            }
            return data
        },

        isHavePemeriksaanFisik(){
            let total = 0
            for(let i = 0; i < (this.Config.mr.configVitalSignUGD||[]).length; i++){
                if(this.isParent.row[this.Config.mr.configVitalSignUGD[i]['value']] != 'TP'){
                    total += 1
                }
            }
            return total
        },
        now(){
            return moment().format('DD MMM YYYY, HH:mm:ss')
        },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        canEditLab() {
            // return this.isParent.rowReg.ar_is_done_ppa_lab !== 'Y'
            return true

        },
        canEditRadiologi() {
            // return this.isParent.rowReg.ar_is_done_ppa_radiologi !== 'Y'
            return true
        },
        canEditFarmasi() {
            return true
        },
        
        selectedFaskesInput() {
            let data = []
            for (let i = 0; i < (this.listFaskesBPJS || []).length; i++) {
                if(this.listFaskesBPJS[i]['selected']){
                    data.push(this.listFaskesBPJS[i])
                }
            }
            return data
        },

        
        selectedLabInput() {
            let data = []
            for (let ik = 0; ik < (this.isParent.row.aupdl_data || []).length; ik++) {
                for (let jk = 0; jk < (this.isParent.row.aupdl_data[ik]['dubData'] || []).length; jk++) {
                    for (let kk = 0; kk < (this.isParent.row.aupdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
                        if (this.isParent.row.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                            data.push(this.isParent.row.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                        }
                    }
                }
            }
            if (this.isParent.row.aupdl_hasil_lainnya) {
                data.push(this.isParent.row.aupdl_hasil_lainnya)
            }
            return data.join(", ")
        },
        selectedRadioInput() {
            let data = []
            for (let i = 0; i < (this.isParent.row.aupdr_data || []).length; i++) {
                for (let j = 0; j < (this.isParent.row.aupdr_data[i]['data'] || []).length; j++) {
                    if (this.isParent.row.aupdr_data[i]['data'][j]['selected']) {
                        data.push(this.isParent.row.aupdr_data[i]['data'][j]['text'])
                    }
                }
            }
            if (this.isParent.row.aupdr_hasil_lainnya) {
                data.push(this.isParent.row.aupdr_hasil_lainnya)
            }
            return data.join(", ")
        },
        disabledDates() {
            let datesDisabled = []
            for (let i = 0; i < this.isParent.mHariLibur.length; i++) {
                datesDisabled.push(new Date(moment(this.isParent.mHariLibur[i]['mhl_date'])))
            }
            return {
                to: new Date(moment().format('YYYY-MM-DD')),
                dates: datesDisabled,
                days: [0],
            }
        },


        selectedTemplate(){
            let selected = []
            for (let i = 0; i < (this.templateResep||[]).length; i++) {
                if(this.templateResep[i]['checked']){
                for (let j = 0; j < (this.templateResep[i]['data']||[]).length; j++) {
                    let dataSelected = this.templateResep[i]['data'][j]
                    dataSelected.ard_code = null
                    selected.push(dataSelected)
                }
                }
            }
            return selected
        },

        selectedTemplateLab(){
            let selected = []
            for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
                if(this.templatePenunjang[i]['checked']){
                for (let j = 0; j < (this.templatePenunjang[i]['mtp_lab_selected_id']||[]).length; j++) {
                    let dataSelected = this.templatePenunjang[i]['mtp_lab_selected_id'][j]
                    selected.push(dataSelected)
                }
                }
            }
            return selected
        },
        selectedTemplateLabLainnya(){
            let selected = []
            for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
                if(this.templatePenunjang[i]['checked'] && this.templatePenunjang[i]['mtp_lab_temp_lainnya']){
                let dataSelected = this.templatePenunjang[i]['mtp_lab_temp_lainnya']
                selected.push(dataSelected)
                }
            }
            return selected.join(", ")
        },
        selectedTemplateRadiologi(){
            let selected = []
            for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
                if(this.templatePenunjang[i]['checked']){
                    for (let j = 0; j < (this.templatePenunjang[i]['mtp_radio_selected_id']||[]).length; j++) {
                        let dataSelected = this.templatePenunjang[i]['mtp_radio_selected_id'][j]
                        selected.push(dataSelected)
                    }
                }
            }
            return selected
        },
        selectedTemplateRadiologiLainnya(){
            let selected = []
            for (let i = 0; i < (this.templatePenunjang||[]).length; i++) {
                if(this.templatePenunjang[i]['checked'] && this.templatePenunjang[i]['mtp_radio_temp_lainnya']){
                    let dataSelected = this.templatePenunjang[i]['mtp_radio_temp_lainnya']
                    selected.push(dataSelected)
                }
            }
            return selected.join(", ")
        },
    },
    watch:{
        openAddObat(v) {
            if (!v) {
                this.resetObat()
            }
        },
    }
}

</script>

<template>
    <div>
        <div class="d-flex align-items-center mb-3">
            <div class="label_code m-0 mr-2">
                <h2>S</h2>
            </div>
            <span class="font-weight-semibold text-indigo">Subjective</span>
        </div>
        <div>
            <div class="row">
                <div class="col-md-6 col-xl-6">
                <div class="form-group">
                    <label>Keluhan Utama<small class="txt_mandatory">*</small></label>
                    <textarea v-model="isParent.row.ausd_keluhan" @input="isParent.inputData('keluhan_utama')" id="keluhan_utama" rows="2" class="form-control" placeholder="" spellcheck="false"></textarea>
                    
                    <span class="text-warning d-none" id="keluhan_utama-typing">User Lain Sedang Mengisi ...</span>            
                    <VValidate 
                        name="Keluhan Utama" 
                        v-model="isParent.row.ausd_keluhan" 
                        :rules="toValidate(isParent.mrValidation.ausd_keluhan)"
                    />
                </div>
                </div>

                <div class="col-md-6 col-xl-6">
                <div class="form-group">
                    <label>Riwayat Penyakit Terdahulu</label>
                    <textarea @input="isParent.inputData('riwayat-old')" id="riwayat-old" v-model="isParent.row.ausd_riwayat" rows="2" class="form-control" placeholder="" spellcheck="false"></textarea>
        
                    <span class="text-warning d-none" id="riwayat-old-typing">User Lain Sedang Mengisi ...</span>            

                </div>
                </div>
                
                <div class="col-md-6 col-xl-6">
                <div class="form-group">
                    <label>Catatan Dokter </label>
                    <textarea @input="isParent.inputData('riwayat')" id="riwayat" v-model="isParent.row.ausd_catatan_dokter" rows="2" class="form-control" placeholder="" spellcheck="false"></textarea>

                    <span class="text-warning d-none" id="riwayat-typing">User Lain Sedang Mengisi ...</span>            
                </div>
                </div>
            </div>
            <hr>
            
            <div class="row">
            <div class="col-md-7">
                <div class="head_panel_red">
                <div class="d-flex justify-content-between align-items-center">
                    <h3>ALERGI </h3>
                    <div>
                    <b-form-radio-group
                        id="has_alergi"
                        @change="isParent.inputData('has_alergi')"
                        @input="changeAlergi($event)"                        
                        :options="isParent.Config.mr.yesNoOpt"
                        v-model="isParent.row.ausd_has_alergi"
                    />

                    <span class="text-warning d-none" id="has_alergi-typing">User Lain Sedang Mengisi ...</span>            

                    <VValidate 
                        name="Alergi" 
                        v-model="isParent.row.ausd_has_alergi" 
                        :rules="toValidate(isParent.mrValidation.ausd_has_alergi)"
                    />
                    </div>
                </div>
                </div>
                
                <template v-if="isParent.row.ausd_has_alergi == 'Y'">
                    <table class="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th width="33%">Jenis</th>
                            <th>Informasi Alergi</th>
                            <th width="64"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in (isParent.row.ausd_alergi||[])" :key="k+'aler'">
                            <td>
                                <v-select placeholder="Pilih Jenis"
                                 @input="isParent.inputData('alergi')"
                                 v-model="isParent.row.ausd_alergi[k]['jenis']" :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                <VValidate 
                                    :name="'Obat '+(k+1)" 
                                    v-model="isParent.row.ausd_alergi[k]['jenis']" 
                                    :rules="{required:1}"
                                />
                            </td>
                            <td>
                                <vue-typeahead-bootstrap
                                v-model="isParent.row.ausd_alergi[k]['name']"
                                :data="isParent.mAlergi" @input="searchAlergi(isParent.row.ausd_alergi[k]['name']);isParent.inputData('alergi')"
                                placeholder="Pilih Alergi"
                                />
                                
                                <VValidate 
                                    :name="'Obat '+(k+1)" 
                                    v-model="isParent.row.ausd_alergi[k]['name']" 
                                    :rules="{required:1}"
                                />
                            </td>
                            <td>
                              <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="3" class="text-center">
                              <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                            </td>
                          </tr>
                        </tfoot>
                    </table>
                    <span class="text-warning d-none" id="alergi-typing">User Lain Sedang Mengisi ...</span>            
                </template>
                <template v-else>
                    <table class="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th width="33%">Jenis</th>
                            <th>Informasi Alergi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                                <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </td>
                            <td>
                              <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </template>
            </div>


            <div class="col-md-5">
                <div class="head_panel_blue">
                <div class="d-flex justify-content-between align-items-center">
                    <h3>OBAT RUTIN </h3>
                    <div>
                    <b-form-radio-group
                        id="has_obat_rutin"
                        @change="isParent.inputData('has_obat_rutin')"
                        :options="isParent.Config.mr.yesNoOpt"
                        v-model="isParent.row.ausd_has_obat_rutin"
                    />

                    <span class="text-warning d-none" id="has_obat_rutin-typing">User Lain Sedang Mengisi ...</span>           
                    <VValidate 
                        name="Obat Rutin" 
                        v-model="isParent.row.ausd_has_obat_rutin" 
                        :rules="toValidate(isParent.mrValidation.ausd_has_obat_rutin)"
                    />
                    </div>
                </div>
                </div>

                <div class="row mt-2" v-if="isParent.row.ausd_has_obat_rutin == 'Y'">
                <div class="col-md-12">
                    <div class="form-group">
                    <label>Keterangan </label>
                    <b-form-textarea id="keterangan_obat_rutin" @input="isParent.inputData('keterangan_obat_rutin')" v-model="isParent.row.ausd_keterangan_obat_rutin" rows="5" cols="3" class="form-control" placeholder=""
                        spellcheck="false"></b-form-textarea>
                    </div>
                    
                    <span class="text-warning d-none" id="keterangan_obat_rutin-typing">User Lain Sedang Mengisi ...</span>          
                    <VValidate 
                        name="Keterangan" 
                        v-model="isParent.row.ausd_keterangan_obat_rutin" 
                        :rules="toValidate(isParent.mrValidation.ausd_keterangan_obat_rutin)"
                    />
                </div>
                </div>
            </div>
            </div>
        
        </div>

        
        <b-modal v-model="openRiwayatKeluhan" :title="'Pilih Keluhan dari Riwayat Pasien'" size="xl" hide-footer>
        <div class="modal-body p-0">
            <div class="mb-3">
            <div class="row">
                <div class="col-md-5 col-lg-4">
                <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                    <input v-model="searchKeluhan" @input="searchKeluhanData" class="form-control"
                        placeholder="Ketik Nama Dokter / No. Registrasi" />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                </div>
                </div>
            </div>
            </div>
            <div class="table-responsive">
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>Keluhan</th>
                    <th>Riwayat Penyakit</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in (isParent.riwayatKeluhan||[])">
                    <tr :class="selectedKeluhan == v.apsd_id? 'table-info':''" :key="k+'riwayat'">
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.apsd_id" v-model="selectedKeluhan" name="historySelection"
                            :id="`hist${k+1}`" class="custom-control-input">
                        <label :for="`hist${k+1}`" class="custom-control-label"></label>
                        </div>
                    </td>
                    <td>
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td>{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td><strong>{{v.bu_full_name||"-"}} <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.apsd_keluhan||"-"}}</td>
                    <td>{{v.apsd_anamnesa||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(isParent.riwayatKeluhan||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>

                </tbody>
            </table>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <div class="text-right">
            <button data-dismiss="modal" type="button" @click="openRiwayatKeluhan = false" class="btn">Batal</button>
            <button data-dismiss="modal" :disabled="!selectedKeluhan" @click="saveKeluhan()" type="button"
                class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
                Pilihan</button>
            </div>
        </div>
        </b-modal>
    </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')


export default{
    extends: GlobalVue,
    
    data(){
        return {
            openRiwayatKeluhan: false,
            selectedKeluhan: '',
            searchKeluhan: '',
        }
    },
    
    methods: {
        toValidate(val){
            return {...val}
        },
        saveKeluhan() {
            if (this.selectedKeluhan) {

                console.log(this.selectedKeluhan)
                let data = {
                    id: this.selectedKeluhan,
                    ausd_id: this.isParent.row.ausd_id,
                    type: 'save-riwayat-keluhan'
                }
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: data
                    },
                    "POST"
                ).then(() => {
                    this.openRiwayatKeluhan = false
                    this.selectedKeluhan = ''
                    this.resetData()
                    console.log(data)
                })
            }
        },

        
        searchKeluhanData: _.debounce(function () {
            let data = {
                search: this.searchKeluhan,
                aur_ap_id: this.isParent.rowReg.aur_ap_id,
                arm_id: null,
                type: 'search-keluhan'
            }
            Gen.apiRest(
            "/do/" + 'UGDFormDokter', {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.isParent.riwayatKeluhan = resp.data
            })
        }, 500),

        


        resetData() {
            this.searchKeluhan = ''
            this.isParent.apiGet()
        },

        searchAlergi: _.debounce(function (e) {
          let data = {
              name: e,
              type: 'get-alergi'
          }
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
              this.isParent.mAlergi = res.data.data            
          })
        }, 100),
        
        changeAlergi(e){
            if(!(this.isParent.row.ausd_alergi||[]).length){
                this.addAlergi()
            }
        },
        
        addAlergi(){
            this.isParent.row.ausd_alergi.push({
                name : '',
                jenis : null,
            })
            this.addMasterAlergi()
            this.isParent.inputData('alergi')
        },
        
        addMasterAlergi(){
            for(let i = 0; i < (this.isParent.row.ausd_alergi||[]).length; i++){
                let newData = this.isParent.row.ausd_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+this.isParent.modulePage,
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        
        removeAlergi(k){
            this.isParent.row.ausd_alergi.splice(k,1)
            this.isParent.inputData('alergi')
        },
    },

    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
    },
}

</script>

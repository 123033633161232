<template>

    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <div class="phi-info d-flex flex-column col-md-1 ml-auto mr-3">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                        
                        <div class="d-flex flex-column">
                            <a href="javascript:;" v-if="row.kajianPerawat" @click="toDetailKajianPerawatUGD(row,row.aurm_ar_id)" data-toggle="modal" class="btn btn-outline-light">Lihat Kajian Perawat</a>

                            <a href="javascript:;" @click="doOpenRiwayat(row)" data-toggle="modal" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis</a>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>

        <div class="content">
            <b-form @submit.prevent="handleSubmit(doSubmit())">
            <div class="card">
                <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                    <h6 class="card-title font-weight-semibold">Kajian Primer</h6>
                </div>
                <b-card-body>
                    <VStepTab :step.sync="stepTab" :last="Steps.length" :config="stepValidate">
                        <template slot="link">
                            <template v-for="(v,k) in Steps">
                                <VStepLink :step.sync="stepTab" :option="k + 1" :key="k" :clickable="(k+1) < stepTab">
                                    {{ v.subtitle }}
                                </VStepLink>
                            </template>
                        </template>
                        <template slot="content">
                            <template v-for="(v,k) in Steps">
                                <VStepContent :step.sync="stepTab" :option="k+1" :key="k">
                                    <template slot="title">
                                        {{ v.subtitle }}
                                    </template>
                                    <template slot="fieldset">
                                        <div>
                                            <validation-observer
                                                ref="VFormSubjektif"
                                            >
                                                <Subjektif v-show="stepTab == 1" />
                                            </validation-observer>
                                            <validation-observer
                                                ref="VFormObjektif"
                                            >
                                                <Objektif v-show="stepTab == 2" />
                                            </validation-observer>
                                            <validation-observer
                                                ref="VFormAssesment"
                                            >
                                                <Assesment v-show="stepTab == 3" />
                                            </validation-observer>
                                            
                                            <validation-observer
                                                ref="VFormPlanning"
                                            >
                                                <Planning v-if="stepTab == 4" />
                                            </validation-observer>

                                        </div>
                                    </template>
                                </VStepContent>
                            </template>
                        </template>
                        <template slot="action">
                            <ul role="menu" aria-label="Pagination">
                                
                                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>

                                <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>

                                <li aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" :class="'btn btn-primary '+(stepTab!=stepLast?'':'disabled')" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                                

                                <li v-if="stepTab == stepLast" aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-primary" role="menuitem">Submit<i class="icon-checkmark3 ml-2"></i></button></li>
                            </ul>
                        </template>
                    </VStepTab> 
                </b-card-body>
            </div>
            </b-form>
        </div>

        <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
            <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
        </b-modal>
        
        <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
            <RiwayatRMUGD v-bind="passToSubRMUGD" />
            <div class="row mt-3">
                <div class="col-md-12">
                    <a href="javascript:;" @click="doOpenRiwayat(row)"
                    class="btn float-right btn-primary btn-labeled btn-labeled-left mb-1">
                    <b><i class="icon-spinner11"></i></b>
                    Muat Ulang
                    </a>
                </div>
            </div>
        </b-modal>

    </div>
</template>



<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._

import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

import Subjektif from './Subjektif.vue'
import Objektif from './Objektif.vue'
import Assesment from './Assesment.vue'
import Planning from './Planning.vue'

import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD.vue'
import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD.vue'




import Gen from '@/libs/Gen.js'

export default {
    extends: GlobalVue,
    data(){
        return {
            stepTab: 1,
            stepLast: 4,
            last_page: 1,
            rowReg: {},
            Steps: [
                {
                    icon: 'create',
                    name: 'kajian-awal',
                    subtitle: 'Subjective',
                    component: Subjektif,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'kajian-nyeri',
                    subtitle: 'Objective',
                    component: Objektif,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'resiko-jatuh',
                    subtitle: 'Assesment',
                    component: Assesment,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'tngkat-kesadaran',
                    subtitle: 'Planning',
                    component: Planning,
                    completed: false
                }
            ],

            mAlergi: [],
            mKesadaran: [],
            riwayatKeluhan: [],

            dataICD9:[],
            dataICD10:[],
            riwayatICD9:[],
            riwayatICD10:[],
            riwayatObat:[],

            mICD9:[],
            mICD10:[], 

            mLab: [],
            mRadiologi: [],
            mTypeSatuan: [],
            mDosis: [],
            mAlatKesehatan: [],
            mAlatInfus: [],
            mRacikan: [],
            mHariLibur: [],
            resepDokter: [],
            resepPerawatData: [],
            mFaskes: [],
            mObatPreferensi: [],
            mObat: [],
            mRuangan: [],
            mDokterRanap: [],
            catatanAsuhanDokter: [],
            catatanDPJPDokter: [],
            mDokterAll: [],
            tindakanLainnya: [],
             
            openDetailKajianPerawatUGD: false,
            openRiwayatRMUGD: false,

            dataKajianPerawat: {},
            dataKajianDokter: {},

            historiKajianPerawat: [],
            historiKajianDokter: [],

            masterKajianPerawat: {},
            masterKajianDokter: {},

            masterTriase: {},
            dataKajianTriase: {},

            historiKajianRadiologi: [],
            historiKajianLab: [],
            historiKajianTriase: [],

            dataKajianRadiologi: {},
            dataKajianLab: {},

            dataKajianFarmasi: [],
            dataUGDReservasi: {},
            activeNyeri: '',
            
            mPaketAlkes: [],
        }
    },
    methods: {   
        toDetailKajianPerawatUGD(v,regID){
            this.loadingOverlay = true
            if(v.kajianPerawat){
                Gen.apiRest(
                    "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+v.kajianPerawat+'?regId='+v.aurm_aur_id, 
                ).then(res=>{
                    this.openDetailKajianPerawatUGD = true    
                    this.dataKajianPerawat = res.data
                    this.loadingOverlay = false
                })
                
                Gen.apiRest(
                    "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+v.kajianPerawat+'?regId='+v.aurm_aur_id+'&master=1', 
                ).then(res=>{
                    this.masterKajianPerawat = res.data
                })
            }else{
                this.openDetailKajianPerawatUGD = true    
                this.loadingOverlay = false
            }
        },
        doOpenRiwayat(v){
            this.loadingOverlay = true

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({}, paramsQuery, {})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })

                v = this.row

                console.log(v)
                
                if(v.kajianDokter){
                    Gen.apiRest(
                        "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id, 
                    ).then(res=>{
                        this.openRiwayatRMUGD = true    
                        this.dataKajianDokter = res.data
                        this.loadingOverlay = false
                    })
                }else{
                    this.openRiwayatRMUGD = true    
                    this.loadingOverlay = false
                }

                if(v.kajianDokter){
                    Gen.apiRest(
                        "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id+'&master=1', 
                    ).then(res=>{
                        this.masterKajianDokter = res.data
                    })
                }

                if(v.triase_id){            
                    Gen.apiRest(
                        "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aurm_aur_id, 
                    ).then(res=>{
                        this.dataKajianTriase = res.data
                    })
                }
                
                if(v.triase_id){            
                    Gen.apiRest(
                        "/get/"+'UGDTriase'+'/'+'master', 
                    ).then(res=>{
                        this.masterTriase = res.data
                    })
                }

                if(v.kajianLab){                        
                    Gen.apiRest(
                        "/get/"+'UGDFormLab'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.aurm_aur_id, 
                    ).then(res=>{
                        this.dataKajianLab = res.data
                    })
                }
                
                if(v.kajianRadiologi){                        
                    Gen.apiRest(
                        "/get/"+'UGDFormRadiologi'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.aurm_aur_id, 
                    ).then(res=>{
                        this.dataKajianRadiologi = res.data
                    })
                }
                    
                Gen.apiRest(
                    "/do/"+'UGDAssesment',
                    {data:{type:'get-riwayat-rekam-medis', aur_id: v.aurm_aur_id}}
                ).then(res=>{
                    let resp = res.data
                    this.historiKajianPerawat = resp.historyKajianPerawat
                    this.historiKajianDokter = resp.historyKajianDokter
                    this.historiKajianRadiologi = resp.historyKajianRadiologi
                    this.historiKajianLab = resp.historyKajianLab
                    this.historiKajianTriase = resp.historyKajianTriase
                    this.loadingOverlay = false
                })

                if(v.aurm_aur_id){
                    Gen.apiRest(
                        "/do/" + 'UGDAssesment', {
                            data: {
                            type: 'get-obat-farmasi',
                            regId: v.aurm_aur_id
                            }
                        }
                    ).then(res => {
                        let resp = res.data
                        this.dataKajianFarmasi = resp.data
                        this.dataUGDReservasi = resp.dataUGDReservasi
                        this.loadingOverlay = false
                    })
                }
                
                
                this.openRiwayatRMUGD = true
            }).catch(()=>{
                this.loadingOverlay = false
            })
        },
        back(){
            this.$router.back()
        },
        apiGet(params = {}, page = 1){
            if(!this.pageSlug || !this.$route.query.regId){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.pageNow = page
                this.last_page = this.data.last_page
                
                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })

            // master
            if(this.$route.params.rmNo){
                Gen.apiRest(
                    "/get/"+url+'?master=1', 
                    {
                    params: Object.assign({page: page}, paramsQuery, params.query||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            }
            
        },

        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                this.row.aurm_last_step = this.stepTab
                this.autoSave(this.row)
                window.scrollTo(0,0)
            }
        },
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    this.$refs['VFormSubjektif'][0].validate().then(success=>{
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                else if(this.stepTab == 2){
                    this.$refs['VFormObjektif'][0].validate().then(success => {
                        if(success){
                            if (!this.row.auod_keadaan_umum && !(this.row.auod_lokasi_nyeri||[]).length) {
                                return this.$swal({
                                    icon: 'error',
                                    title: 'Maaf, data yang Anda isi belum lengkap',
                                    text: 'Mohon Isi Data Keadaan Umum / Status Lokalis'
                                }).then(result => {
                                    if (result.value) {
                                    setTimeout(() => {
                                        window.scrollTo(0, 0)
                                    }, 500)
                                    }
                                })
                            }

                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab                
                            this.row.arj_tindakan_tambahan = this.row.arj_tindakan_tambahan ? ['Tidak Ada Tindakan'] : null
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }

                                
                                let PA = ['Breathing', 'Disability', 'Kekuatan Tangan Kanan', 'Kekuatan Tangan Kiri', 'Kekuatan Kaki Kanan', 'Kekuatan Kaki Kiri','Suara Napas','Airways','Circulation','Nilai Kekuatan Tangan Kanan','Nilai Kekuatan Tangan Kiri','Kekuatan Kaki Kanan','Kekuatan Kaki Kiri']
                                                            
                                for(let i = 0; i < (inv||[]).length; i++){
                                    if(PA.indexOf(inv[i]) != -1){
                                        return this.$swal({
                                            icon: 'error',
                                            title: `Kajian Primer Belum Diisi`
                                        })
                                    }
                                }

                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 3){
                    this.$refs['VFormAssesment'][0].validate().then(success => {
                        if(success){
                            let invalICD9 = 0
                            
                            for (let i = 0; i < (this.dataICD9||[]).length; i++) {
                                if(!this.dataICD9[i]['auicd9d_informed_consent'] && this.dataICD9[i]['mi9_informed_consent']){
                                    invalICD9 += 1
                                }
                            }

                            if(invalICD9){
                                return this.$swal({
                                    icon: 'error',
                                    title: `Ada Tindakan yang memerlukan informed consent`
                                }).then(result => {
                                    if (result.value) {
                                        setTimeout(()=>{
                                            let inv = []
                                            let el = document.querySelectorAll('.label_error')
                                            for(let i = 0; i < (el||[]).length; i++){
                                                if(el[i].style.display !== 'none'){
                                                    inv.push(el[i].id)
                                                }
                                            }
                                            if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                                        },500)
                                    }
                                })
                            }
                            
                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 4){
                    this.$refs['VFormPlanning'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else{
                    console.log("no action")
                }
                window.scrollTo(0,0)
            }
        },
        
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            data.aurm_last_step = this.row.aurm_last_step
            if(data.ap_usia <= 6 || data.ap_usia == null){
                data.auod_skala_nyeri_value = this.hitungSkala
            }
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'UGDFormDokter',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        
        autoSaveResepObat: _.debounce(function (data) {
            let dataPost = {
                type: 'auto-save-resep-obat',
                data: data
            }
            if (!this.row.isEdit) {
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: dataPost
                    },
                    "POST"
                )
            }
        }, 1000),
      
        autoSaveCatatanAsuhan: _.debounce(function (data) {
            let dataPost = {
                type: 'auto-save-catatan-asuhan',
                data: data
            }
            if (!this.row.isEdit) {
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: dataPost
                    },
                    "POST"
                )
            }
        }, 1000),

        doSubmit(){
            if(this.row.aurm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                }).then(result => {
                    if (result.value) {
                        setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                    }
                })
            }   

            let isNotClearDPJP = false
            let isNotClearDPJPParent = false


            for(let i = 0; i < (this.row.aupd_laporan_dpjp_evaluasi_data||[]).length; i++){
                if(this.row.aupd_laporan_dpjp_evaluasi_data[i]['is_done'] != "Y"){
                    isNotClearDPJPParent = true
                }
            }

            for(let i = 0; i < (this.catatanDPJPDokter||[]).length; i++){
                for(let j = 0; j < (this.catatanDPJPDokter[i]['auldd_laporan_dpjp_evaluasi_data']||[]).length; j++){
                    if(this.catatanDPJPDokter[i]['auldd_laporan_dpjp_evaluasi_data'][j]['is_done'] != "Y"){
                        isNotClearDPJP = true
                    }
                }
            }

            if(this.row.aupd_laporan_dpjp == 'Y'){
                if(isNotClearDPJPParent || isNotClearDPJP){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Evaluasi Tidak lengkap',
                        text: 'Silakan Lengkapi Terlebih dahulu'
                    })   
                }
            }
            
            if (!this.row.auod_keadaan_umum && !(this.row.auod_lokasi_nyeri||[]).length) {
                return this.$swal({
                    icon: 'error',
                    title: 'Maaf, data yang Anda isi belum lengkap',
                    text: 'Mohon Isi Data Keadaan Umum / Status Lokalis'
                }).then(result => {
                    if (result.value) {
                    setTimeout(() => {
                        window.scrollTo(0, 0)
                    }, 500)
                    }
                })
            }

            if (!this.row.auod_diagnosa && !(this.dataICD10||[]).length) {
                return this.$swal({
                    icon: 'error',
                    title: 'Diagnosa/ICD10 belum diisi',
                    text: 'Silakan Lengkapi Terlebih dahulu'
                })   
            }

            if (this.row.aupd_is_operasi == 'Y') {
                if (!this.validateICOperasi) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Tidak dapat Lanjut',
                        text: 'Tindakan Operasi Memerlukan Inform Consent'
                    })
                }
            }

            let inval = 0
            this.$refs['VFormSubjektif'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 1
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormObjektif'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 2
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                    
                }
            })
            this.$refs['VFormAssesment'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 3
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormPlanning'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 4
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })

            setTimeout(()=>{
                if(inval){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
            },100)

            
            if (!this.selectedRadioInput && this.row.aupdr_is_radio == 'Y') {
                return this.$swal({
                    icon: 'error',
                    title: 'Tidak Bisa Lanjut',
                    text: 'Data Tindakan Radiologi Masih Kosong'          
                })
            }
            
            if (!this.selectedLabInput && this.row.aupdl_is_lab == 'Y') {
                return this.$swal({
                    icon: 'error',
                    title: 'Tidak Bisa Lanjut',
                    text: 'Data Tindakan Lab Masih Kosong'          
                })
            }

            if(!inval){
                console.log(this.selectedOperasiInputId)
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {
                        let data = this.row
                        data.type = 'submit-data'
                        data.dataICD9 = this.dataICD9
                        data.dataICD10 = this.dataICD10
                        data.resepDokter = this.resepDokter  
                        data.catatanAsuhanDokter = this.catatanAsuhanDokter
                        data.catatanDPJPDokter = this.catatanDPJPDokter
                        data.tindakanOperasi = this.selectedOperasiInput
                        data.tindakanOperasiId = this.selectedOperasiInputId
                        data.tindakanIC = this.selectedOperasiInformConsent
                        data.tindakanLainnya = this.tindakanLainnya

                        this.loadingOverlay = true
                        Gen.apiRest(
                        "/do/" + 'UGDFormDokter', {
                            data: data
                        },
                        "POST"
                        ).then(res => {
                        this.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
                        this.$swal({
                            title: resp.message,
                            icon: resp.status,
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            if (result.value) {
                                if(this.user.levelId == 1){
                                    this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.row.aurm_pasien_id }, query: {regId: this.row.aurm_aur_id, byPassLevel: this.user.levelId == 1 ? this.uDokterUGD : null } }).catch(()=>{})
                                }else{
                                    this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.row.aurm_pasien_id }, query: {regId: this.row.aurm_aur_id} }).catch(()=>{})
                                }
                            }

                            this.$socket.emit('refresh_data', {to : 'UGDFarmasi'})
                        })
                        }).catch(err => {
                        this.loadingOverlay = false
                        if (err) {
                            err.statusType = err.status
                            err.status = "error"
                            err.title = err.response?.data?.title
                            err.message = err.response?.data?.message
                            err.messageError = err.message
                        }
                        this.doSetAlertForm(err)
                        })
                    }
                })
            }
            


        },
        
        autoSaveTindakanLainnya: _.debounce(function (data) {
            let dataPost = {
                type: 'auto-save-tindakan-lainnya',
                data: data
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                data: dataPost
                },
                "POST"
            )
        }, 1000),

        autoSaveICD9: _.debounce(function (data) {
            let dataPost = {
                type: 'auto-save-icd-9',
                data: data
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                data: dataPost
                },
                "POST"
            )
        }, 1000),

        autoSaveICD10: _.debounce(function (data) {
            let dataPost = {
                type: 'auto-save-icd-10',
                data: data
            }
            Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                data: dataPost
                },
                "POST"
            )
        }, 1000),

        
        inputData: _.debounce(function (element) {

            let data = this.row
            data.to = this.$route.query.regId
            data.activeNyeri = this.activeNyeri
            data.users = "dokter"
            data.element = element

            console.log(element,data)

            this.$socket.emit('kajian',data)
        },1000),

        autoSaveCatatanDPJP: _.debounce(function (data) {
            let dataPost = {
                type: 'auto-save-catatan-dpjp',
                data: data
            }
            if (!this.row.isEdit) {
                Gen.apiRest(
                    "/do/" + 'UGDFormDokter', {
                    data: dataPost
                    },
                    "POST"
                )
            }
        },1000)

    },
    mounted() {
        setTimeout(()=>{
            this.initSticky()
        },1000)
    
        setTimeout(()=>{
            this.stepTab = this.row.aurm_last_step||1
        },1500)
    
        this.apiGet()

        this.$socket.emit('new_reg_id_kajian',this.$route.query.regId, this.user.levelId)

        this.sockets.subscribe('show_kajian', function(data) {
            if(data.aurm_aur_id == this.$route.query.regId){
                if(data.users == "perawat"){
                    if(data.element == 'keluhan_utama'){
                        this.row.ausd_keluhan = data.aukpa_keluhan_utama
                    }
                    if(data.element == 'riwayat'){
                        this.row.ausd_catatan_dokter = data.aukpa_riwayat_penyakit
                    }
                    if(data.element == 'riwayat-old'){
                        this.row.ausd_riwayat = data.aukpa_riwayat_penyakit_old
                    }


                    if(data.element == 'alergi'){
                        this.row.ausd_alergi = data.aukpa_alergi
                    }
                    if(data.element == 'has_alergi'){
                        this.row.ausd_has_alergi = data.aukpa_has_alergi            
                    }
                    if(data.element == 'has_obat_rutin'){
                        this.row.ausd_has_obat_rutin = data.aukpa_has_obat_rutin
                    }
                    if (data.element == 'keterangan_obat_rutin') {
                        this.row.ausd_keterangan_obat_rutin = data.aukpa_keterangan_obat_rutin
                    }
                    if(data.element == 'tekanan_darah_min'){
                        this.row.auod_ttv_tekanan_darah_min = data.aukpa_ttv_tekanan_darah_min
                    }
                    if(data.element == 'tekanan_darah_max'){
                        this.row.auod_ttv_tekanan_darah_max = data.aukpa_ttv_tekanan_darah_max
                    }
                    if(data.element == 'nadi'){
                        this.row.auod_ttv_nadi = data.aukpa_ttv_nadi
                        this.row.auod_circulation_nadi_text = data.aukpa_ttv_nadi
                    }
                    if(data.element == 'label'){
                        this.row.auod_ttv_label = data.aukpa_ttv_label
                        this.row.auod_circulation_nadi_label = data.aukpa_ttv_label
                    }
                    if(data.element == 'gula_darah'){
                        this.row.auod_ttv_gula_darah = data.aukpa_ttv_gula_darah
                    }
                    if(data.element == 'pernafasan'){
                        this.row.auod_ttv_pernafasan = data.aukpa_ttv_pernafasan
                        this.row.auod_breathing_frekuensi = data.aukpa_ttv_pernafasan
                    }
                    if(data.element == 'spo2'){
                        this.row.auod_ttv_spo2 = data.aukpa_ttv_spo2
                    }
                    if(data.element == 'suhu'){
                        this.row.auod_ttv_suhu = data.aukpa_ttv_suhu
                    }
                    if(data.element == 'weight'){
                        this.row.auod_ttv_weight = data.aukpa_ttv_weight
                        this.row.auod_ttv_bmi = data.aukpa_ttv_bmi
                    }
                    if(data.element == 'height'){
                        this.row.auod_ttv_height = data.aukpa_ttv_height
                        this.row.auod_ttv_bmi = data.aukpa_ttv_bmi
                    }
                    if(data.element == 'lingkar_kepala'){
                        this.row.auod_ttv_lingkar_kepala = data.aukpa_ttv_lingkar_kepala
                    }
                    if(data.element == 'permukaan_anak'){
                        this.row.auod_ttv_luas_permukaan_anak = data.aukpa_ttv_luas_permukaan_anak
                    }
                    if(data.element == 'bmi'){
                        this.row.auod_ttv_bmi = data.aukpa_ttv_bmi
                    }

                    if(data.element == 'skala_nyeri'){
                        this.row.auod_skala_nyeri = data.aukpn_skala_nyeri
                    }
                    if(data.element == 'skala_nyeri_nips'){
                        this.row.auod_skala_nyeri_nips = data.aukpn_skala_nyeri_nips
                    }
                    if(data.element == 'skala_nyeri_value'){
                        this.row.auod_skala_nyeri_value = data.aukpn_skala_nyeri_value
                    }
                    if(data.element == 'response_mata'){
                        this.row.auod_response_mata = data.aukptk_response_mata
                    }
                    if(data.element == 'response_verbal'){
                        this.row.auod_response_verbal = data.aukptk_response_verbal
                    }
                    if(data.element == 'response_motorik'){
                        this.row.auod_response_motorik = data.aukptk_response_motorik
                    }
                    
                    if(data.element == 'activeNyeri' || data.element == 'skala_nyeri_nips' || data.element == 'skala_nyeri') {
                        console.log(data.activeNyeri)
                        this.activeNyeri = data.activeNyeri
                    }
                    


                    let elem = document.getElementById(data.element)
                    if(elem){
                        let elemtype = document.getElementById(data.element+"-typing")
                        elem.disabled = true
                        elemtype.classList.remove('d-none')        

                        setTimeout(()=>{
                            elem.disabled = false      
                            elemtype.classList.add('d-none')              
                        },1000)
                    }
                

                
                }
            }
        })
    },
    components: {
        VStepTab, VStepLink, VStepContent, Subjektif, Objektif, Assesment, Planning, RiwayatRMUGD, KajianPerawatUGD
    },
    computed: {
        stepValidate(){
            return {
                disabled:false,
                validate:true,
                refs:this.$children
            }
        },
        selectedOperasiInput() {
            let data = []
            for (let i = 0; i < (this.row.aupd_tindakan_operasi || []).length; i++) {
                for (let j = 0; j < (this.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                    if (this.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                        data.push(this.row.aupd_tindakan_operasi[i]['data'][j]['text'])
                    }
                }
            }
            return data.join(", ")
        },
        selectedOperasiInputId() {
            let data = []
            for (let i = 0; i < (this.row.aupd_tindakan_operasi || []).length; i++) {
                for (let j = 0; j < (this.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                    if (this.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                        data.push(this.row.aupd_tindakan_operasi[i]['data'][j])
                    }
                }
            }
            return data
        },
        
        selectedOperasiInformConsent() {
            let data = []
            for (let i = 0; i < (this.row.aupd_tindakan_operasi || []).length; i++) {
                for (let j = 0; j < (this.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                    if (this.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                        data.push(this.row.aupd_tindakan_operasi[i]['data'][j]['inform_consent'])
                    }
                }
            }
            return data
        },

        validateICOperasi() {
            let isHaveIC = 0
            for (let i = 0; i < (this.selectedOperasiInputData || []).length; i++) {
                if (this.selectedOperasiInputData[i]['inform_consent']) {
                    isHaveIC = isHaveIC + 1
                }
            }
            return isHaveIC == (this.selectedOperasiInputData || []).length
        },
        selectedOperasiInputData() {
            let data = []
            for (let i = 0; i < (this.row.aupd_tindakan_operasi || []).length; i++) {
                for (let j = 0; j < (this.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                    if (this.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                        data.push(this.row.aupd_tindakan_operasi[i]['data'][j])
                    }
                }
            }
            return data
        },

        hitungSkala(){
            let value = 0
            if(this.row.ap_usia <= 1 || (this.activeNyeri == 'NIPS')){   
                for(let i = 0; i < (this.row.auod_skala_nyeri_nips||[]).length; i++){
                    value += this.row.auod_skala_nyeri_nips[i]['value']
                }   
            }
            if(this.row.ap_usia > 1 || (this.activeNyeri == 'FL')){
                for(let i = 0; i < (this.row.auod_skala_nyeri||[]).length; i++){
                    value += this.row.auod_skala_nyeri[i]['value']
                }   
            }
            if(this.row.ap_usia > 6 || (this.activeNyeri == 'NP')){
                value += this.row.auod_skala_nyeri_value
            }
            
            return value
        },  
        
        passToSubPerawatUGD(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                dataKajianPerawat: this.dataKajianPerawat,
                historiKajianPerawat: this.historiKajianPerawat,
                masterKajianPerawat: this.masterKajianPerawat,
            })
        },

        passToSubRMUGD(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                dataKajianDokter: this.dataKajianDokter,
                dataKajianTriase: this.dataKajianTriase,
                historiKajianDokter: this.historiKajianDokter,
                masterKajianDokter: this.masterKajianDokter,
                masterTriase: this.masterTriase,
                historiKajianRadiologi: this.historiKajianRadiologi,
                historiKajianLab: this.historiKajianLab,
                historiKajianTriase: this.historiKajianTriase,
                dataKajianRadiologi: this.dataKajianRadiologi,
                dataKajianLab: this.dataKajianLab,
                dataKajianFarmasi: this.dataKajianFarmasi,
                dataUGDReservasi: this.dataUGDReservasi
            })
        },

        selectedLabInput() {
            let data = []
            for (let ik = 0; ik < (this.row.aupdl_data || []).length; ik++) {
                for (let jk = 0; jk < (this.row.aupdl_data[ik]['dubData'] || []).length; jk++) {
                    for (let kk = 0; kk < (this.row.aupdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
                    if (this.row.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                        data.push(this.row.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                    }
                    }
                }
            }
            if (this.row.aupdl_hasil_lainnya) {
                data.push(this.row.aupdl_hasil_lainnya)
            }
            return data.join(", ")
        },

        selectedRadioInput() {
            let data = []
            for (let i = 0; i < (this.row.aupdr_data || []).length; i++) {
                for (let j = 0; j < (this.row.aupdr_data[i]['data'] || []).length; j++) {
                    if (this.row.aupdr_data[i]['data'][j]['selected']) {
                    data.push(this.row.aupdr_data[i]['data'][j]['text'])
                    }
                }
            }
            if (this.row.aupdr_hasil_lainnya) {
                data.push(this.row.aupdr_hasil_lainnya)
            }
            return data.join(", ")
        },
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        resepDokter: {
            handler(v) {
                this.autoSaveResepObat(v)
            },
            deep: true
        },
        catatanAsuhanDokter: {
            handler(v) {
                this.autoSaveCatatanAsuhan(v)
            },
            deep: true
        },  
        catatanDPJPDokter: {
            handler(v) {
                this.autoSaveCatatanDPJP(v)
            },
            deep: true
        },
        dataICD9: {
            handler(v) {
            this.autoSaveICD9(v)
            },
            deep: true
        },
        dataICD10: {
            handler(v) {
            this.autoSaveICD10(v)
            },
            deep: true
        },
        tindakanLainnya: {
            handler(v) {
            this.autoSaveTindakanLainnya(v)
            },
            deep: true
        },

    }
    
}

</script>
<template>
  <div>
    <div class="card-header bg-info">
      <h6 class="card-title font-weight-semibold">Tindakan Operasi</h6>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Tanggal Operasi <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <datepicker input-class="form-control transparent"
                  placeholder="Tanggal Awal" class="my-datepicker"
                  calendar-class="my-datepicker_calendar"
                  v-model="isParent.row.aupd_tanggal_operasi">
              </datepicker>
              <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
              </div>
            </div>
            <VValidate :name="`Tanggal Operasi`"
            v-model="isParent.row.aupd_tanggal_operasi"
            :rules="toValidate(isParent.mrValidation.aupd_tanggal_operasi)" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Waktu Operasi <strong class="text-danger">*</strong></label>
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
              <vue-timepicker manual-input v-model="isParent.row.aupd_waktu_operasi" format="HH:mm"  input-class="form-control"></vue-timepicker>
            </div>
            <VValidate :name="`Waktu Operasi`"
              v-model="isParent.row.aupd_waktu_operasi"
              :rules="toValidate(isParent.mrValidation.aupd_waktu_operasi)" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="">Tindakan Operasi<strong class="text-danger">*</strong></label>
        <div class="req-list-resume">
          <div class="row g-3">
          <template v-for="(v,k) in isParent.row.aupd_tindakan_operasi">
              <div class="col-md-4 col-lg-3" :key="k+'radioData'"
              v-if="isShowHeadOperasi(k)">
              <div class="card shadow-0 border">
                  <div class="card-header py-2 bg-light">
                  <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                  </div>
                  <div class="card-body py-2">
                  <template v-for="v1,k1 in (v.data||[])">
                      <div v-if="isShowOperasi(k,k1)" :key="k1+'radiosubdatas'">
                      <i
                          class="icon-checkmark-circle text-success align-middle mr-1"></i>
                      <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                      <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                      
                      <a href="javascript:;" @click="openInformConsent(v1,k1,k,v.head)" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo ml-2"  v-b-tooltip.hover :title="v1.inform_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                      <i class="icon-file-check2"></i>
                      </a>

                      <a href="javascript:;" @click="openInformConsentDetail(v1,k1,k,v.head)" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                      <i class="icon-file-eye2"></i>
                      </a>
                  
                      </div>
                  </template>
                  </div>
              </div>
              </div>
          </template>

          <span class="col-md-12" v-if="!selectedOperasiInput">Tidak ada Tindakan Operasi</span>
          
          <div class="col-md-12">
            <div class="text-center mt-3">
              <a href="javascript:;" @click="doOpenOperasi" class="btn alpha-blue"><i
                  class="icon-plus2 mr-2"></i>Tambah Tindakan Operasi</a>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div>
            <label for="tindakanAnestesi">Tindakan Anestesi<strong
              class="text-danger">*</strong></label>
            <v-select 
              :options="isParent.Config.mr.tindakanAnestesi"
              v-model="isParent.row.aupd_tindakan_anestesi"
              placeholder="-- Pilih Salah Satu --"
              label="text"
              :clearable="true" :reduce="v=>v.value"
            >
            </v-select>
          
            <VValidate 
                name="Tindakan Anestesi" 
                v-model="isParent.row.aupd_tindakan_anestesi" 
                :rules="toValidate(isParent.mrValidation.aupd_tindakan_anestesi)"
            />
          </div>
        </div>
      </div>
      
      <div class="card-body">
        <div>
          <label for="">Apakah pasien akan dipasang implant?<strong class="text-danger">*</strong></label>
          <div>
            <b-form-radio-group 
            :options="isParent.Config.mr.yesNoOptV2" v-model="isParent.row.aupd_is_implant" />
            <VValidate :name="'Apakah pasien akan dipasang implant?'"
            v-model="isParent.row.aupd_is_implant" :rules="toValidate(isParent.mrValidation.aupd_is_implant)" />
          </div>
        </div>
      </div>
    </div>

    
    <b-modal v-model="openOperasi" :title="'Pilih Item Pemeriksaan Operasi'" size="xl" hide-footer>
      <div class="modal-header d-block p-0 mt-3">
          <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
          <li v-for="(v,k) in isParent.row.aupd_tindakan_operasi" :key="k" @click="changeTabOperasi(k)" class="nav-item">
              <a href="javascript:;" data-toggle="tab"
              :class="k == activeTabOperasi ? 'nav-link active' : 'nav-link'">{{v.head}}</a>
          </li>
          </ul>
      </div>

      <div class="modal-body p-0 mt-3">
          <div class="tab-content">
            <div v-for="(v,k) in isParent.row.aupd_tindakan_operasi" :key="k+'radform'"
                :class="k == activeTabOperasi ? 'tab-pane fade show active' : 'tab-pane fade'">
                <div class="row sc-row g-0">
                <div class="col-md-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                    <div class="selection-control">
                    <b-form-checkbox @input="changeInpTindakanOperasi($event,v1,k,k1)" :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                    </b-form-checkbox>
                    <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                    </div>
                </div>
                </div>
            </div>
          </div>
      </div>

      <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
          <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
          <span class="m-0" v-if="selectedOperasiInput">{{selectedOperasiInput}}</span>
          <span v-else> - </span>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white">
          <div class="text-right">
          <button @click="openOperasi = false" data-dismiss="modal" class="btn">Selesai</button>
          </div>
      </div>
    </b-modal>
    
    <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
        <small class="text-primary mt-2">Klik pada salah satu kolom 'Isi Informasi' untuk melakukan perubahan.</small>
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.auic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="isParent.Config.mr.pasienSetuju"
                            v-model="rowIC.auic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.auic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="isParent.Config.mr.persetujuanTindakan"
                            v-model="rowIC.auic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.auic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
    </validation-observer>
        
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan</h4>
                    <p>{{rowIC.tindakan}}</p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.auic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.auic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.auic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.auic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.auic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.auic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.auic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.auic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.auic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.auic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.auic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>
  </div>
</template>

<script>

import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
  components:{ Datepicker,VueTimepicker },
  computed: {
    isParent(){
      return this.$parent.$parent.isParent
    },
    selectedOperasiInput() {
      let data = []
      for (let i = 0; i < (this.isParent.row.aupd_tindakan_operasi || []).length; i++) {
        for (let j = 0; j < (this.isParent.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
            if (this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['selected']) {
                data.push(this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['text'])
            }
        }
      }
      return data.join(", ")
    }
  },
  mounted() {    
    console.log(this.isParent)
  },
  data() {
    return {
      openOperasi: false,
      activeTabOperasi: 0,
      openModalICDetail: false,
      openModalIC: false,
      rowIC: {
        auic_is_pasien_setuju: null,
        auic_persetujuan_tindakan: null
      },
    }
  },
  methods: {
    
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },

    doOpenOperasi() {
      this.openOperasi = true
    },
    changeTabOperasi(e) {
      this.activeTabOperasi = e
    },
    isShowHeadOperasi(j) {
      let isData = 0
      for (let k = 0; k < (this.isParent.row.aupd_tindakan_operasi[j]['data'] || []).length; k++) {
        if (this.isParent.row.aupd_tindakan_operasi[j]['data'][k]['selected']) {
            isData += 1
        }
      }
      return isData
    },    
    isShowOperasi(j, k) {
        let isData = 0
        if (this.isParent.row.aupd_tindakan_operasi[j]['data'][k]['selected']) {
            isData += 1
        }
        return isData
    },
    resetOperasi() {
        for (let i = 0; i < (this.isParent.row.aupd_tindakan_operasi || []).length; i++) {
            for (let j = 0; j < (this.isParent.row.aupd_tindakan_operasi[i]['data'] || []).length; j++) {
                this.isParent.row.aupd_tindakan_operasi[i]['data'][j]['selected'] = false
            }
        }
    },


    openInformConsentDetail(v,k1,k,kat){
        Gen.apiRest('/do/' + 'UGDFormDokter', {
            data: {
                type: 'get-data-ic',
                id: v.inform_consent,
                isOperasi: true,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = v.text  + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent
              
            this.rowIC.k1 = k1
            this.rowIC.k = k

            this.openModalICDetail = true                   
        })
    },
    openInformConsent(v,k1,k,kat){
        Gen.apiRest('/do/' + 'UGDFormDokter', {
            data: {
                type: 'get-data-ic',
                id: v.inform_consent,
                isOperasi: true,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.tindakan = v.text + "("+kat+")"
            this.rowIC.id_tindakan = v.inform_consent   
            this.rowIC.k1 = k1
            this.rowIC.k = k         
        })
        
        this.openModalIC = true
    },

    submitInformConcent(){
        this.$refs['VFormInformconsent'].validate().then(success => {
            if(success){
                this.openModalIC = false

                let data = this.rowIC
                data.type = 'submit-data-inform-consent-single'
                data.auic_aur_id = this.isParent.rowReg.aur_id
                data.auic_name = this.rowIC.tindakan
                data.auic_created_on = 'Operasi'

                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'UGDFormDokter', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.isParent.row['aupd_tindakan_operasi'][this.rowIC.k]['data'][this.rowIC.k1]['inform_consent'] = res.data
                    this.loadingOverlay = false
                    this.isParent.autoSave(this.isParent.row)

                    this.rowIC.auic_id = null
                    
                    
                    return this.$swal({
                        title: "Data Informed Consent berhasil disimpan",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    },


    
    changeInpTindakanOperasi(e,v,kU,k){
      console.log(e,v,kU,k)
      if(!e && v.inform_consent){
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda yakin ingin menghapus tindakan ini, Tindakan telah mempunyai Inform consent?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
              if(result.value){
                  this.loadingOverlay = true                        
                  let data = {
                      type: "remove-ic",
                      id: v.inform_consent
                  }
                  Gen.apiRest(
                      "/do/"+'UGDFormDokter', 
                      {
                          data: data
                      }, "POST"
                  ).then(()=>{
                      this.loadingOverlay = false                                            
                      this.isParent.row.aupd_tindakan_operasi[kU]['data'][k]['inform_consent'] = null                    
                  })
              }
              else{
                  this.isParent.row.aupd_tindakan_operasi[kU]['data'][k]['selected'] = true

              }
          })
      }
    },
  },
}
</script>
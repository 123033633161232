<template>
    <div>
        <div class="d-flex align-items-center mb-3">
        <div class="label_code m-0 mr-2">
            <h2>O</h2>
        </div>
        <span class="font-weight-semibold text-indigo">Objective</span>
        </div>
        <div>
        <div class="row">
            <div class="col-md-8">
            <div class="form-group">
                <label>Keadaan Umum</label>
                <b-form-textarea v-model="isParent.row.auod_keadaan_umum" rows="2" cols="3"
                class="form-control" placeholder="" spellcheck="false">
                </b-form-textarea>
        
                <VValidate name="Keadaan Umum" v-model="isParent.row.auod_keadaan_umum"
                    :rules="{required: 1, min: 2, max:128}" />
            </div>
            </div>
            <div class="col-md-12">
            <div class="card mb-2">
                <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                        <label>Tekanan Darah</label>
                        <div class="input-group">
                            <b-form-input id="tekanan_darah_min" @input="isParent.inputData('tekanan_darah_min')"  :formatter="isParent.number"
                            v-model="isParent.row.auod_ttv_tekanan_darah_min" type="text" name="name"
                            class="form-control" placeholder="Systole" />

                            <div class="input-group-append input-group-prepend">
                            <span class="input-group-text">/</span>
                            </div>

                            <b-form-input id="tekanan_darah_max" @input="isParent.inputData('tekanan_darah_max')" :formatter="isParent.number" placeholder="Diastole"
                            v-model="isParent.row.auod_ttv_tekanan_darah_max" type="text"
                            class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">mmHG</span>
                            </div>
                        </div>
                        
                        <span class="text-warning d-none" id="tekanan_darah_min-typing">User Lain Sedang Mengisi ...</span>    
                        
                        <span class="text-warning d-none" id="tekanan_darah_max-typing">User Lain Sedang Mengisi ...</span>        

                        <VValidate v-if="isParent.stepTab != 2" name="Tekanan Darah Min" v-model="isParent.row.auod_ttv_tekanan_darah_min"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_tekanan_darah_min)" />
                        <VValidate v-if="isParent.stepTab != 2" name="Tekanan Darah Max" v-model="isParent.row.auod_ttv_tekanan_darah_max"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_tekanan_darah_max)" />
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="form-group">
                        <label>Nadi<span class="text-danger">*</span></label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input style="max-width: 80px;" id="nadi" @input="isParent.inputData('nadi')" :formatter="isParent.number" v-model="isParent.row.auod_ttv_nadi"
                                type="text" class="form-control" />
                                <div class="input-group-append">
                                <span class="input-group-text">x/mnt</span>
                                </div>
                                <div class="input-group-append">
                                    <div style="width: 150px;">
                                        <v-select id="label" @input="isParent.inputData('label')" placeholder="Pilih Label" v-model="isParent.row.auod_ttv_label"
                                        :options="Config.mr.StatusRegular" label="text"
                                        :clearable="true" :reduce="v=>v.value"></v-select>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    
                        <span class="text-warning d-none" id="nadi-typing">User Lain Sedang Mengisi ...</span>    
                    
                        <span class="text-warning d-none" id="label-typing">User Lain Sedang Mengisi ...</span> 

                        <VValidate name="Nadi" v-if="isParent.stepTab != 2" v-model="isParent.row.auod_ttv_nadi"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_nadi)" />

                        <VValidate name="Label" v-if="isParent.stepTab != 2" v-model="isParent.row.auod_ttv_label"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_label)" />
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Gula Darah</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="gula_darah" @input="isParent.inputData('gula_darah')" :formatter="isParent.alphanum"
                                v-model="isParent.row.auod_ttv_gula_darah" type="text"
                                class="form-control" />
                                <div class="input-group-append">
                                <span class="input-group-text">mg/dL</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="gula_darah-typing">User Lain Sedang Mengisi ...</span>     

                        <VValidate name="Gula Darah" v-model="isParent.row.auod_ttv_gula_darah"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_gula_darah)" />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                        <label>Pernafasan<span class="text-danger">*</span></label>
                        <div class="input-group">
                            <b-form-input id="pernafasan" @input="isParent.inputData('pernafasan')"  :formatter="isParent.number"
                            v-model="isParent.row.auod_ttv_pernafasan" type="text" class="form-control" />
                            <div class="input-group-append"><span
                                class="input-group-text">x/mnt</span>
                            </div>
                        </div>
                        
                        <span class="text-warning d-none" id="pernafasan-typing">User Lain Sedang Mengisi ...</span>                                    
                        <VValidate v-if="isParent.stepTab != 2" name="Pernafasan" v-model="isParent.row.auod_ttv_pernafasan"
                        :rules="toValidate(isParent.mrValidation.auod_ttv_pernafasan)" />
                        
                        </div>

                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>SPO2</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="spo2" @input="isParent.inputData('spo2')" :formatter="isParent.number" v-model="isParent.row.auod_ttv_spo2"
                                type="text" class="form-control" />
                                <div class="input-group-append">
                                <span class="input-group-text">%</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="spo2-typing">User Lain Sedang Mengisi ...</span>                                    
                        <VValidate name="SPO2" v-model="isParent.row.auod_ttv_spo2"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_spo2)" />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Suhu<span class="text-danger">*</span></label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="suhu" @input="isParent.inputData('suhu')" :formatter="isParent.number" v-model="isParent.row.auod_ttv_suhu"
                                type="text" class="form-control" />
                                <div class="input-group-append">
                                <span class="input-group-text">C</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="suhu-typing">User Lain Sedang Mengisi ...</span>                                                                                    
                        <VValidate v-if="isParent.stepTab != 2" name="Suhu" v-model="isParent.row.auod_ttv_suhu"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_suhu)" />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Berat Badan</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="weight" @input="hitungBMI();isParent.inputData('weight')" :formatter="isParent.number"
                                v-model="isParent.row.auod_ttv_weight" type="text" class="form-control" />
                                <div class="input-group-append">
                                <span class="input-group-text">kg</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="weight-typing">User Lain Sedang Mengisi ...</span>         

                        <VValidate name="Berat Badan" v-model="isParent.row.auod_ttv_weight"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_weight)" />
                        </div>
                    </div>


                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Tinggi</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input @input="hitungBMI();isParent.inputData('height')"  :formatter="isParent.number"
                                v-model="isParent.row.auod_ttv_height" type="text" class="form-control" />
                                <div class="input-group-append">
                                <span class="input-group-text">cm</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="height-typing">User Lain Sedang Mengisi ...</span>         

                        <VValidate name="Tinggi Badan" v-model="isParent.row.auod_ttv_height"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_height)" />
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Lingkar Kepala</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="lingkar_kepala" @input="hitungBMI();isParent.inputData('lingkar_kepala')" :formatter="isParent.number"
                                v-model="isParent.row.auod_ttv_lingkar_kepala" type="text"
                                class="form-control" />
                                <div class="input-group-append">
                                <span class="input-group-text">cm</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        
                        <span class="text-warning d-none" id="lingkar_kepala-typing">User Lain Sedang Mengisi ...</span>  

                        <VValidate name="Lingkar Kepala" v-model="isParent.row.auod_ttv_lingkar_kepala"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_lingkar_kepala)" />
                        </div>
                    </div>

                    <div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
                        <div class="form-group">
                        <label>Luas Permukaan Tubuh Anak</label>
                        <div class="input-group">
                            <b-form-input id="permukaan_anak" @input="isParent.inputData('permukaan_anak')"  :formatter="isParent.number"
                            v-model="isParent.row.auod_ttv_luas_permukaan_anak" type="text"
                            class="form-control" />
                            <div class="input-group-append"><span
                                class="input-group-text">m<sup>2</sup></span></div>
                        </div>
                        </div>

                        <span class="text-warning d-none" id="permukaan_anak-typing">User Lain Sedang Mengisi ...</span>  
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>BMI</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input disabled v-model="isParent.row.auod_ttv_bmi" type="text"
                                class="form-control" />
                                <div class="input-group-append">
                                <span class="input-group-text">m2</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        <VValidate name="BMI" v-model="isParent.row.auod_ttv_bmi"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_bmi)" />
                        </div>
                    </div>

                    <!--
                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Kesadaran</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <v-select placeholder="Pilih Kesadaran" v-model="isParent.row.auod_ttv_kesadaran"
                                :options="isParent.mKesadaran" label="text" :clearable="true"
                                :reduce="v=>v.value">
                                <template slot="selected-option" slot-scope="option">
                                <span v-b-tooltip.hover
                                    :title="option.text">{{ option.text }}</span>
                                </template>
                                <template slot="option" slot-scope="option">
                                <span v-b-tooltip.hover
                                    :title="option.text">{{ option.text }}</span>
                                </template>
                            </v-select>
                            </div>
                        </div>
                        <VValidate name="Kesadaran" v-model="isParent.row.auod_ttv_kesadaran"
                            :rules="toValidate(isParent.mrValidation.auod_ttv_kesadaran)" />
                        </div>
                    </div>
                    -->

                    <!--
                    <div class="col-md-3" v-if="!isParent.row.auod_ttv_bmi">
                        <div class="form-group">
                            <label>IMT</label>
                            <b-form-input v-model="isParent.row.auod_ttv_imt" type="text"
                            class="form-control" />
                        </div>
                    </div>
                    -->

                    </div>
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Kesadaran GCS</h6>
                </div>
                <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                    <th>Pengkajian</th>
                    <th>Nilai</th>
                    </tr>
                </thead>
                

                <tbody>
                    <tr>
                    <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                    
                    <!-- ele pertama hardcode -->
                    <td>
                    <b-form-radio @input="isParent.inputData('response_mata')" v-model="isParent.row.auod_response_mata" name="auod_response_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                    </td>

                    </tr>
                    <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                        <tr v-if="k != 0" :key="k">
                        <td>
                            <b-form-radio @input="isParent.inputData('response_mata')"  v-model="isParent.row.auod_response_mata" name="auod_response_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                        </td>
                        </tr>
                    </template>
                </tbody>
                <tbody>
                    <tr>
                    <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                    
                    <td>
                    <b-form-radio @input="isParent.inputData('response_verbal')" v-model="isParent.row.auod_response_verbal" name="auod_response_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
                    </b-form-radio>
                    </td>
                    
                    </tr>
                    <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                        <tr v-if="k != 0" :key="k">
                        <td>
                            <b-form-radio @input="isParent.inputData('response_verbal')" v-model="isParent.row.auod_response_verbal" name="auod_response_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                        </td>
                        </tr>
                    </template>
                </tbody>
                <tbody>
                    <tr>
                    <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                    
                    <td>
                    <b-form-radio @input="isParent.inputData('response_motorik')" v-model="isParent.row.auod_response_motorik" name="auod_response_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                    </td>
                    
                    </tr>
                    <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                        <tr v-if="k != 0" :key="k">
                        <td>
                            <b-form-radio @input="isParent.inputData('response_motorik')"  v-model="isParent.row.auod_response_motorik" name="auod_response_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                        </td>
                        </tr>
                    </template>
                </tbody>
                <tfoot>
                    <tr>
                    <td class="table-info" colspan="9">
                        <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                        
                        <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                        <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                        <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                        <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                        <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                        <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                        <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                    </td>
                    </tr>
                </tfoot>


                </table>

                <VValidate 
                    name="Response Mata" 
                    v-if="isParent.stepTab != 2"
                    v-model="isParent.row.auod_response_mata" 
                    :rules="toValidate(isParent.mrValidation.auod_response_mata)"
                    class="d-block"
                />

                <VValidate 
                    name="Response Verbal" 
                    v-if="isParent.stepTab != 2"
                    v-model="isParent.row.auod_response_verbal"
                    :rules="toValidate(isParent.mrValidation.auod_response_verbal)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Response Motorik" 
                    v-if="isParent.stepTab != 2"
                    v-model="isParent.row.auod_response_motorik" 
                    :rules="toValidate(isParent.mrValidation.auod_response_motorik)"
                    class="d-block"
                />
            </div>
            
            <div class="row g-2">
                <div class="col-md-6" v-if="(isParent.row.ap_usia == null && isParent.row.ap_dob == null)">
                    <v-select 
                        :options="mActiveNyeri"
                        v-model="isParent.activeNyeri"
                        placeholder="Pilih Pengkajian Nyeri"
                        label="text"
                        @input="isParent.inputData('activeNyeri')" 
                    :clearable="true" :reduce="v=>v.value"
                    >
                    </v-select>
                    
                    <VValidate 
                        name="Kajian Nyeri" 
                        v-if="isParent.stepTab != 2"
                        v-model="isParent.activeNyeri" 
                        :rules="{required : 1}"
                    />
                </div>

                <div class="col-12" v-if="(isParent.row.ap_usia > 6 || (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.activeNyeri == 'NP'))">
                    <div class="card">
                        <div class="card-header bg-info">
                            <h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
                        </div>
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-md-auto">
                                    <label for="perPain">Skala Nyeri<strong class="text-danger">*</strong></label>
                                    <div>
                                        <div v-for="(v,k) in Config.mr.skalaNyeri" :key="k" :class="`pain-scale-radio ${v.class}`">
                                        <input type="radio" v-model="isParent.row.auod_skala_nyeri_value" name="perPainScale" @input="isParent.inputData('skala_nyeri_value')" :id="`perPainScale${v.text}`" :value="v.value" class="psr-input">
                                        <label :for="`perPainScale${v.text}`" class="psr-label">
                                            <span class="psr-label-content">{{v.text}}</span>
                                            <div class="psr-check-container"></div>
                                        </label>
                                        </div>
                                        
                                        <VValidate 
                                            name="Skala Nyeri" 
                                            v-if="isParent.stepTab != 2"
                                            v-model="isParent.row.auod_skala_nyeri_value" 
                                            :rules="{required : 1}"
                                        />
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="pain-scale no-pain" v-if="isParent.row.auod_skala_nyeri_value == 0">
                                        <img :src="assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                                        <strong>Tidak Ada Nyeri</strong>
                                    </div>
                                    <div class="pain-scale mild-pain" v-if="isParent.row.auod_skala_nyeri_value >= 1 && isParent.row.auod_skala_nyeri_value <= 3">
                                        <img :src="assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                                        <strong>Sedikit Nyeri</strong>
                                    </div>
                                    <div class="pain-scale moderate-pain" v-if="isParent.row.auod_skala_nyeri_value >= 4 && isParent.row.auod_skala_nyeri_value <= 5">
                                        <img :src="assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                                        <strong>Agak Mengganggu</strong>
                                    </div>
                                    <div class="pain-scale severe-pain" v-if="isParent.row.auod_skala_nyeri_value >= 6 && isParent.row.auod_skala_nyeri_value <= 7">
                                        <img :src="assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                                        <strong>Mengganggu Aktivitas</strong>
                                    </div>
                                    <div class="pain-scale worst-pain" v-if="isParent.row.auod_skala_nyeri_value >= 8 && isParent.row.auod_skala_nyeri_value <= 9">
                                        <img :src="assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                                        <strong>Sangat Mengganggu</strong>
                                    </div>
                                    <div class="pain-scale worst-pain" v-if="isParent.row.auod_skala_nyeri_value >= 10">
                                        <img :src="assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                                        <strong>Tak Tertahankan</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-12" v-if="(isParent.row.ap_usia <= 6) || (isParent.row.ap_usia == null && isParent.row.ap_dob == null)">
                    <div class="card">
                        <template v-if="(isParent.row.ap_usia > 1 || (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.activeNyeri == 'FL'))">
                        <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">FLACC Scale</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                            <label for="kidPainScale">Skala Nyeri</label>
                            <table class="table table-bordered pain-scale-table">
                                <thead>
                                <tr>
                                    <th>Pengkajian</th>
                                    <th>Nilai 0</th>
                                    <th>Nilai 1</th>
                                    <th>Nilai 2</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.row.auod_skala_nyeri||[])" :key="k">
                                    <th class="table-warning text-center"><img :src="assetLocal(v.img)" style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>{{v.name||"-"}}</th>
                                    <td v-for="(vc,kc) in v.configVal" :key="kc">
                                    <div class="form-check form-check-inline align-middle">
                                    <label class="form-check-label" :for="v.name+k+kc">
                                        <b-form-radio @input="isParent.inputData('skala_nyeri')" type="radio" :value="vc.value" v-model="isParent.row.auod_skala_nyeri[k]['value']" :id="v.name+k+kc" class="form-check-input-styled" />{{vc.text}}
                                    </label>
                                    </div>
                                    <br/>
                                    <VValidate 
                                        :name="v.name" 
                                        v-if="isParent.stepTab != 2"
                                        v-model="isParent.row.auod_skala_nyeri[k]['value']" 
                                        :rules="{required : 1}"
                                    />

                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr class="table-info">
                                    <td colspan="4" class="text-info-700">
                                    <span>SKALA: {{isParent.hitungSkala}} </span>
                                    
                                    <span v-if="isParent.hitungSkala == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                    <span v-else-if="isParent.hitungSkala >= 1 && isParent.hitungSkala < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                    <span v-else-if="isParent.hitungSkala >= 4 && isParent.hitungSkala < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            </div>
                        </div>
                        </template>

                        <template v-if="((isParent.row.ap_usia <= 1  && isParent.row.ap_usia != null)|| (isParent.row.ap_usia == null && isParent.row.ap_dob == null && isParent.activeNyeri == 'NIPS')) ">
                        <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Neonatal-Infant Pain Scale (NIPS)</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                            <label for="kidPainScale">Skala Nyeri</label>
                            <table class="table table-bordered pain-scale-table">
                                <thead>
                                <tr>
                                    <th>Pengkajian</th>
                                    <th>Nilai 0</th>
                                    <th>Nilai 1</th>
                                    <th>Nilai 2</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.row.auod_skala_nyeri_nips||[])" :key="k">
                                    <th class="table-warning text-center"><img :src="assetLocal(v.img)" style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>{{v.name||"-"}}</th>
                                    <td v-for="(vc,kc) in v.configVal" :key="kc">
                                    <div class="form-check form-check-inline align-middle">
                                    <label class="form-check-label" :for="v.name+k+kc">
                                        <b-form-radio @input="isParent.inputData('skala_nyeri_nips')" type="radio" :value="vc.value" v-model="isParent.row.auod_skala_nyeri_nips[k]['value']" :id="v.name+k+kc" class="form-check-input-styled" />{{vc.text}}
                                    </label>
                                    </div>
                                    <br/>
                                    <VValidate 
                                        :name="v.name" 
                                        v-if="isParent.stepTab != 2"
                                        v-model="isParent.row.auod_skala_nyeri_nips[k]['value']" 
                                        :rules="{required : 1}"
                                    />
                                    
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr class="table-info">
                                    <td colspan="4" class="text-info-700">
                                    <span>SKALA: {{isParent.hitungSkala}} </span>
                                    
                                    <span v-if="isParent.hitungSkala < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                    <span v-else-if="isParent.hitungSkala >= 3 && isParent.hitungSkala < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            </div>
                            
                            <span class="text-warning d-none" id="skala_nyeri_nips-typing">User Lain Sedang Mengisi ...</span>                                                                                   
                        </div>  
                        </template>     
                        
                    </div>
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
                </div>
                <div class="card-body p-3">
                <div class="alert alert-info">Silakan pilih/ceklis bagian yang memiliki kelainan</div>
                    <div class="card border shadow-0">
                        <div class="bg-light card-header c-pointer"
                        @click="pemeriksaanFisik = !pemeriksaanFisik"
                        :class="pemeriksaanFisik ? null : 'collapsed'"
                        :aria-expanded="pemeriksaanFisik ? 'true' : 'false'"
                        aria-controls="GenPartRow">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="card-title font-weight-semibold">Klik untuk
                            {{ pemeriksaanFisik ? "Menyembunyikan" : "Menampilkan" }} Bagian Keadaan
                            Umum</span>
                            <i class="icon-arrow-down22" style="font-size: 20px;"></i>
                        </div>
                        </div>
                    <div>
                    
                    <b-collapse id="GenPartRow" v-model="pemeriksaanFisik">
                        <div class="row g-0">
                            <div class="col-md-6" v-for="(genCol, genColKey) in oddEvenGenCondition"
                            :key="genColKey+'genCol'">
                            <div class="gen-part" v-for="(vs,ks) in genCol" :key="ks+'sign'">
                                <div
                                class="d-flex align-items-center justify-content-between gen-part-head c-pointer"
                                @click="isParent.row[vs.value+'isOpen'] = !isParent.row[vs.value+'isOpen']"
                                :class="isParent.row[vs.value+'isOpen'] ? null : 'collapsed'">
                                <div class="font-weight-semibold">{{vs.label}}
                                    <span class="text-danger" v-if="isParent.row[vs.value] == 'TN'">*</span>
                                </div>
                                <i class="icon-arrow-down22"></i>
                                </div>

                                <b-collapse :id="vs.value" v-model="isParent.row[vs.value+'isOpen']"
                                accordion="accKeadaanUmum">
                                <div class="gen-part-body mt-1">
                                    <div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" v-model="isParent.row[vs.value]"
                                        @input="changeVital(vs.value,'N')"
                                        class="custom-control-input" :name="`formType${vs.label}`"
                                        :id="`normalCheck${vs.label}`" value="N">
                                        <label class="custom-control-label"
                                        :for="`normalCheck${vs.label}`">
                                        Normal
                                        </label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" v-model="isParent.row[vs.value]"
                                        @input="changeVital(vs.value,'TN')"
                                        class="custom-control-input" :name="`formType${vs.label}`"
                                        :id="`notNormalCheck${vs.label}`" value="TN">
                                        <label class="custom-control-label"
                                        :for="`notNormalCheck${vs.label}`">
                                        Tidak Normal
                                        </label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" v-model="isParent.row[vs.value]"
                                        class="custom-control-input" :name="`formType${vs.label}`"
                                        :id="`notCheck${vs.label}`" value="TP">
                                        <label class="custom-control-label"
                                        :for="`notCheck${vs.label}`">
                                        Tidak Diperiksa
                                        </label>
                                    </div>
                                    </div>
                                    <div class="gen-input mt-2" v-if="isParent.row[vs.value] !== 'TP'">
                                            <template v-if="vs.value == 'auod_vs_saraf' && isParent.row[vs.value] == 'TN'">
                                                <div class="gen-input-saraf" data-select2-id="162">
                                                <div class="mb-2">
                                                    <label for="refleksFisiologis">Refleks Fisiologis</label>
                                                    <b-textarea
                                                    v-model="isParent.row.auod_vs_saraf_r_fisiologis" class="form-control"
                                                    rows="2"></b-textarea>
                                                </div>
                                                <div class="mb-2">
                                                    <label for="refleksPatologis">Refleks Patologis</label>
                                                    <b-textarea
                                                    v-model="isParent.row.auod_vs_saraf_r_patologis" class="form-control"
                                                    rows="2"></b-textarea>
                                                </div>

                                                <div class="row g-1">
                                                    
                                                    <div class="col-md-6">
                                                        <label>Babinski</label>
                                                        <v-select placeholder="Status" 
                                                        v-model="isParent.row.auod_vs_saraf_babinski" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <label>Lasegue</label>
                                                        <v-select placeholder="Status" 
                                                        v-model="isParent.row.auod_vs_saraf_lasegue" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>
                                                    
                                                    <div class="col-md-6">
                                                        <label>Bregard</label>
                                                        <v-select placeholder="Status" 
                                                        v-model="isParent.row.auod_vs_saraf_bregard" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>
                                                    
                                                    <div class="col-md-6">
                                                        <label>Sicard</label>
                                                        <v-select placeholder="Status" 
                                                        v-model="isParent.row.auod_vs_saraf_sicard" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>
                                                    
                                                    <div class="col-md-6">
                                                        <label>Kaku Duduk</label>
                                                        <v-select placeholder="Status" 
                                                        v-model="isParent.row.auod_vs_saraf_kaku_duduk" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <label>Kernig Sign</label>
                                                        <v-select placeholder="Status" 
                                                        v-model="isParent.row.auod_vs_saraf_kernig_sign" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <label>Brudzinski 1</label>
                                                        <v-select placeholder="Status" 
                                                        v-model="isParent.row.auod_vs_saraf_brudzinski" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>
                                                    
                                                    <div class="col-md-6">
                                                        <label>Brudzinski 2</label>
                                                        <v-select placeholder="Status" 
                                                        v-model="isParent.row.auod_vs_saraf_brudzinski2" :options="isParent.Config.mr.positifNegatifOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>

                                                </div>
                                                
                                                </div>
                                            </template>
                                            <template v-else>
                                                <b-textarea
                                                    v-model="isParent.row[vs.value+'_text']" class="form-control"
                                                    :placeholder="`cth. pembengkakan pada bagian ${vs.label} sebelah kiri`"
                                                    rows="2"></b-textarea>

                                                <VValidate :name="`${vs.label}`" v-model="isParent.row[vs.value+'_text']"
                                                    :rules="{required:1, min:2, max:512}" />
                                            </template>
                                    </div>
                                </div>
                                </b-collapse>
                            </div>
                            </div>
                        </div>
                    </b-collapse>
                    
                    </div>
                </div>
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Kajian Primer</h6>
                </div>
                <div class="card-body p-3">
                <div class="row g-0">
                    <div class="col-md-6">
                    <div class="gen-part">
                        

                        <div class="d-flex align-items-center justify-content-between gen-part-head"
                            @click="airwaysOpen = !airwaysOpen"
                            :class="airwaysOpen ? null : 'collapsed'"
                            :aria-expanded="airwaysOpen ? 'true' : 'false'"
                            aria-controls="airwaysOpen"
                            >
                            <div class="font-weight-semibold">Airways</div>
                            <i class="icon-arrow-down22"></i>
                        </div>
                        <b-collapse :id="'airwaysOpen'" v-model="airwaysOpen"
                            accordion="airwaysOpen">
                            <div class="gen-part-body mt-2">
                                <div>                    
                                    <b-form-radio-group
                                        :options="isParent.Config.mr.bebasTidakOpt"
                                        v-model="isParent.row.auod_airways_val"
                                    />
                                    <VValidate name="Airways" v-model="isParent.row.auod_airways_val"
                                        :rules="toValidate(isParent.mrValidation.auod_airways_val)" />
                                </div>
                            
                                <div class="gen-input mt-3">
                                <div v-if="isParent.row.auod_airways_val == 'TB'">
                                    <label for="">Ketidaknormalan</label>
                                    <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_airways_pangkal_lidah" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Pangkal Lidah Jatuh
                                        </label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_airways_sputum" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Sputum
                                        </label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_airways_darah" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Darah
                                        </label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_airways_spasme" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Spasme
                                        </label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_airways_benda_asing" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Benda Asing
                                        </label>
                                    </div>
                                    </div>
                                </div>
                                
                                <div class="form-row" v-if="isParent.row.auod_airways_val == 'TB'">

                                    <div class="form-group col-md-6">
                                    <label>Suara Napas<small class="txt_mandatory">*</small></label>

                                        <v-select placeholder="Suara Napas" 
                                        v-if="isParent.row.auod_airways_val == 'TB'"
                                        v-model="isParent.row.auod_airways_suara_napas" :options="isParent.Config.mr.suaraNapasOpt" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                        <VValidate name="Suara Napas" v-model="isParent.row.auod_airways_suara_napas"
                                         :rules="toValidate(isParent.mrValidation.auod_airways_suara_napas)" />

                                    </div>
                                    
                                    <div class="form-group col-md-12" v-if="isParent.row.auod_airways_suara_napas == 'L'">
                                        <textarea class="form-control" v-model="isParent.row.auod_airways_suara_napas_text" placeholder="Tuliskan" rows="2"></textarea>
                                        
                                        <VValidate name="Suara Napas Lainnya" v-model="isParent.row.auod_airways_suara_napas_text"
                                         :rules="toValidate(isParent.mrValidation.auod_airways_suara_napas_text)" />
                                        
                                    </div>
                                </div>
                                </div>
                            </div>
                        </b-collapse>
                        
                    </div>

                    <div class="gen-part">
                        <div class="d-flex align-items-center justify-content-between gen-part-head"
                            @click="circulationOpen = !circulationOpen"
                            :class="circulationOpen ? null : 'collapsed'"
                            :aria-expanded="circulationOpen ? 'true' : 'false'"
                            aria-controls="circulationOpen"
                            >
                            <div class="font-weight-semibold">Circulation</div>
                            <i class="icon-arrow-down22"></i>
                        </div>

                        <b-collapse :id="'circulationOpen'" v-model="circulationOpen"
                            accordion="circulationOpen">
                            <div class="gen-part-body mt-2">
                                <div>

                                <b-form-radio-group
                                    :options="isParent.Config.mr.normalTidakNormalOpt"
                                    v-model="isParent.row.auod_circulation_val"
                                />
                                <VValidate name="Circulation" v-model="isParent.row.auod_circulation_val"
                                    :rules="toValidate(isParent.mrValidation.auod_circulation_val)" />

                                </div>
                                <div class="gen-input mt-2" v-if="isParent.row.auod_circulation_val == 'TN'">
                                <div class="form-group">
                                    <label for="">Ketidaknormalan</label>
                                    <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_akral_dingin" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Akral Dingin
                                        </label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_pucat" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Pucat
                                        </label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_sianosis" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Sianosis
                                        </label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_kulit_kering" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Kulit Kering
                                        </label>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_turgor_kulit_kurang" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Tugor Kulit Kurang
                                        </label>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_pendarahan" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Pendarahan
                                        </label>

                                        <template v-if="isParent.row.auod_circulation_pendarahan == 'Y'">
                                            <textarea v-model="isParent.row.auod_circulation_pendarahan_text" rows="2" class="form-control mt-2"></textarea>
                                                                
                                            <VValidate name="Pendarahan Lainnya" v-model="isParent.row.auod_circulation_pendarahan_text"
                                            :rules="toValidate(isParent.mrValidation.auod_circulation_pendarahan_text)" />
                                        </template>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_lainnya" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Lain-lain
                                        </label>
                                        
                                        <template v-if="isParent.row.auod_circulation_lainnya == 'Y'">
                                            <textarea v-model="isParent.row.auod_circulation_lainnya_text" rows="2" class="form-control mt-2"></textarea>
                                                                
                                            <VValidate name="Pendarahan Lainnya" v-model="isParent.row.auod_circulation_lainnya_text"
                                            :rules="toValidate(isParent.mrValidation.auod_circulation_lainnya_text)" />
                                        </template>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_pengisian_kapiler" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Pengisian Kapiler
                                        </label>
                                        
                                        <div v-if="isParent.row.auod_circulation_pengisian_kapiler == 'Y'">
                                            <v-select placeholder="Pilih Label" 
                                            class="mt-2" v-model="isParent.row.auod_circulation_pengisian_kapiler_text"
                                            :options="Config.mr.nadikapilerOpt" label="text"
                                            :clearable="true" :reduce="v=>v.value"></v-select>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12">
                                        
                                        <label class="form-check-label d-flex">
                                            <b-form-checkbox value="Y" :unchecked-value="'N'" v-model="isParent.row.auod_circulation_nadi" class="form-check-input-styled"
                                                name="radio-inline-left" />
                                            Nadi
                                        </label>

                                        <div class="form-row mt-2" v-if="isParent.row.auod_circulation_nadi == 'Y'">
                                            <div class="col-md-7">
                                                <div class="input-group">
                                                    <b-form-input style="max-width: 85px;" v-model="isParent.row.auod_circulation_nadi_text" :formatter="numberOnly" type="text" class="form-control" />
                                                    <div class="input-group-append"><span class="input-group-text">x/mnt</span>
                                                    </div>
                                                    <div class="input-group-append">
                                                        <div style="width: 150px;">
                                                            <v-select placeholder="Pilih Label" v-model="isParent.row.auod_circulation_nadi_label"
                                                            :options="Config.mr.StatusRegular" label="text"
                                                            :clearable="true" :reduce="v=>v.value"></v-select>
                                                        </div>
                                                    </div>
                                                </div>
                                                                        
                                                <VValidate name="Nilai Nadi" v-model="isParent.row.auod_circulation_nadi_text"
                                                    :rules="toValidate(isParent.mrValidation.auod_circulation_nadi_text)" />
                                                <VValidate name="Nilai Nadi Label"  v-model="isParent.row.auod_circulation_nadi_label"
                                                    :rules="toValidate(isParent.mrValidation.auod_circulation_nadi_label)" />
                                            </div>
                                            <div class="col-md-5">
                                                <v-select placeholder="Pilih Label" v-model="isParent.row.auod_circulation_nadi_status"
                                                :options="Config.mr.kuatLemahOpt" label="text"
                                                :clearable="true" :reduce="v=>v.value"></v-select>

                                                <VValidate name="Denyut Nadi"  v-model="isParent.row.auod_circulation_nadi_status"
                                                :rules="toValidate(isParent.mrValidation.auod_circulation_nadi_status)" />
                                            </div>
                                        </div>
                                    </div>


                                    </div>
                                </div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>

                    <div class="gen-part">
                        <div class="d-flex align-items-center justify-content-between gen-part-head"
                            @click="envOpen = !envOpen"
                            :class="envOpen ? null : 'collapsed'"
                            :aria-expanded="envOpen ? 'true' : 'false'"
                            aria-controls="envOpen"
                            >
                            <div class="font-weight-semibold">Environment</div>
                            <i class="icon-arrow-down22"></i>
                        </div>

                        <b-collapse :id="'envOpen'" v-model="envOpen"
                            accordion="envOpen">
                            <div class="gen-part-body mt-2">
                                
                                <div class="gen-input">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>Adanya trauma pada daerah</label>
                                        <textarea v-model="isParent.row.auod_env_trauma" class="form-control" placeholder="Tuliskan" rows="2"></textarea>
                                        
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>Adanya jejas/luka pada daerah</label>
                                        <textarea v-model="isParent.row.auod_env_jelas_luka" class="form-control" placeholder="Tuliskan" rows="2"></textarea>
                                        
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>Ukuran luka</label>
                                        <textarea v-model="isParent.row.auod_env_ukuran_luka" class="form-control" placeholder="Tuliskan" rows="2"></textarea>
                                        
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>Kedalaman Luka</label>
                                        <textarea v-model="isParent.row.auod_env_kedalaman_luka" class="form-control" placeholder="Tuliskan" rows="2"></textarea>
                                       
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>Lain-Lain</label>
                                        <textarea v-model="isParent.row.auod_env_lainnya" class="form-control" placeholder="Tuliskan" rows="2"></textarea>
                                        
                                    </div>
                                </div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="gen-part">
                        <div class="d-flex align-items-center justify-content-between gen-part-head"
                            @click="breathingOpen = !breathingOpen"
                            :class="breathingOpen ? null : 'collapsed'"
                            :aria-expanded="breathingOpen ? 'true' : 'false'"
                            aria-controls="breathingOpen"
                            >
                            <div class="font-weight-semibold">Breathing</div>
                            <i class="icon-arrow-down22"></i>
                        </div>

                        <b-collapse :id="'envOpen'" v-model="breathingOpen">
                            <div class="gen-part-body mt-2">
                                <div>
                                <b-form-radio-group
                                    :options="isParent.Config.mr.normalTidakNormalOpt"
                                    v-model="isParent.row.auod_breathing_val"
                                />
                                <VValidate name="Breathing" v-model="isParent.row.auod_breathing_val"
                                    :rules="toValidate(isParent.mrValidation.auod_breathing_val)" />

                                </div>
                                <div class="gen-input mt-3" v-if="isParent.row.auod_breathing_val == 'TN'">
                                <div class="form-row">
                                    <div class="form-group col-md-5">
                                    <label>Pola Napas<small class="txt_mandatory">*</small></label>
                                    
                                    <v-select placeholder="Pilih Pola Napas" v-model="isParent.row.auod_breathing_pola_napas"
                                    :options="Config.mr.polaNapasOpt" label="text"
                                    :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                    <VValidate v-if="isParent.row.auod_breathing_val == 'Y'" name="Pola Napas" v-model="isParent.row.auod_breathing_pola_napas"
                                    :rules="toValidate(isParent.mrValidation.auod_breathing_pola_napas)" />

                                    </div>
                                    <div class="form-group col-md-7">
                                    <label>Frekuensi Napas<small class="txt_mandatory">*</small></label>
                                    <div class="input-group">
                                        <b-form-input :formatter="isParent.number" v-model="isParent.row.auod_breathing_frekuensi"
                                        type="text" class="form-control" />
                                        <div class="input-group-append">
                                        <span class="input-group-text">x/mnt</span>
                                        </div>
                                        <div class="input-group-append">
                                        <div style="width: 140px;">
                                            <v-select placeholder="Pilih Label" v-model="isParent.row.auod_breathing_label"
                                            :options="Config.mr.StatusRegular" label="text"
                                            :clearable="true" :reduce="v=>v.value"></v-select>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                    <label>Bunyi Nafas<small class="txt_mandatory">*</small></label>

                                    <v-select multiple placeholder="Pilih Pola Napas" 
                                    class="vs__multiple"
                                    v-model="isParent.row.auod_breathing_bunyi_nafas"
                                    :options="Config.mr.bunyiNapasOpt" label="text"
                                    :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                    <VValidate v-if="isParent.row.auod_breathing_val == 'Y'" name="Bunyi Napas" v-model="isParent.row.auod_breathing_bunyi_nafas"
                                    :rules="toValidate(isParent.mrValidation.auod_breathing_bunyi_nafas)" />

                                    </div>
                                    <div class="form-group col-md-6">
                                    <label>Tanda distress pernafasan<small class="txt_mandatory">*</small></label>

                                    <v-select multiple placeholder="Tanda distress pernafasan" 
                                    class="vs__multiple"
                                    v-model="isParent.row.auod_breathing_tanda_napas"
                                    :options="Config.mr.tandaDistressOpt" label="text"
                                    :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                    <VValidate v-if="isParent.row.auod_breathing_val == 'Y'" name="Tanda distress pernapasan" v-model="isParent.row.auod_breathing_tanda_napas"
                                    :rules="toValidate(isParent.mrValidation.auod_breathing_tanda_napas)" />

                                    </div>
                                    <div class="form-group col-md-6">
                                    <label>Jenis pernapasan<small class="txt_mandatory">*</small></label>
                                    
                                    <v-select placeholder="Tanda distress pernapasan" v-model="isParent.row.auod_breathing_jenis_nafas"
                                    :options="Config.mr.jenisNapasOpt" label="text"
                                    :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                    <VValidate v-if="isParent.row.auod_breathing_val == 'Y'" name="Jenis pernapasan" v-model="isParent.row.auod_breathing_jenis_nafas"
                                    :rules="toValidate(isParent.mrValidation.auod_breathing_jenis_nafas)" />

                                    </div>
                                    <div class="form-group col-md-12" v-if="isParent.row.auod_breathing_jenis_nafas == 'L'">
                                        <textarea class="form-control" v-model="isParent.row.auod_breathing_jenis_nafas_text" placeholder="Tuliskan" rows="2"></textarea>
                                        
                                        <VValidate name="Jenis Napas lainnya" v-model="isParent.row.auod_breathing_jenis_nafas_text"
                                         :rules="toValidate(isParent.mrValidation.auod_breathing_jenis_nafas_text)" />
                                        
                                    </div>

                                </div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>

                    <div class="gen-part">
                        <div class="d-flex align-items-center justify-content-between gen-part-head"
                            @click="disabilityOpen = !disabilityOpen"
                            :class="disabilityOpen ? null : 'collapsed'"
                            :aria-expanded="disabilityOpen ? 'true' : 'false'"
                            aria-controls="disabilityOpen"
                            >
                            <div class="font-weight-semibold">Disability</div>
                            <i class="icon-arrow-down22"></i>
                        </div>

                        <b-collapse :id="'envOpen'" v-model="disabilityOpen">
                            <div class="gen-part-body mt-2">
                                <div>
                                <b-form-radio-group
                                    :options="isParent.Config.mr.normalTidakNormalOpt"
                                    v-model="isParent.row.auod_disability_val"
                                />
                                <VValidate name="Disability" v-model="isParent.row.auod_disability_val"
                                    :rules="toValidate(isParent.mrValidation.auod_disability_val)" />
                                </div>
                                <div class="gen-input mt-3">
                                <div class="form-row" v-if="isParent.row.auod_disability_val == 'TN'">
                                    <div class="form-group col-md-6">
                                    <label>Disability Tidak Normal<small class="txt_mandatory">*</small></label>
                                    <v-select placeholder="Pilih Label" v-model="isParent.row.auod_disability_tidak_normal"
                                    :options="Config.mr.disabilty" label="text"
                                    :clearable="true" :reduce="v=>v.value"></v-select>
                                    
                                    <VValidate name="Disability Tidak Normal" v-model="isParent.row.auod_disability_tidak_normal"
                                         :rules="toValidate(isParent.mrValidation.auod_disability_tidak_normal)" />
                                    </div>
                                </div>
                                <hr>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                    <label>Kekuatan Tangan Kanan<small class="txt_mandatory">*</small></label>
                                    
                                    <b-form-radio-group
                                        class="mb-1"
                                        :options="isParent.Config.mr.nilaiKekuatan" text-field="value"
                                        v-model="isParent.row.auod_disability_tangan_kanan_val"
                                    />
                                    <span>{{getConfigDynamic(isParent.Config.mr.nilaiKekuatan,isParent.row.auod_disability_tangan_kanan_val)||"-"}}</span>

                                    <!--
                                    <textarea class="form-control" v-model="isParent.row.auod_disability_tangan_kanan" placeholder="Kekuatan Tangan Kanan" rows="2"></textarea>
                                    -->
                                    <VValidate name="Kekuatan Tangan Kanan" v-model="isParent.row.auod_disability_tangan_kanan_val"
                                        :rules="{required: 1}" />
                                    <!--
                                    <VValidate name="Kekuatan Tangan Kanan" v-model="isParent.row.auod_disability_tangan_kanan"
                                        :rules="toValidate(isParent.mrValidation.auod_disability_tangan_kanan)" />
                                    -->
                                    
                                    </div>

                                    <div class="form-group col-md-12">
                                    <label>Kekuatan Tangan Kiri<small class="txt_mandatory">*</small></label>
                                    
                                    <b-form-radio-group
                                        class="mb-1"
                                        :options="isParent.Config.mr.nilaiKekuatan" text-field="value"
                                        v-model="isParent.row.auod_disability_tangan_kiri_val"
                                    />
                                    <span>{{getConfigDynamic(isParent.Config.mr.nilaiKekuatan,isParent.row.auod_disability_tangan_kiri_val)||"-"}}</span>

                                    <!--                                    
                                    <textarea class="form-control" v-model="isParent.row.auod_disability_tangan_kiri" placeholder="Kekuatan Tangan Kiri" rows="2"></textarea>
                                    -->

                                    <VValidate name="Kekuatan Tangan Kiri" v-model="isParent.row.auod_disability_tangan_kiri_val"
                                        :rules="{required: 1}" />
                                    
                                    <!--
                                    <VValidate name="Kekuatan Tangan Kiri" v-model="isParent.row.auod_disability_tangan_kiri"
                                        :rules="toValidate(isParent.mrValidation.auod_disability_tangan_kiri)" />
                                    -->

                                    </div>

                                    <div class="form-group col-md-12">
                                    <label>Kekuatan Kaki Kanan<small class="txt_mandatory">*</small></label>
                                    
                                    <b-form-radio-group
                                        class="mb-1"
                                        :options="isParent.Config.mr.nilaiKekuatan" text-field="value"
                                        v-model="isParent.row.auod_disability_kaki_kanan_val"
                                    />
                                    <span>{{getConfigDynamic(isParent.Config.mr.nilaiKekuatan,isParent.row.auod_disability_kaki_kanan_val)||"-"}}</span>

                                    <!--
                                    <textarea class="form-control" v-model="isParent.row.auod_disability_kaki_kanan" placeholder="Kekuatan Kaki Kanan" rows="2"></textarea>
                                    -->
                                    
                                    <VValidate name="Kekuatan Kaki Kanan" v-model="isParent.row.auod_disability_kaki_kanan_val"
                                        :rules="{required: 1}" />
                                    
                                    <!--
                                    <VValidate name="Kekuatan Kaki Kanan" v-model="isParent.row.auod_disability_kaki_kanan"
                                        :rules="toValidate(isParent.mrValidation.auod_disability_kaki_kanan)" />
                                    -->

                                    </div>

                                    <div class="form-group col-md-12">
                                    <label>Kekuatan Kaki Kiri<small class="txt_mandatory">*</small></label>
                                    
                                    <b-form-radio-group
                                        class="mb-1"
                                        :options="isParent.Config.mr.nilaiKekuatan" text-field="value"
                                        v-model="isParent.row.auod_disability_kaki_kiri_val"
                                    />
                                    <span>{{getConfigDynamic(isParent.Config.mr.nilaiKekuatan,isParent.row.auod_disability_kaki_kiri_val)||"-"}}</span>

                                    <!--
                                    <textarea class="form-control" v-model="isParent.row.auod_disability_kaki_kiri" placeholder="Kekuatan Kaki Kiri" rows="2"></textarea>
                                    -->

                                    <VValidate name="Kekuatan Kaki Kiri" v-model="isParent.row.auod_disability_kaki_kiri_val"
                                        :rules="{required: 1}" />

                                    <!--
                                    <VValidate name="Kekuatan Kaki Kiri" v-model="isParent.row.auod_disability_kaki_kiri"
                                        :rules="toValidate(isParent.mrValidation.auod_disability_kaki_kiri)" />
                                    -->

                                    </div>
                                </div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    </div>
                </div>

                </div>
            </div>
            <!-- Status LOkalis -->


            
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
            </div>
            <div class="card-body p-3 pb-5">
                <div class="row gx-2">
                <div class="col-lg-4">
                    <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                    <li class="nav-item" @click="changeTabAnat(k)"
                        v-for="(v,k) in isParent.row.auod_lokasi_nyeri||[]" :key="k+'nav'">
                        <a href="javascript:;" data-toggle="tab"
                        :class="activeAnat == k ? 'nav-link active':'nav-link'">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{{v.name||"(Anatomi Baru)"}}</span>
                            <div>
                            <i class="icon-chevron-right"></i>
                            </div>
                        </div>
                        </a>
                    </li>
                    <li class="nav-item border-top">
                        <a href="javascript:;" @click="addAnatomi"
                        class="nav-link border-top bg-light">
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="icon-plus2 mr-2"></i>Tambah Anatomi</span>
                        </div>
                        </a>
                    </li>
                    <li class="nav-item border-top">
                        <a href="javascript:;" @click="deleteAnatomi(activeAnat)"
                        class="nav-link border-top bg-light">
                        <div class="d-flex justify-content-between align-items-center">
                            <span><i class="icon-trash mr-2"></i>Hapus Anatomi</span>
                        </div>
                        </a>
                    </li>
                    </ul>
                </div>
                <div class="col-lg-8">
                    <div class="tab-content">
                    <div v-for="(v,k) in isParent.row.auod_lokasi_nyeri||[]" :key="k+'navAnat'"
                        :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                        <div class="form-group">
                        <label for="">Jenis Anatomi<strong
                            class="text-danger">*</strong></label>
                        <div class="input-group flex-nowrap">
                            <v-select class="flex-fill" placeholder="Pilih Status Lokalis"
                            @input="selectStatusLokalis($event,k)" v-model="v.type"
                            :disabled="!v.isEdit"
                            :options="getStatusLokalis(isParent.row.auod_lokasi_nyeri,k)" label="text"
                            :reduce="v=>v.value">
                            </v-select>

                            <div class="input-group-append">
                            <button @click="editAnatomi(k)" type="button"
                                class="btn btn-info btn-labeled btn-labeled-left">
                                <b><i class="icon-pencil"></i></b>
                                Edit
                            </button>
                            </div>

                            <div class="input-group-append">
                            <button @click="saveAnatomi(k)" type="button"
                                class="btn btn-success btn-labeled btn-labeled-left">
                                <b><i class="icon-checkmark"></i></b>
                                Simpan
                            </button>
                            </div>
                        </div>
                        </div>

                        <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                        <div class="position-relative" :id="`anatomiNyeri${k}`"
                            @click.stop.prevent>
                            <div class="body_wrapper">
                            <img :src="assetLocal(v.image)" class="img-fluid w-100"
                                alt="">
                            </div>
                            <div class="anatomy-info" v-for="(v1,k1) in v.data||[]"
                            :key="k1+'body'" :style="`top:${v1.y}%;left:${v1.x}%`">
                            <a href="javascript:;" @click="openAnat(k1,k)" class="ai-point"
                                v-b-tooltip.hover :html="true" :title="v1.value"></a>
                                
                            <div class="anatomy-info-input" :class="{
                        'd-block': v1.isOpen,
                        'reversed': v1.isReversed
                        }" :style="v1.isReversed ? 'transform: translateX('+v1.reverseCount+')' : ''"
                                @click.stop.prevent>
                                <textarea v-model="v1.value" name="anatomyInput" id="anatomyInput"
                                rows="2" class="form-control form-control-sm"></textarea>
                                <small class="text-muted"><i class="icon-info22"></i> Keluhan
                                harus diisi</small>

                                <div class="mt-2 text-right">
                                <a href="javascript:;" class="btn btn-sm btn-danger"
                                    @click.prevent.stop="removeAnat(k1,k)">Hapus</a>
                                <a href="javascript:;" @click.prevent.stop="closeAnat(k1,k)"
                                    class="btn btn-sm btn-success">Simpan</a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <span v-if="!(isParent.row.auod_lokasi_nyeri||[]).length">Belum Ada Data Anatomi
                        (Klik Tambah Untuk Menambahkan)</span>
                    </div>
                </div>
                </div>
            </div>
            </div>

            </div>
        </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')


export default{
    extends: GlobalVue,
    // components:{ 
    //     Datepicker
    // },

    data(){
        return {
            pemeriksaanFisik : false,
            airwaysOpen : false,
            circulationOpen : false,
            envOpen: false,
            breathingOpen: false,
            disabilityOpen: false,
            activeAnat: 0,
            
            mActiveNyeri: [
                {
                    text: 'Numeric Pain Rating Scale',
                    value: 'NP' 
                },
                {
                    text: 'FLACC Scale',
                    value: 'FL' 
                },
                {
                    text: 'Neonatal-Infant Pain Scale (NIPS)',
                    value: 'NIPS' 
                },
            ]
        
        }
    },

    mounted() {
      document.body.classList.add('sidebar-xs')
      setTimeout(() => {
        this.setPosisionNyeri()
      }, 1500)
    },
    
    methods: {
        toValidate(val) {
            return {
            ...val
            }
        },
        
        changeVital(field, n) {
            if (n == 'N') {
                this.isParent.row[field + '_text'] = 'TAK'
            } else {
                this.isParent.row[field + '_text'] = ''
            }
        },
      
        hitungBMI(){
            if(this.isParent.row.auod_ttv_weight && this.isParent.row.auod_ttv_height){
                this.isParent.row.auod_ttv_bmi = this.isParent.row.auod_ttv_weight/ ((this.isParent.row.auod_ttv_height/100)*(this.isParent.row.auod_ttv_height/100))
                this.isParent.row.auod_ttv_bmi = this.isParent.row.auod_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.row.auod_ttv_bmi = null
            }
        },

        
        editAnatomi(k) {
            this.isParent.row.auod_lokasi_nyeri[k]['isEdit'] = true
        },
        saveAnatomi(k) {
            this.isParent.row.auod_lokasi_nyeri[k]['isEdit'] = false
        },
        changeTabAnat(k, isAdd = false) {
            this.activeAnat = k
            setTimeout(() => {
            if (isAdd) {
                this.setPosisionNyeri()
            }
            }, 100)
        },
        deleteAnatomi(key) {
            this.isParent.row.auod_lokasi_nyeri.splice(key, 1)
            this.activeAnat = (this.isParent.row.auod_lokasi_nyeri || []).length - 1
        },
        addAnatomi() {
            this.isParent.row.auod_lokasi_nyeri = this.isParent.row.auod_lokasi_nyeri ? this.isParent.row.auod_lokasi_nyeri : []
            this.isParent.row.auod_lokasi_nyeri.push({
                name: '',
                type: null,
                image: '',
                isEdit: true,
                data: [],
            })
            console.log("add anat")
            this.activeAnat = (this.isParent.row.auod_lokasi_nyeri || []).length - 1
        },
        selectStatusLokalis(e, k) {
            let idx = this.isParent.Config.mr.configStatusLokalis.findIndex(x => x.value == e)
            if (idx !== -1) {
                let data = this.isParent.Config.mr.configStatusLokalis[idx]
                this.isParent.row.auod_lokasi_nyeri[k]['name'] = data.text
                if (this.isParent.row.ap_gender == 2) {
                    this.isParent.row.auod_lokasi_nyeri[k]['image'] = data.image2
                } else {
                    this.isParent.row.auod_lokasi_nyeri[k]['image'] = data.image
                }
            }

            this.isParent.row.auod_lokasi_nyeri[this.activeAnat]['data'] = []

            if (!$("#anatomiNyeri" + this.activeAnat).length) {
                this.changeTabAnat(k, true)
            } else {
                this.changeTabAnat(k)
            }
        },

        getStatusLokalis(data, k) {
            const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
            return k2 != k
            }), v2 => {
            return v2.type
            })
            return _.filter(this.isParent.Config.mr.configStatusLokalis, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
            })
        },

        removeAnat(k, kp) {
            this.isParent.row.auod_lokasi_nyeri[kp]['data'].splice(k, 1)
        },
        closeAnat(k, kp) {
            if (this.isParent.row.auod_lokasi_nyeri[kp]['data'][k]['value']) {
            this.isParent.row.auod_lokasi_nyeri[kp]['data'][k]['isOpen'] = false
            this.isParent.row.auod_lokasi_nyeri[kp]['data'][k]['y'] += (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
            }
        },
        openAnat(k, kp) {
            this.isParent.row.auod_lokasi_nyeri[kp]['data'][k]['y'] -= (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
            this.isParent.row.auod_lokasi_nyeri[kp]['data'][k]['isOpen'] = true
        },
        setPosisionNyeri() {
            let posX = false
            let posY = false
            let self = this

            $("body").mousemove(function (e) {
            if (!$("body").hasClass("active")) $("body").addClass("active")
            if ($("#anatomiNyeri" + self.activeAnat).length) {
                posX = parseInt(e.pageX - ($("#anatomiNyeri" + self.activeAnat).offset().left + 8))
                posY = parseInt(e.pageY - ($("#anatomiNyeri" + self.activeAnat).offset().top + 52))
            }
            })


            $('#anatomiNyeri' + self.activeAnat).on('click', function (e) {
            e.stopPropagation()
            let index = self.isParent.row.auod_lokasi_nyeri[self.activeAnat]['data'].findIndex(x => x.isOpen == true)

            if (self.isParent.row.auod_lokasi_nyeri[self.activeAnat]['isEdit']) {
                if (index !== -1) {
                     self.isParent.row.auod_lokasi_nyeri[self.activeAnat]['data'].splice(index, 1)
                } else {
                    self.isParent.row.auod_lokasi_nyeri[self.activeAnat]['data'].push({
                    x: (posX / $(this).width()) * 100,
                    y: (posY / $(this).height()) * 100,
                    value: '',
                    isOpen: true,
                    isReversed: posX > 300 ? true : false,
                    reverseCount: `-111%`
                    // reverseCount: `-${((posX - 300) / 2) - 16 + 300}px`
                })
                }
            }
            })
        },

        
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },


    },

    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        
        tingkatKesadaranTotal(){
            return (this.isParent.row.auod_response_mata||0) + 
            (this.isParent.row.auod_response_verbal||0) + 
            (this.isParent.row.auod_response_motorik||0)
        },
        
        oddEvenGenCondition() {
            let odd = this.isParent.Config.mr.configVitalSignUGD.filter((el, index) => index % 2)
            let even = this.isParent.Config.mr.configVitalSignUGD.filter((el, index) => !(index % 2))
            let res = []
            res.push(even)
            res.push(odd)
            return res
        }
    },

    
    watch:{
        tingkatKesadaranTotal(v){
            setTimeout(()=>{
                this.isParent.row.auod_gcs_total = v
            },250)
        },
        
        'isParent.activeNyeri':function(v){
            this.isParent.row.auod_nyeri_type = v
        }
    }
}

</script>